// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SpatialConfigModePage-module__header--2JU2v{display:flex;height:5%;align-items:center;width:100%;background-color:#fff !important}.SpatialConfigModePage-module__buttons-box--2CGjs{display:flex;padding-left:10px;margin-left:10px;border-left:1px solid}.SpatialConfigModePage-module__action-buttons--3WEF2{display:flex;padding-left:10px;flex:1}.SpatialConfigModePage-module__active-button--3oUfo{color:#2539ee !important}.SpatialConfigModePage-module__selector-box--XN9H4{display:flex;width:100%;justify-content:center;align-items:center}.SpatialConfigModePage-module__selector--3c9Bm{width:700px}", "",{"version":3,"sources":["webpack://./src/pages/SpatialConfigModePage/SpatialConfigModePage.module.css"],"names":[],"mappings":"AAAA,6CACE,YAAA,CACA,SAAA,CACA,kBAAA,CACA,UAAA,CACA,gCAAA,CAIF,kDACE,YAAA,CACA,iBAAA,CACA,gBAAA,CACA,qBAAA,CAGF,qDACE,YAAA,CACA,iBAAA,CACA,MAAA,CAGF,oDACE,wBAAA,CAGF,mDACE,YAAA,CACA,UAAA,CACA,sBAAA,CACA,kBAAA,CAGF,+CACE,WAAA","sourcesContent":[".header {\n  display: flex;\n  height: 5%;\n  align-items: center;\n  width: 100%;\n  background-color: #ffffff !important;\n}\n\n\n.buttons-box {\n  display: flex;\n  padding-left: 10px;\n  margin-left: 10px;\n  border-left: 1px solid;\n}\n\n.action-buttons {\n  display: flex;\n  padding-left: 10px;\n  flex: 1;\n}\n\n.active-button {\n  color: #2539EE !important;\n}\n\n.selector-box {\n  display: flex;\n  width: 100%;\n  justify-content: center;\n  align-items: center;\n}\n\n.selector {\n  width: 700px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "SpatialConfigModePage-module__header--2JU2v",
	"buttons-box": "SpatialConfigModePage-module__buttons-box--2CGjs",
	"action-buttons": "SpatialConfigModePage-module__action-buttons--3WEF2",
	"active-button": "SpatialConfigModePage-module__active-button--3oUfo",
	"selector-box": "SpatialConfigModePage-module__selector-box--XN9H4",
	"selector": "SpatialConfigModePage-module__selector--3c9Bm"
};
export default ___CSS_LOADER_EXPORT___;
