import { Table } from "antd";
import classNames from "classnames";
import React from "react";
import styles from "./styles.module.css";

type ScrollableTableProps = {
  columns;
  dataSource;
  highlightSecondColumn?: boolean;
};

export const ScrollableTable = (props: ScrollableTableProps) => (
  <Table
    className={classNames(styles["table"], {
      [styles["highlight-second-column"]]: props.highlightSecondColumn,
    })}
    rowKey={(record) => record.id}
    columns={props.columns}
    dataSource={props.dataSource}
    scroll={{ x: 1300 }}
    bordered
    pagination={false}
    size="small"
    sticky
  />
);
