//eslint-disable-next-line
//@ts-nocheck
import { Button, Card, InputNumber, Modal, Progress, Select } from "antd";
import axios from "axios";
import _ from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useProject } from "../../App/state";
import { useFloorplan } from "../../components/FloorplanEditor/hooks/useFloorplan";
import {
  useGetSequenceDetailsQuery,
  useGetSpaceAncestorNamesByIdQuery,
} from "../../generated/types";
import styles from "./OpenSpaceContainer.module.css";

interface Organization {
  name: string;
  id: string;
}

interface Site {
  name: string;
  id: string;
}

interface SiteSheet {
  name: string;
  id: string;
}

interface Capture {
  id: string;
  startedAt: string;
}

interface Pano {
  id: string;
  sheetPose: {
    position: any;
  };
  captureId: string;
  sharpnessScore: number;
}

export const OpenSpaceContainer: React.FC = () => {
  const baseURL = axios.create({
    baseURL: `${process.env["OPENSPACE_MAPPING_SERVICE"]}`,
    timeout: 1500000,
    withCredentials: true,
  });

  const floorplansEditor = useFloorplan();
  const [selectedOrganization, setSelectedOrganization] = useState<
    [string, string] | null
  >(null);
  const [selectedProject, setSelectedProject] = useState<string | undefined>(
    undefined,
  );
  const [selectedCapture, setSelectedCapture] = useState<
    [string, string][] | null
  >(null);
  const [loading, setLoading] = useState(false);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [sites, setSites] = useState<Site[]>([]);
  const [siteSheets, setSiteSheets] = useState<SiteSheet[]>([]);
  const [selectedSiteSheet, setSelectedSiteSheet] = useState<
    string | undefined
  >(undefined);
  const [captures, setCaptures] = useState<Capture[]>([]);
  const [panos, setPanos] = useState<any[]>([]);
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState("");
  const [uploadPath, setUploadPath] = useState("");
  const [uploadLogs, setUploadLogs] = useState<string[]>([]);
  const abortControllerRef = useRef<AbortController | null>(null);
  const [xTranslate, setXTranslate] = useState(0);
  const [yTranslate, setYTranslate] = useState(0);
  const [adjustableScale, setAdjustableScale] = useState(1);
  const [avgDistanceOffset, setAvgDistanceOffset] = useState(0);
  const [selectedRegion, setSelectedRegion] = useState<string>("ksa");
  const [selectedScanLevel, setSelectedScanLevel] = useState<string>("");
  const [noSequencePoints, setNoSequencePoints] = useState(false);
  const [generalError, setGeneralError] = useState<string | null>(null);

  const floorplanIdToSpaceId = floorplansEditor.floorplanIdToSpaceId;

  const project = useProject();

  const { data: floorplanSpacesData } = useGetSpaceAncestorNamesByIdQuery({
    variables: {
      tenant: project,
      spaceIds: Object.values(floorplanIdToSpaceId),
    },
    skip: !Object.values(floorplanIdToSpaceId).length,
  });

  const { data: sequenceDetailsData } = useGetSequenceDetailsQuery({
    variables: {
      tenant: project,
      floorPlanId: floorplansEditor.floorplan?.id ?? "",
      scanLevel: selectedScanLevel,
    },
    skip: !floorplansEditor.floorplan?.id,
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setNoSequencePoints(!data.getSequenceDetails?.length);
    },
  });

  useEffect(() => {
    setNoSequencePoints(false);
  }, [floorplansEditor.floorplan?.id, selectedScanLevel]);

  const selectFloorplanOptions = useMemo(() => {
    if (floorplanSpacesData?.spacesByFilter) {
      const floorplanSpacesMap = _.keyBy(
        floorplanSpacesData?.spacesByFilter,
        "id",
      );

      return _.sortBy(
        Object.entries(floorplanIdToSpaceId).map(([key, value]) => ({
          id: key,
          label: `${floorplanSpacesMap[value].name} - ${floorplanSpacesMap[
            value
          ].ancestors
            .map((s) => s.name)
            .join("-")}`,
        })),
        "label",
      );
    } else {
      return [];
    }
  }, [floorplanSpacesData, floorplanIdToSpaceId]);

  useEffect(() => {
    const fetchOrganizations = async () => {
      if (!selectedRegion) {
        return;
      }
      try {
        setGeneralError(null);
        const response = await baseURL.get(`/get-organization`, {
          params: {
            region: selectedRegion,
            customer: project.customer,
            project: project.project,
          },
        });
        setOrganizations(response.data.content);
      } catch (error) {
        console.error("Error fetching organizations:", error);
        if (axios.isAxiosError(error)) {
          setGeneralError(
            `Error fetching organizations: ${
              error.response?.data?.message || error.message
            }`,
          );
        } else {
          setGeneralError(
            "An unexpected error occurred while fetching organizations.",
          );
        }
      }
    };

    fetchOrganizations();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRegion]);

  const handleOrganizationChange = async (value: [string, string]) => {
    setSelectedOrganization(value);
    setSelectedProject(undefined);
    setSelectedCapture(null);
    try {
      setGeneralError(null);
      const response = await baseURL.get(`/get-sites/${value[0]}`, {
        params: {
          region: selectedRegion,
          customer: project.customer,
          project: project.project,
        },
      });
      setSites(response.data.content);
    } catch (error) {
      console.error("Error fetching sites:", error);
      if (axios.isAxiosError(error)) {
        setGeneralError(
          `Error fetching sites: ${
            error.response?.data?.message || error.message
          }`,
        );
      } else {
        setGeneralError("An unexpected error occurred while fetching sites.");
      }
    }
  };

  const handleProjectChange = async (value: string) => {
    setSelectedProject(value);
    setSelectedSiteSheet(undefined);
    try {
      setGeneralError(null);
      const response = await baseURL.get(`/get-site-sheets/${value}`, {
        params: {
          region: selectedRegion,
          customer: project.customer,
          project: project.project,
        },
      });
      setSiteSheets(response.data.content);
    } catch (error) {
      console.error("Error fetching site sheets:", error);
      if (axios.isAxiosError(error)) {
        setGeneralError(
          `Error fetching site sheets: ${
            error.response?.data?.message || error.message
          }`,
        );
      } else {
        setGeneralError(
          "An unexpected error occurred while fetching site sheets.",
        );
      }
    }
  };

  const handleCaptureChange = async (values: [string, string][]) => {
    setSelectedCapture(values);
    try {
      setGeneralError(null);
      const requests = values.map((id) =>
        baseURL.get(`/panos-by-capture/${selectedProject}/${id[0]}`, {
          params: {
            region: selectedRegion,
            customer: project.customer,
            project: project.project,
          },
        }),
      );
      const responses = await Promise.all(requests);
      const allPanos = responses.flatMap((response) => response.data.content);
      const transformedPanos = allPanos.map((pano: Pano) => ({
        id: pano.id,
        location: pano.sheetPose.position,
        captureId: pano.captureId,
        sharpnessScore: pano.sharpnessScore,
      }));
      setPanos(transformedPanos);
    } catch (error) {
      console.error("Error fetching panos:", error);
      if (axios.isAxiosError(error)) {
        setGeneralError(
          `Error fetching panoramas: ${
            error.response?.data?.message || error.message
          }`,
        );
      } else {
        setGeneralError(
          "An unexpected error occurred while fetching panoramas.",
        );
      }
    }
  };

  const handleSiteSheetChange = async (value: string) => {
    setSelectedSiteSheet(value);
    try {
      setGeneralError(null);
      const response = await baseURL.get(
        `/captures-by-sheet/${selectedProject}/${value}`,
        {
          params: {
            region: selectedRegion,
            customer: project.customer,
            project: project.project,
          },
        },
      );
      setCaptures(response.data.content);
    } catch (error) {
      console.error("Error fetching captures:", error);
      if (axios.isAxiosError(error)) {
        setGeneralError(
          `Error fetching captures: ${
            error.response?.data?.message || error.message
          }`,
        );
      } else {
        setGeneralError(
          "An unexpected error occurred while fetching captures.",
        );
      }
    }
  };

  const [mappingResponse, setMappingResponse] = useState<any | null>(null);

  const handleMapping = async () => {
    if (!sequenceDetailsData || !floorplansEditor.floorplan) {
      console.error("Insufficient data to send mapping.");
      setGeneralError(
        "Insufficient data for mapping. Please ensure all required fields are filled.",
      );
      return;
    }

    const { height, width, originX, originY, mmScaleFactor, url } =
      floorplansEditor.floorplan;
    const sequencePoints = sequenceDetailsData?.getSequenceDetails;
    const payload = {
      sequencePoints,
      height,
      width,
      originX,
      originY,
      mmScaleFactor,
      url,
      xTranslate,
      yTranslate,
      adjustableScale,
      openspace_locations: panos,
      avgDistanceOffset,
    };

    try {
      setGeneralError(null);
      setLoading(true);
      const response = await baseURL.post("/sequence-points", payload, {
        params: {
          region: selectedRegion,
          customer: project.customer,
          project: project.project,
        },
      });
      setMappingResponse(response.data);
    } catch (error) {
      console.error("Error sending mapping data:", error);
      if (axios.isAxiosError(error)) {
        setGeneralError(
          `Mapping error: ${error.response?.data?.message || error.message}`,
        );
      } else {
        setGeneralError("An unexpected error occurred during mapping.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleUpload = async () => {
    setUploadLoading(true);
    setUploadProgress(0);
    setUploadStatus("");
    setUploadPath("");
    setUploadLogs([]);
    const controller = new AbortController();
    abortControllerRef.current = controller;

    try {
      const sharpnessScores = panos.map((pano) => pano.sharpnessScore);

      const payload = {
        capture_ids: mappingResponse?.filtered_points?.capture_ids,
        pano_ids: mappingResponse?.filtered_points?.ids,
        project_name: selectedOrganization ? selectedOrganization[1] : "",
        date_name: selectedCapture && selectedCapture[0][1],
        site_id: selectedProject,
        floor_name: selectFloorplanOptions.find(
          (option) => option.id === floorplansEditor.floorplan?.id,
        )?.label,
        region: selectedRegion,
        sequencenumbers: mappingResponse?.filtered_points?.sequencenumbers,
        sharpness_scores: sharpnessScores,
      };

      const url = new URL(
        `${process.env["OPENSPACE_MAPPING_SERVICE"]}/upload-equi-images`,
      );
      url.searchParams.append("region", selectedRegion);
      url.searchParams.append("customer", project.customer);
      url.searchParams.append("project", project.project);

      const response = await fetch(url.toString(), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
        signal: controller.signal,
        credentials: "include",
      });

      if (!response.body) {
        throw new Error("ReadableStream not supported in this browser.");
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let { value, done } = await reader.read();
      let buffer = "";

      while (!done) {
        buffer += decoder.decode(value, { stream: true });
        const lines = buffer.split("\n");
        buffer = lines.pop() || ""; // Keep the last partial line

        for (const line of lines) {
          setUploadLogs((prevLogs) => [...prevLogs, line]);
          if (line.startsWith("Progress:")) {
            const match = line.match(/Progress:\s+(\d+)%\s+\((\d+)\/(\d+)\)/);
            if (match) {
              const percent = parseInt(match[1], 10);
              const current = parseInt(match[2], 10);
              const total = parseInt(match[3], 10);
              setUploadProgress(percent);
              setUploadStatus(`Progress: ${percent}% (${current}/${total})`);
            }
          } else if (line.startsWith("S3 Path:")) {
            setUploadPath(line);
          }
        }

        ({ value, done } = await reader.read());
      }

      // Handle any remaining buffer
      if (buffer.length > 0 && buffer.startsWith("Progress:")) {
        const match = buffer.match(/Progress:\s+(\d+)%\s+\((\d+)\/(\d+)\)/);
        if (match) {
          const percent = parseInt(match[1], 10);
          setUploadProgress(percent);
          setUploadStatus(`Progress: ${percent}%`);
        }
      }

      // Check the last log for status
      const lastLog = uploadLogs[uploadLogs.length - 1];
      if (lastLog && lastLog.startsWith("Please upload")) {
        setUploadStatus("Re-upload required.");
      } else {
        setUploadStatus("Upload completed successfully.");
      }
    } catch (error: any) {
      if (error.name === "AbortError") {
        setUploadStatus("Upload aborted by the user.");
      } else {
        setUploadStatus(`Error uploading images: ${error.message}`);
        console.error("Error uploading images:", error);
      }
    } finally {
      setUploadLoading(false);
      abortControllerRef.current = null;
    }
  };

  const showUploadModal = () => {
    setIsUploadModalVisible(true);
    setUploadStatus("");
    setUploadPath("");
  };

  const handleUploadCancel = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    setIsUploadModalVisible(false);
    setUploadStatus("");
    setUploadPath("");
    setUploadLogs([]); // Clear logs on cancel
  };

  return (
    <Card
      title={<div className={styles.layoutHeader}>OpenSpace Integration</div>}
      className={styles.layout}
    >
      <div className={styles.formSection}>
        <h3 className={styles.sectionTitle}>Customer Settings</h3>
        <div className={styles.fieldsGrid}>
          <div className={styles.fields}>
            <label className={styles.label}>Openspace Region</label>
            <Select
              className={styles.select}
              placeholder="Select a Region"
              onChange={(value: string) => setSelectedRegion(value)}
              options={[
                { label: "UK", value: "uk" },
                { label: "US", value: "us" },
                { label: "EU", value: "eu" },
                { label: "KSA", value: "ksa" },
              ]}
              value={selectedRegion}
            />
          </div>
          <div className={styles.fields}>
            <label className={styles.label}>Customer in Openspace</label>
            <Select
              className={styles.select}
              placeholder="Select an Organization"
              onChange={handleOrganizationChange}
              options={organizations.map((org: Organization) => ({
                label: org.name,
                value: [org.id, org.name],
              }))}
              value={selectedOrganization && selectedOrganization[1]}
              disabled={!selectedRegion}
            />
          </div>
        </div>
      </div>

      <div className={styles.formSection}>
        <h3 className={styles.sectionTitle}>Project Settings</h3>
        <div className={styles.fieldsGrid}>
          <div className={styles.fields}>
            <label className={styles.label}>Project in Openspace</label>
            <Select
              className={styles.select}
              placeholder="Select a Project"
              onChange={handleProjectChange}
              disabled={!selectedOrganization}
              options={sites.map((site: Site) => ({
                label: site.name,
                value: site.id,
              }))}
              value={selectedProject}
            />
          </div>
          <div className={styles.fields}>
            <label className={styles.label}>Sheet (Floorplan)</label>
            <Select
              className={styles.select}
              placeholder="Select a Sheet"
              onChange={handleSiteSheetChange}
              disabled={!selectedProject}
              value={selectedSiteSheet}
              options={siteSheets
                .filter(
                  (sheet: {
                    sheetStats: { videoCaptures: { count: number } };
                  }) => sheet.sheetStats.videoCaptures.count > 0,
                )
                .map((sheet: SiteSheet) => ({
                  label: sheet.name,
                  value: sheet.id,
                }))}
            />
          </div>
          <div className={styles.fields}>
            <label className={styles.label}>Capture</label>
            <Select
              className={styles.select}
              placeholder="Select a Capture"
              onChange={handleCaptureChange}
              disabled={!selectedSiteSheet}
              value={selectedCapture && selectedCapture[0][1]}
              options={Object.entries(
                captures.reduce((acc, capture) => {
                  const captureDate = capture.startedAt.split("T")[0];
                  if (!acc[captureDate]) {
                    acc[captureDate] = [];
                  }
                  acc[captureDate].push({ id: capture.id, date: captureDate });
                  return acc;
                }, {} as Record<string, { id: string; date: string }[]>),
              )
                .sort(([dateA], [dateB]) => dateB.localeCompare(dateA))
                .map(([captureDate, ids]) => ({
                  label: captureDate,
                  value: ids.map(({ id, date }) => [id, date]),
                }))}
            />
          </div>
        </div>
      </div>

      <div className={styles.formSection}>
        <h3 className={styles.sectionTitle}>Floorplan Settings</h3>
        <div className={styles.fieldsGrid}>
          <div className={styles.fields}>
            <label className={styles.label}>Scan Level</label>
            <Select
              className={styles.select}
              placeholder="Select Scan Level"
              onChange={(value: string) => setSelectedScanLevel(value)}
              value={selectedScanLevel}
            >
              <Select.Option value="EARLY">Early</Select.Option>
              <Select.Option value="MID">Mid</Select.Option>
              <Select.Option value="LATE">Late</Select.Option>
            </Select>
          </div>
          <div className={styles.fields}>
            <label className={styles.label}>Floorplan in Disperse</label>
            <Select
              className={styles.select}
              placeholder={"Select a floorplan"}
              onChange={floorplansEditor.onChange}
              showSearch
              disabled={!selectedScanLevel}
              value={floorplansEditor.floorplan?.id}
              filterOption={(input, option) =>
                option?.children
                  ?.toLowerCase()
                  ?.indexOf(input.toLowerCase().trim()) >= 0
              }
            >
              {selectFloorplanOptions.map((option) => (
                <Select.Option key={option.id} value={option.id}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
        {noSequencePoints && floorplansEditor.floorplan?.id && (
          <div className={styles.errorMessage}>
            No sequence points found for the selected floor plan and scan level.
          </div>
        )}
      </div>

      <div className={styles.formSection}>
        <h3 className={styles.sectionTitle}>Adjustment Settings</h3>
        <div className={styles.fieldsGrid}>
          <div className={styles.fields}>
            <label className={styles.label}>Move points horizontally</label>
            <InputNumber value={xTranslate} onChange={setXTranslate} />
            <span style={{ marginLeft: "8px" }}>units</span>
          </div>
          <div className={styles.fields}>
            <label className={styles.label}>Move points vertically</label>
            <InputNumber value={yTranslate} onChange={setYTranslate} />
            <span style={{ marginLeft: "8px" }}>units</span>
          </div>
          <div className={styles.fields}>
            <label className={styles.label}>Scale Walkpath</label>
            <InputNumber
              value={adjustableScale}
              onChange={setAdjustableScale}
            />
          </div>
          <div className={styles.fields}>
            <label className={styles.label}>Search Radius Offset</label>
            <InputNumber
              value={avgDistanceOffset}
              onChange={setAvgDistanceOffset}
            />
          </div>
        </div>
      </div>

      <div className={styles.buttonGroup}>
        <Button
          type="primary"
          size="large"
          onClick={handleMapping}
          loading={loading}
          disabled={
            noSequencePoints || !floorplansEditor.floorplan?.id || loading
          }
        >
          Send data for mapping
        </Button>
        <Button
          type="default"
          size="large"
          onClick={showUploadModal}
          disabled={!mappingResponse}
        >
          Start Uploading
        </Button>
      </div>

      {mappingResponse && (
        <div className={styles.imagesContainer}>
          <img
            className={styles.image}
            src={`data:image/png;base64,${mappingResponse.plotted_image}`}
            alt="Plotted Image"
          />
          <img
            className={styles.image}
            src={`data:image/png;base64,${mappingResponse.all_points_plot}`}
            alt="All Points Plot"
          />
        </div>
      )}

      <Modal
        title={
          uploadStatus.startsWith("Progress:")
            ? "Uploading..."
            : uploadStatus === "Upload completed successfully."
            ? "Uploaded Successfully"
            : uploadStatus === "Re-upload required."
            ? "Re-upload required"
            : "Confirm Upload"
        }
        visible={isUploadModalVisible}
        onOk={
          uploadStatus === "Upload completed successfully."
            ? handleUploadCancel
            : handleUpload
        }
        onCancel={handleUploadCancel}
        okText={
          uploadStatus === "Upload completed successfully."
            ? ""
            : uploadStatus === "Re-upload required."
            ? "Re-upload"
            : "Confirm"
        }
        cancelText={
          uploadStatus === "Upload completed successfully." ? "Close" : "Cancel"
        }
        confirmLoading={uploadLoading}
        afterClose={() => {
          if (uploadStatus === "Upload completed successfully.") {
            setUploadStatus("");
            setUploadPath("");
          }
          setUploadLogs([]);
        }}
      >
        {uploadStatus === "" && (
          <p>Are you sure you want to start uploading?</p>
        )}
        {uploadLoading && <Progress percent={uploadProgress} />}
        <p>{uploadStatus}</p>
        {uploadPath && (
          <p>
            {uploadStatus === "Upload completed successfully."
              ? "Uploaded to:"
              : "Uploading to:"}{" "}
            {uploadPath}
          </p>
        )}
        <div className={styles.modalLogContainer}>
          {uploadLogs.map((log, index) => (
            <div
              key={index}
              className={styles.modalLogEntry}
              style={{
                color:
                  log.toLowerCase().includes("error") ||
                  log.toLowerCase().includes("failed") ||
                  log.toLowerCase().includes("please")
                    ? "red"
                    : "#0f0",
              }}
            >
              {log}
            </div>
          ))}
        </div>
      </Modal>

      {generalError && (
        <div className={`${styles.errorMessage} ${styles.bottomError}`}>
          {generalError}
        </div>
      )}
    </Card>
  );
};
