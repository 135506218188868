// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OpenSpaceContainer-module__uploadStatus--1C9vI{margin-top:20px}.OpenSpaceContainer-module__fields--3xzST{display:flex;margin-bottom:16px;align-items:center}.OpenSpaceContainer-module__label--1Bm4e{margin-right:10px;margin-left:10px}.OpenSpaceContainer-module__select--Y4Qkk{width:300px}.OpenSpaceContainer-module__image--2T6-h{max-width:40vw}", "",{"version":3,"sources":["webpack://./src/components/OpenSpace/OpenSpaceContainer.module.css"],"names":[],"mappings":"AAEA,gDACI,eAAA,CAGJ,0CACI,YAAA,CACA,kBAAA,CACA,kBAAA,CAGJ,yCACI,iBAAA,CACA,gBAAA,CAGJ,0CACI,WAAA,CAGJ,yCAEI,cAAA","sourcesContent":[".layout {}\n\n.uploadStatus {\n    margin-top: 20px;\n}\n\n.fields {\n    display: flex;\n    margin-bottom: 16px;\n    align-items: center;\n}\n\n.label {\n    margin-right: 10px;\n    margin-left: 10px;\n}\n\n.select {\n    width: 300px;\n}\n\n.image {\n\n    max-width: 40vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"uploadStatus": "OpenSpaceContainer-module__uploadStatus--1C9vI",
	"fields": "OpenSpaceContainer-module__fields--3xzST",
	"label": "OpenSpaceContainer-module__label--1Bm4e",
	"select": "OpenSpaceContainer-module__select--Y4Qkk",
	"image": "OpenSpaceContainer-module__image--2T6-h"
};
export default ___CSS_LOADER_EXPORT___;
