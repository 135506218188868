import { ColumnsType } from "antd/lib/table";
import _ from "lodash";
import React, { useMemo } from "react";
import { Project, Space } from "../../../types";
import { EditableProgressCellContainer } from "../EditableProgressCell/EditableProgressCellContainer";
import { ScrollableTable } from "../ScrollableTable";
import {
  ancestorToRow,
  getComponentColumn,
  getProgressColumn,
} from "../table-utils";
import { ComponentWithProgresses, RecordType } from "../types";

type ProgressTableProps = {
  project: Project;
  spaces: Space[];
  components: ComponentWithProgresses[];
  onComponentUpdated: (componentIds: string[]) => any;
};

const componentToRow = (
  c: ComponentWithProgresses,
  onComponentUpdated: (componentIds: string[]) => any,
  project: Project,
) => ({
  key: c.id,
  component: c.name,
  type: RecordType.component,
  ...(c.aggregatedProgresses
    ? _.zipObject(
        c.aggregatedProgresses.map((r) => r.spaceId),
        c.aggregatedProgresses.map((r) => (
          <EditableProgressCellContainer
            key={r.spaceId}
            project={project}
            progress={r.progress}
            status={r.status}
            componentId={c.id}
            spaceId={r.spaceId}
            batchId={r.batchId}
            progressChange={r.progressChange}
            onComponentUpdated={onComponentUpdated}
          />
        )),
      )
    : {}),
});

export const ProgressTable = (props: ProgressTableProps) => {
  const { project, spaces, components, onComponentUpdated } = props;
  // The type of the column set depends on the selected space.
  const columns: ColumnsType<any> = useMemo(
    () => [
      getComponentColumn(),
      // All other columns, representing the list of spaces under the selected space.
      ...spaces.map((s, idx) =>
        getProgressColumn(s.name, s.id, idx, spaces.length, true),
      ),
    ],
    [spaces],
  );

  const tableData = useMemo(() => {
    const componentsByAncestor = _.groupBy(components, "ancestorPath");
    return Object.entries(componentsByAncestor).flatMap(([, cs]) => [
      ancestorToRow(cs[0].ancestorPath),
      ...cs.map((c, i) => ({
        ...componentToRow(c, onComponentUpdated, project),
        id: `component-${c.id}-${i}`,
      })),
    ]);
  }, [components, onComponentUpdated, project]);

  return (
    <ScrollableTable
      columns={columns}
      dataSource={tableData}
      highlightSecondColumn
    />
  );
};
