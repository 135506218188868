import { useApolloClient } from "@apollo/client";
import { Spin } from "antd";
import _ from "lodash";
import React, { useMemo } from "react";
import {
  GetComponentProgressesDocument,
  GetComponentProgressesQuery,
  GetComponentProgressesQueryVariables,
  useGetComponentProgressesQuery,
} from "../../../generated/types";
import { Filter } from "../../../pages/EditorPage/types";
import { Batch, Project } from "../../../types";
import { ComponentInfo } from "../types";
import { ProgressHistoryTable } from "./ProgressHistoryTable";

type ProgressHistoryTableContainerProps = {
  planId: string;
  batches: Batch[];
  components: ComponentInfo[];
  project: Project;
  spaceId: string;
  filter: Filter;
};

export const ProgressHistoryTableContainer = (
  props: ProgressHistoryTableContainerProps,
) => {
  const componentIds = useMemo(
    () => props.components.map((c) => c.id),
    [props.components],
  );
  const batchIds = useMemo(
    () => props.batches.map((b) => b.id),
    [props.batches],
  );
  const spaceIds = useMemo(() => [props.spaceId], [props.spaceId]);

  const getProgressesVariables = (ids: string[]) => ({
    customer: props.project.customer,
    project: props.project.project,
    scope: props.project.scope,
    planId: props.planId,
    componentIds: ids,
    spaceIds,
    batchIds,
  });

  const { data, loading, error } = useGetComponentProgressesQuery({
    variables: getProgressesVariables(componentIds),
    skip: props.components.length === 0,
  });

  const client = useApolloClient();

  const refetchComponentProgresses = async (ids: string[]) =>
    await client.query<
      GetComponentProgressesQuery,
      GetComponentProgressesQueryVariables
    >({
      query: GetComponentProgressesDocument,
      variables: getProgressesVariables(ids),
    });

  const aggregatedProgressesByComponentId = useMemo(
    () =>
      _(data?.componentsByIds)
        .keyBy("id")
        .mapValues((c) =>
          c.aggregatedProgresses.map((p) => ({
            batchId: p.batch.id,
            progress: p.progress ?? undefined,
            status: p.status ?? undefined,
            spaceId: p.space.id,
          })),
        )
        .value(),
    [data?.componentsByIds],
  );

  const filteredComponents = useMemo(
    () =>
      props.components.filter((c) => {
        const matchesName =
          !props.filter.name ||
          (c.name &&
            c.name.toLowerCase().includes(props.filter.name.toLowerCase()));
        const matchesPhase =
          !props.filter.phase ||
          (c.ancestorPath && c.ancestorPath.includes(props.filter.phase));
        return matchesName && matchesPhase;
      }),
    [props.components, props.filter.name, props.filter.phase],
  );

  if (loading) {
    return <Spin />;
  }

  if (error) {
    return <div>Error getting progress data. {error ?? ""}</div>;
  }

  return (
    <ProgressHistoryTable
      project={props.project}
      batches={props.batches}
      components={filteredComponents.map((c) => ({
        ...c,
        aggregatedProgresses: aggregatedProgressesByComponentId[c.id],
      }))}
      onComponentUpdated={refetchComponentProgresses}
    />
  );
};
