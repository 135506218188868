import { WorkBook, WritingOptions, utils, writeFile } from "xlsx";

/**
 * Downloads an Excel workbook to the user's system.
 *
 * @param workbook - The Excel workbook to download
 * @param filename - The name to save the file as
 * @param options - Excel writing options to control format and behavior
 *
 * @example
 * ```ts
 * // Download as .xlsx
 * downloadWorkbook(workbook, "report.xlsx", { bookType: "xlsx" });
 *
 * // Download as .xls
 * downloadWorkbook(workbook, "report.xls", { bookType: "xls" });
 * ```
 */
export const downloadWorkbook = (
  workbook: WorkBook,
  filename: string,
  options: WritingOptions,
) => {
  writeFile(workbook, filename, options);
};

/**
 * Estimates the file size of an Excel workbook in bytes.
 *
 * This function calculates an approximate file size based on:
 * - Number of rows in the first sheet
 * - Number of columns per row
 * - Average bytes per cell (5.7 bytes based on analysis of real data)
 *
 * Note: This is a rough estimation and actual file size may vary based on:
 * - Cell content length and type
 * - Formatting and styles
 * - Excel file format (.xls vs .xlsx)
 *
 * @param workbook - The Excel workbook to analyze
 * @returns Estimated file size in bytes
 */
export const estimateFileSize = (workbook: WorkBook): number => {
  const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
  const data = utils?.sheet_to_json(firstSheet) as Record<string, unknown>[];
  if (data.length === 0) {
    return 0;
  }
  const columnCount = Object.keys(data[0]).length;
  return Math.round(data.length * columnCount * 5.7);
};
