import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  from,
  fromPromise,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { Layout } from "antd";
import { createUploadLink } from "apollo-upload-client";
import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { hot } from "react-hot-loader/root";
import { BrowserRouter as Router } from "react-router-dom";
import NavMenu from "../components/NavMenu";
import { AuthProvider, useAuth } from "../contexts/Auth";
import { NotificationProvider } from "../contexts/Notifications";
import AppContent from "./AppContent";
import apps, { App } from "./apps";
import { Provider as AppStateProvider, useAppState } from "./state";
import styles from "./styles.module.css";

const PROXIED_BACKEND_URL = `${window.location.origin}/${process.env.API_BASE_URL}/graphql`;

// This type-policy does the following
// The read function provided for the task query will
// first check the cache whether Task:id is present (with all requested)
// This allows us to access the cache for the task(...) query even
// if we never ran that query before. For example we might have populated
// the cache with a different query that returns tasks

const apolloCache = new InMemoryCache({
  addTypename: true,
  typePolicies: {
    Project: {
      keyFields: ["customer", "project"],
    },
    Query: {
      fields: {
        task: {
          read(_, { args, toReference }) {
            return args
              ? toReference({
                  __typename: "Task",
                  id: args?.id,
                })
              : undefined;
          },
        },
        component: {
          read(_, { args, toReference }) {
            return args
              ? toReference({
                  __typename: "Component",
                  id: args?.id,
                })
              : undefined;
          },
        },
      },
    },
  },
});

const uploadLink = createUploadLink({
  uri: PROXIED_BACKEND_URL,
  credentials: "include",
});

const apolloClient = new ApolloClient({
  link: uploadLink,
  cache: apolloCache,
  credentials: "include",
});

const AppBody = () => {
  const auth = useAuth();
  const appKey = useAppState().app;

  React.useEffect(() => {
    document.title = appKey
      ? `${apps[appKey].name} | Delivery Core`
      : "Disperse Delivery Core";
  }, [appKey]);

  React.useEffect(() => {
    apolloClient.setLink(
      from([
        onError(({ operation, forward, ...error }) => {
          if (
            error.networkError != null &&
            "statusCode" in error.networkError &&
            error.networkError.statusCode === 401
          ) {
            return fromPromise(auth.refresh()).flatMap(() =>
              forward(operation),
            );
          }
        }),
        uploadLink,
      ]),
    );
  }, [auth]);

  return (
    <DndProvider backend={HTML5Backend}>
      <ApolloProvider client={apolloClient}>
        <NotificationProvider placement="bottomLeft">
          <Layout className={styles.app}>
            <Layout.Sider
              collapsible={auth.status === "authenticated"}
              defaultCollapsed={true}
            >
              <NavMenu
                items={(Object.keys(apps) as App[])
                  .filter((app) => !apps[app].hidden)
                  .map((app) => ({
                    name: apps[app].name,
                    icon: apps[app].icon,
                    app,
                  }))}
              />
            </Layout.Sider>
            <Layout.Content className={styles["app-content"]}>
              <AppContent />
            </Layout.Content>
          </Layout>
        </NotificationProvider>
      </ApolloProvider>
    </DndProvider>
  );
};

const App = () => (
  <Router>
    <AuthProvider>
      <AppStateProvider>
        <AppBody />
      </AppStateProvider>
    </AuthProvider>
  </Router>
);

export default hot(App);
