import { Button, Card } from "antd";
import { format } from "date-fns";
import _ from "lodash";
import React, { useState } from "react";
import * as xlsx from "xlsx";
import { useNotification } from "../../contexts/Notifications";
import { useGetAllComponentsWithTagsLazyQuery } from "../../generated/types";
import { Project } from "../../types";

interface ComponentTagsDumpGeneratorProps {
  project: Project;
}

const toFilename = (project: Project) => {
  // use a custom date format instead of ISO to avoid dots in the filename
  const dateString = format(new Date(), "yyyyMMdd_HHmmss");
  return `${project.customer}-${project.project}-${dateString}-component-tags.csv`;
};

const generateWorkbook = (components: Component[], sheetName) => {
  const workbook = xlsx.utils.book_new();
  const data = components.map((c) => [c.tag, c.id, c.name]);
  const worksheet = xlsx.utils.aoa_to_sheet([["tag", "id", "name"], ...data]);
  xlsx.utils.book_append_sheet(workbook, worksheet, sheetName);
  return workbook;
};

type Component = {
  id: string;
  name: string;
  tag?: string;
};

export const parseComonents = (rawComponents): Component[] => {
  const components = rawComponents.flatMap((raw) => {
    if (raw.tags.length > 0) {
      return raw.tags.map((tag) => ({
        id: raw.id,
        name: raw.name,
        tag,
      }));
    } else {
      return [{ id: raw.id, name: raw.name }];
    }
  });
  // sorting them is nice, so the order in the csv is deterministic
  // and sorting by name is a lot simpler than getting parent tasks and the guiIndex shenanigans
  const sortedComponents = _.sortBy(components, "tag");
  return sortedComponents;
};

export const ComponentTagsDumpGenerator = (
  props: ComponentTagsDumpGeneratorProps,
) => {
  const notify = useNotification();
  const [loading, setLoading] = useState(false);
  const [fetchComponentsWithTags] = useGetAllComponentsWithTagsLazyQuery({
    onError: (error) => {
      notify(`Failed to generate component tags csv: ${error}`, "error");
      setLoading(false);
    },
    onCompleted: (data) => {
      try {
        const components = parseComonents(data.components);
        const workbook = generateWorkbook(components, "ComponentTags");

        xlsx.writeFile(workbook, toFilename(props.project), {
          sheet: "ComponentTags",
        });
      } catch (error) {
        notify(`Failed to generate component tags csv: ${error}`, "error");
      }
      setLoading(false);
    },
    fetchPolicy: "no-cache", // let the User decide when data has changed
  });

  const onClick = async () => {
    setLoading(true);
    fetchComponentsWithTags({
      variables: {
        customer: props.project.customer,
        project: props.project.project,
        scope: props.project.scope,
      },
    });
  };

  return (
    <Card>
      <Button value="generate" onClick={onClick} loading={loading}>
        Download all components with tags
      </Button>
    </Card>
  );
};
