import { Button, Card, Select } from "antd";
import React, { useMemo, useState } from "react";
import { useNotification } from "../../contexts/Notifications";
import {
  ScanSequenceLevelType,
  useGetAllFloorplansLazyQuery,
  useGetAllFloorplansScanSequencesAndScenesLazyQuery,
} from "../../generated/types";
import { Project } from "../../types";
import styles from "./SequenceMapGenerator.module.css";
import { generateSequenceMaps, toFilename } from "./sequence-maps-generator";
import { FloorplanScanList } from "./types";
import {
  createFloorplanBatches,
  gqlToFloorplansData,
  gqlToSequenceMapsData,
} from "./utils";

interface SequenceMapsGeneratorProps {
  project: Project;
}

export const SequenceMapsGenerator = (props: SequenceMapsGeneratorProps) => {
  const { project } = props;
  const [loading, setLoading] = useState(false);
  const notify = useNotification();
  const [floorplans, setFloorplans] = useState<FloorplanScanList>([]);
  const [selectedSequences, setSelectedSequences] = useState<string[]>([]);
  const [selectedSequencesSize, setSelectedSequencesSize] = useState<number>();
  const [showSelector, setShowSelector] = useState(false);

  const [fetchAllFloorplansScanSequencesAndScenes] =
    useGetAllFloorplansScanSequencesAndScenesLazyQuery({
      fetchPolicy: "no-cache", // let the User decide when data has changed
      onError: (error) => {
        notify(`Failed to generate site scanner maps: ${error}`, "error");
      },
      onCompleted: async (data) => {
        if (!data) {
          notify(
            `No floorplan data for ${project.customer} ${project.project}`,
          );
        }
      },
    });

  const [fetchAllFloorplans] = useGetAllFloorplansLazyQuery({
    fetchPolicy: "no-cache", // let the User decide when data has changed
    onError: (error) => {
      notify(`Failed to generate site scanner maps: ${error}`, "error");
      setLoading(false);
    },
    onCompleted: async (data) => {
      if (data) {
        setFloorplans(gqlToFloorplansData(data));
      } else {
        notify(`No floorplan data for ${project.customer} ${project.project}`);
      }
      setLoading(false);
    },
  });

  const fetchSequenceMaps = async () => {
    setLoading(true);
    await fetchAllFloorplans({
      variables: {
        tenant: project,
      },
    });
    setShowSelector(true);
  };

  const downloadSelectedSequeceMaps = async () => {
    setLoading(true);
    const floorCombo: Record<ScanSequenceLevelType, string[]> = {
      EARLY: [],
      MID: [],
      LATE: [],
    };
    selectedSequences.forEach((sequence) => {
      const splitter = sequence.split("_");
      floorCombo[splitter[0]].push(splitter[1]);
    });
    const floorplanBatches = createFloorplanBatches(floorCombo, 4);
    for (const scanLevel in floorplanBatches) {
      for (const batch of floorplanBatches[scanLevel]) {
        await downloadSequenceFunc(batch, scanLevel as ScanSequenceLevelType);
      }
    }
    setLoading(false);
  };

  const downloadAllSequeceMaps = async () => {
    setLoading(true);
    const floorCombo: Record<ScanSequenceLevelType, string[]> = {
      EARLY: [],
      MID: [],
      LATE: [],
    };
    floorplans.forEach((floorplan) => {
      floorCombo[floorplan.scanLevel].push(floorplan.floorplan.id);
    });
    const floorplanBatches = createFloorplanBatches(floorCombo, 4);
    for (const scanLevel in floorplanBatches) {
      for (const batch of floorplanBatches[scanLevel]) {
        await downloadSequenceFunc(batch, scanLevel as ScanSequenceLevelType);
      }
    }
    setLoading(false);
  };

  const downloadSequenceFunc = async (
    floorplanIds: string[],
    scanLevel: ScanSequenceLevelType,
  ) => {
    const filterObject: any = {};
    if (floorplanIds) {
      filterObject.floorplanIds = floorplanIds;
    }
    if (scanLevel) {
      filterObject.scanLevel = scanLevel;
    }
    const sequence = await fetchAllFloorplansScanSequencesAndScenes({
      variables: {
        tenant: project,
        scanSequenceFilters: filterObject,
      },
    });
    if (sequence?.data) {
      const processedSequence = gqlToSequenceMapsData(sequence.data);
      if (processedSequence.some((entry) => entry.scenes.length > 1)) {
        try {
          await generateSequenceMaps(
            processedSequence,
            "pdf",
            selectedSequencesSize,
          );
        } catch (error) {
          notify(`Failed to generate map export: ${error}`, "error");
        }
      } else {
        notify(
          `No scenes data for ${sequence?.data?.scanSequencesByFilter[0].floorplan?.space?.name} - ${sequence?.data?.scanSequencesByFilter[0].scanLevel}`,
        );
      }
    }
  };

  const options = useMemo(
    () =>
      floorplans.map((seq: any) => {
        return {
          value: seq.scanLevel + "_" + seq.floorplan.id,
          label: toFilename(seq),
        };
      }),
    [floorplans],
  );

  return (
    <Card>
      {!showSelector && (
        <Button value="generate" onClick={fetchSequenceMaps} loading={loading}>
          Fetch Sequence Maps
        </Button>
      )}
      {showSelector && (
        <>
          <Select
            mode="multiple"
            className={styles["select"]}
            placeholder="Select sequences"
            value={selectedSequences}
            onChange={(value) => setSelectedSequences(value)}
            options={options}
          />
          <Select
            className={styles["select-size"]}
            placeholder="Select Size Of Sequence Points"
            value={selectedSequencesSize}
            onChange={(size) => setSelectedSequencesSize(size)}
            options={[
              { value: 1, label: "1" },
              { value: 2, label: "2" },
              { value: 3, label: "3" },
              { value: 4, label: "4" },
              { value: 5, label: "5" },
            ]}
          />
          <Button
            className={styles["button"]}
            value="generateSelected"
            onClick={downloadSelectedSequeceMaps}
            loading={loading}
          >
            Download Selected Sequence Maps
          </Button>
          <Button
            value="generateSelected"
            onClick={downloadAllSequeceMaps}
            loading={loading}
          >
            Download All
          </Button>
        </>
      )}
    </Card>
  );
};
