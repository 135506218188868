import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  UUID: any;
  Upload: any;
};

export enum Action {
  Write = 'write',
  Read = 'read'
}

export type AggregatedArbitraryPlannedProgress = {
  __typename?: 'AggregatedArbitraryPlannedProgress';
  progress?: Maybe<Scalars['Float']>;
  timestamp: Scalars['Date'];
  status: ProgressStatus;
};

export type AggregatedArbitraryProgress = {
  __typename?: 'AggregatedArbitraryProgress';
  progress?: Maybe<Scalars['Float']>;
  batch: Batch;
  status: ProgressStatus;
  subcontractorId?: Maybe<Scalars['UUID']>;
};

export type AggregatedPlannedProgress = {
  __typename?: 'AggregatedPlannedProgress';
  task?: Maybe<Task>;
  plan?: Maybe<Plan>;
  subcontractor?: Maybe<Subcontractor>;
  space?: Maybe<Space>;
  spaceTags?: Maybe<Array<Scalars['String']>>;
  timestamp: Scalars['Date'];
  progress?: Maybe<Scalars['Float']>;
  status: ProgressStatus;
};

export type AggregatedProgress = {
  __typename?: 'AggregatedProgress';
  task?: Maybe<Task>;
  component?: Maybe<Component>;
  plan?: Maybe<Plan>;
  subcontractor?: Maybe<Subcontractor>;
  space: Space;
  spaceTags?: Maybe<Array<Scalars['String']>>;
  batch: Batch;
  progress?: Maybe<Scalars['Float']>;
  status: ProgressStatus;
};

export type AggregatedSchedule = ScheduleInterface & {
  __typename?: 'AggregatedSchedule';
  task: Task;
  space: Space;
  ranges: Array<Range>;
  aggregatedOver: Array<Space>;
};

export type AnnotationLocation = {
  __typename?: 'AnnotationLocation';
  pitch: Scalars['Float'];
  yaw: Scalars['Float'];
};

export type AnnotationLocationInputType = {
  pitch: Scalars['Float'];
  yaw: Scalars['Float'];
};

export type Arrow = {
  __typename?: 'Arrow';
  id: Scalars['UUID'];
  missing: Scalars['Boolean'];
  targetScene?: Maybe<Scene>;
  scene: Scene;
  location: AnnotationLocation;
  locationInImage: AnnotationLocation;
};

export type AuthQueryStringMapping = {
  __typename?: 'AuthQueryStringMapping';
  tileSuffix: Scalars['String'];
  queryString: Scalars['String'];
};

export type Batch = {
  __typename?: 'Batch';
  id: Scalars['UUID'];
  timestamp: Scalars['Date'];
  weekNumber: Scalars['Int'];
  shots: Array<Shot>;
};

export type BimToken = {
  __typename?: 'BimToken';
  token: Scalars['String'];
  expiresIn: Scalars['Int'];
};

export type BimUrn = {
  __typename?: 'BimUrn';
  id: Scalars['UUID'];
  title: Scalars['String'];
  urn: Scalars['String'];
};

export enum ChangeState {
  Change = 'CHANGE',
  NoChange = 'NO_CHANGE'
}

export type ClearDateInputType = {
  taskId?: Maybe<Scalars['UUID']>;
  spaceId?: Maybe<Scalars['UUID']>;
};

export type Component = {
  __typename?: 'Component';
  id: Scalars['UUID'];
  shell360Id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  guiIndex?: Maybe<Scalars['Int']>;
  componentType: ComponentType;
  subcontractor?: Maybe<Subcontractor>;
  weight?: Maybe<Scalars['Float']>;
  isMapped: Scalars['Boolean'];
  parentTask?: Maybe<Task>;
  object?: Maybe<Scalars['String']>;
  tags: Array<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  aggregatedProgresses: Array<AggregatedProgress>;
  aggregatedPlannedProgresses: Array<AggregatedPlannedProgress>;
  actualDates: Array<Dates>;
};


export type ComponentGuiIndexArgs = {
  taskId?: Maybe<Scalars['UUID']>;
  planId?: Maybe<Scalars['UUID']>;
};


export type ComponentWeightArgs = {
  taskId?: Maybe<Scalars['UUID']>;
  planId?: Maybe<Scalars['UUID']>;
};


export type ComponentParentTaskArgs = {
  planId: Scalars['UUID'];
};


export type ComponentAggregatedProgressesArgs = {
  planId: Scalars['UUID'];
  spaceIds: Array<Scalars['UUID']>;
  spaceTags?: Maybe<Array<Scalars['String']>>;
  spaceGroupingTags?: Maybe<Array<Scalars['String']>>;
  componentTags?: Maybe<Array<Scalars['String']>>;
  batchIds: Array<Scalars['UUID']>;
  excludeHoistSpaces?: Maybe<Scalars['Boolean']>;
  forceSlowAggs?: Maybe<Scalars['Boolean']>;
  onlyInProgressTasks?: Maybe<Scalars['Boolean']>;
};


export type ComponentAggregatedPlannedProgressesArgs = {
  planId: Scalars['UUID'];
  spaceIds: Array<Scalars['UUID']>;
  spaceTags?: Maybe<Array<Scalars['String']>>;
  spaceGroupingTags?: Maybe<Array<Scalars['String']>>;
  componentTags?: Maybe<Array<Scalars['String']>>;
  timestamps: Array<Scalars['Date']>;
  excludeHoistSpaces?: Maybe<Scalars['Boolean']>;
  forceSlowAggs?: Maybe<Scalars['Boolean']>;
};


export type ComponentActualDatesArgs = {
  spaceIds: Array<Scalars['UUID']>;
  spaceTags?: Maybe<Array<Scalars['String']>>;
  spaceGroupingTags?: Maybe<Array<Scalars['String']>>;
  componentTags?: Maybe<Array<Scalars['String']>>;
  planId: Scalars['UUID'];
  excludeHoistSpaces?: Maybe<Scalars['Boolean']>;
};

export type ComponentType = {
  __typename?: 'ComponentType';
  id: Scalars['UUID'];
  name: Scalars['String'];
  description: Scalars['String'];
};

export type CreateArrowInputType = {
  sceneId: Scalars['UUID'];
  targetSceneId: Scalars['UUID'];
  batchId: Scalars['UUID'];
  location: AnnotationLocationInputType;
};

export type CreateComponentInputType = {
  taskId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  componentTypeId: Scalars['UUID'];
  subcontractorId?: Maybe<Scalars['UUID']>;
  weight?: Maybe<Scalars['Float']>;
  object: Scalars['String'];
  tags: Array<Scalars['String']>;
};

export type CreateFloorplanInputType = {
  spaceId: Scalars['UUID'];
  originX: Scalars['Float'];
  originY: Scalars['Float'];
  width: Scalars['Float'];
  height: Scalars['Float'];
  mmScaleFactor: Scalars['Float'];
  floorplanFile: Scalars['Upload'];
  bimOriginX?: Maybe<Scalars['Float']>;
  bimOriginY?: Maybe<Scalars['Float']>;
  bimOriginZ?: Maybe<Scalars['Float']>;
  floorHeight?: Maybe<Scalars['Float']>;
  angleToTrueNorth?: Maybe<Scalars['Float']>;
  bimProjectId?: Maybe<Scalars['String']>;
  bimFloorIds?: Maybe<Array<Scalars['String']>>;
};

export type CreateJiraIssueInputType = {
  username: Scalars['String'];
  type: JiraIssueType;
  uniqueShotId: Scalars['String'];
  week: Scalars['String'];
  path: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};

export type CreateProjectInputType = {
  customer: Scalars['String'];
  project: Scalars['String'];
  displayName: Scalars['String'];
  defaultLanguage: Scalars['String'];
};

export type CreateScanSequenceInputType = {
  floorplanId: Scalars['UUID'];
  sequencePrefix: ScanSequencePrefixType;
  scanLevel: ScanSequenceLevelType;
  sceneIds: Array<Scalars['UUID']>;
};

export type CreateSceneInputType = {
  name: Scalars['String'];
  spaceId: Scalars['UUID'];
  mmX?: Maybe<Scalars['Float']>;
  mmY?: Maybe<Scalars['Float']>;
};

export type CreateSpaceInputType = {
  name: Scalars['String'];
  category?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  parentSpaceId?: Maybe<Scalars['UUID']>;
  tags?: Maybe<Array<Scalars['String']>>;
};

export type CreateSpotlightInputType = {
  type: SpotlightType;
  mappingId?: Maybe<Scalars['UUID']>;
  subcontractorId?: Maybe<Scalars['UUID']>;
  sceneId: Scalars['UUID'];
  location: AnnotationLocationInputType;
  createdInBatchId: Scalars['UUID'];
  description?: Maybe<Scalars['String']>;
  p2Type?: Maybe<P2SpotlightType>;
  defaultPriority?: Maybe<SpotlightPriority>;
  priority?: Maybe<SpotlightPriority>;
  userCreated?: Maybe<Scalars['Boolean']>;
};

export type CreateTaskInputType = {
  name: Scalars['String'];
  planId: Scalars['UUID'];
  parentTaskId?: Maybe<Scalars['UUID']>;
};


export type Dates = {
  __typename?: 'Dates';
  task?: Maybe<Task>;
  component?: Maybe<Component>;
  plan?: Maybe<Plan>;
  space?: Maybe<Space>;
  spaceTags?: Maybe<Array<Scalars['String']>>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};

export type DeleteProjectInputType = {
  customer: Scalars['String'];
  project: Scalars['String'];
};

export enum DocumentType {
  DefaultDeliverable = 'defaultDeliverable',
  DeveloperDeliverable = 'developerDeliverable',
  Configuration = 'configuration'
}

export type DumpSheetSceneData = {
  __typename?: 'DumpSheetSceneData';
  sceneShell360Id: Scalars['String'];
  sceneName: Scalars['String'];
  ancestorIds: Array<Maybe<Scalars['String']>>;
  ancestorNames: Array<Scalars['String']>;
  sceneLocation: Scalars['String'];
  floorName?: Maybe<Scalars['String']>;
  scanLevel?: Maybe<ScanSequenceLevelType>;
  sequenceNumber?: Maybe<Scalars['String']>;
  sceneAliasShell360Id?: Maybe<Scalars['String']>;
};

export type FileGroup = {
  __typename?: 'FileGroup';
  files: Array<S3File>;
  name: Scalars['String'];
};

export type FloatUpdate = {
  newValue: Scalars['Float'];
};

export type Floorplan = {
  __typename?: 'Floorplan';
  id: Scalars['UUID'];
  signedUrl: Scalars['String'];
  originX: Scalars['Float'];
  originY: Scalars['Float'];
  width: Scalars['Float'];
  height: Scalars['Float'];
  mmScaleFactor: Scalars['Float'];
  bimOriginX?: Maybe<Scalars['Float']>;
  bimOriginY?: Maybe<Scalars['Float']>;
  bimOriginZ?: Maybe<Scalars['Float']>;
  floorHeight?: Maybe<Scalars['Float']>;
  angleToTrueNorth?: Maybe<Scalars['Float']>;
  space: Space;
  bimProjectId?: Maybe<Scalars['String']>;
  bimFloorIds?: Maybe<Array<Scalars['String']>>;
};

export type Image = {
  __typename?: 'Image';
  imageId: Scalars['String'];
  state: ChangeState;
};

export type InferredSchedule = ScheduleInterface & {
  __typename?: 'InferredSchedule';
  task: Task;
  space: Space;
  ranges: Array<Range>;
  inferredFrom: Space;
};

export type JiraIssue = {
  __typename?: 'JiraIssue';
  id: Scalars['Int'];
  uniqueShotId: Scalars['String'];
  type: JiraIssueType;
  message?: Maybe<Scalars['String']>;
};

export enum JiraIssueType {
  Level = 'LEVEL',
  Arrow = 'ARROW',
  Map = 'MAP',
  FaceBlur = 'FACE_BLUR',
  ChangeDetection = 'CHANGE_DETECTION'
}

export type Mapping = {
  __typename?: 'Mapping';
  id: Scalars['UUID'];
  space: Space;
  component: Component;
  disabled: Scalars['Boolean'];
  rawProgress?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};


export type MappingRawProgressArgs = {
  batchId: Scalars['UUID'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createSpotlight: Spotlight;
  updateSpotlight?: Maybe<Spotlight>;
  deleteSpotlight?: Maybe<Scalars['Boolean']>;
  createComponent: Component;
  updateComponents: Array<Component>;
  deleteComponents?: Maybe<Scalars['Boolean']>;
  removeComponentsFromTask?: Maybe<Scalars['Boolean']>;
  createPlan: Plan;
  updatePlan: Plan;
  deletePlan: Scalars['UUID'];
  createTask: Task;
  deleteTasks: Array<Scalars['UUID']>;
  calculateWeights: Scalars['Boolean'];
  calculatePlannedProgresses: Scalars['Int'];
  updateBatches: Array<Batch>;
  updateTasks: Array<Task>;
  createSpaces: Array<Space>;
  updateSpaces: Array<Space>;
  patchSpaces: Array<Space>;
  deleteSpaces: Array<Scalars['UUID']>;
  copySpatialData: Array<Space>;
  createScenes: Array<Scene>;
  updateScenes: Array<Scene>;
  patchScenes: Array<Scene>;
  deleteScenes: Array<Scalars['UUID']>;
  uploadProgressFile?: Maybe<Scalars['Boolean']>;
  uploadDatesFile?: Maybe<Scalars['Boolean']>;
  uploadHolidaysFile?: Maybe<Scalars['Boolean']>;
  uploadBuildingStructureFile?: Maybe<Scalars['Boolean']>;
  uploadPlansTreeFile?: Maybe<Scalars['Boolean']>;
  updateRawMappingProgresses: Scalars['Int'];
  clearDates: Scalars['Int'];
  clearComponentTags: Scalars['Int'];
  clearSpaceTags: Scalars['Int'];
  createMappings: Array<Mapping>;
  updateMappings: Array<Mapping>;
  deleteMappings: Array<Scalars['UUID']>;
  releaseDataToExternal?: Maybe<Scalars['Boolean']>;
  updateInternalAggServProgresses?: Maybe<Scalars['Boolean']>;
  createJiraIssue: JiraIssue;
  deleteJiraIssue: Scalars['Boolean'];
  createArrow: Arrow;
  updateArrow: Arrow;
  updateArrowLocationInImage: Arrow;
  deleteArrow: Scalars['UUID'];
  deleteAllHolidays?: Maybe<Scalars['Boolean']>;
  /** @deprecated please use patchShot instead */
  zeroPointShot: Shot;
  deleteShot?: Maybe<Scalars['Boolean']>;
  patchShot: Shot;
  createProject: Scalars['Boolean'];
  deleteProject: Scalars['Boolean'];
  updateProjectStartDate: Project;
  createSubcontractors: Array<Subcontractor>;
  createComponentType: ComponentType;
  shareScreenshot?: Maybe<Scalars['Boolean']>;
  refreshComponentsTaskTags?: Maybe<Scalars['Boolean']>;
  createFloorplan: Floorplan;
  patchFloorplan: Floorplan;
  deleteFloorplan: Scalars['UUID'];
  createScanSequence: ScanSequence;
  updateScanSequence: ScanSequence;
  deleteScanSequence: Scalars['UUID'];
  updateIsReleasedProjectConfig: ProjectConfig;
};


export type MutationCreateSpotlightArgs = {
  tenant: TenantInputType;
  spotlight: CreateSpotlightInputType;
};


export type MutationUpdateSpotlightArgs = {
  tenant: TenantInputType;
  spotlight: UpdateSpotlightInputType;
};


export type MutationDeleteSpotlightArgs = {
  tenant: TenantInputType;
  spotlightId: Scalars['UUID'];
};


export type MutationCreateComponentArgs = {
  tenant: TenantInputType;
  component: CreateComponentInputType;
};


export type MutationUpdateComponentsArgs = {
  tenant: TenantInputType;
  components: Array<UpdateComponentInputType>;
};


export type MutationDeleteComponentsArgs = {
  tenant: TenantInputType;
  ids: Array<Scalars['UUID']>;
};


export type MutationRemoveComponentsFromTaskArgs = {
  tenant: TenantInputType;
  componentIds: Array<Scalars['UUID']>;
  taskId: Scalars['UUID'];
};


export type MutationCreatePlanArgs = {
  tenant: TenantInputType;
  planName: Scalars['String'];
  startDate?: Maybe<Scalars['Date']>;
};


export type MutationUpdatePlanArgs = {
  tenant: TenantInputType;
  id: Scalars['UUID'];
  planName: Scalars['String'];
  startDate?: Maybe<Scalars['Date']>;
};


export type MutationDeletePlanArgs = {
  tenant: TenantInputType;
  id: Scalars['UUID'];
};


export type MutationCreateTaskArgs = {
  tenant: TenantInputType;
  task: CreateTaskInputType;
};


export type MutationDeleteTasksArgs = {
  tenant: TenantInputType;
  ids: Array<Scalars['UUID']>;
};


export type MutationCalculateWeightsArgs = {
  tenant: TenantInputType;
  spaceIds?: Maybe<Array<Scalars['UUID']>>;
  taskIds?: Maybe<Array<Scalars['UUID']>>;
  planIds?: Maybe<Array<Scalars['UUID']>>;
};


export type MutationCalculatePlannedProgressesArgs = {
  tenant: TenantInputType;
  planId: Scalars['UUID'];
};


export type MutationUpdateBatchesArgs = {
  tenant: TenantInputType;
  batches: Array<UpdateBatchInputType>;
};


export type MutationUpdateTasksArgs = {
  tenant: TenantInputType;
  tasks: Array<UpdateTaskInputType>;
};


export type MutationCreateSpacesArgs = {
  tenant: TenantInputType;
  spaces: Array<CreateSpaceInputType>;
};


export type MutationUpdateSpacesArgs = {
  tenant: TenantInputType;
  spaces: Array<UpdateSpaceInputType>;
};


export type MutationPatchSpacesArgs = {
  tenant: TenantInputType;
  spaceIds: Array<Scalars['UUID']>;
  update: PatchSpaceInputType;
};


export type MutationDeleteSpacesArgs = {
  tenant: TenantInputType;
  spaceIds: Array<Scalars['UUID']>;
};


export type MutationCopySpatialDataArgs = {
  tenant: TenantInputType;
  existingRootSpaceId: Scalars['UUID'];
  newRootParentSpaceId: Scalars['UUID'];
  newSpaceName?: Maybe<Scalars['String']>;
  newFloorHeight?: Maybe<Scalars['Float']>;
};


export type MutationCreateScenesArgs = {
  tenant: TenantInputType;
  scenes: Array<CreateSceneInputType>;
};


export type MutationUpdateScenesArgs = {
  tenant: TenantInputType;
  scenes: Array<UpdateSceneInputType>;
};


export type MutationPatchScenesArgs = {
  tenant: TenantInputType;
  sceneIds: Array<Scalars['UUID']>;
  update: PatchSceneInputType;
};


export type MutationDeleteScenesArgs = {
  tenant: TenantInputType;
  sceneIds: Array<Scalars['UUID']>;
};


export type MutationUploadProgressFileArgs = {
  tenant: TenantInputType;
  progressFile: Scalars['Upload'];
};


export type MutationUploadDatesFileArgs = {
  tenant: TenantInputType;
  datesFile: Scalars['Upload'];
};


export type MutationUploadHolidaysFileArgs = {
  tenant: TenantInputType;
  holidaysFile: Scalars['Upload'];
};


export type MutationUploadBuildingStructureFileArgs = {
  tenant: TenantInputType;
  buildingStructureFile: Scalars['Upload'];
  parentId?: Maybe<Scalars['UUID']>;
};


export type MutationUploadPlansTreeFileArgs = {
  tenant: TenantInputType;
  plansTreeFile: Scalars['Upload'];
  planId: Scalars['UUID'];
};


export type MutationUpdateRawMappingProgressesArgs = {
  tenant: TenantInputType;
  batchId: Scalars['UUID'];
  componentId: Scalars['UUID'];
  spaceId: Scalars['UUID'];
  progress: Scalars['Int'];
};


export type MutationClearDatesArgs = {
  tenant: TenantInputType;
  planId: Scalars['UUID'];
  taskSpace?: Maybe<Array<ClearDateInputType>>;
};


export type MutationClearComponentTagsArgs = {
  tenant: TenantInputType;
  prefix: Scalars['String'];
};


export type MutationClearSpaceTagsArgs = {
  tenant: TenantInputType;
  prefix: Scalars['String'];
};


export type MutationCreateMappingsArgs = {
  tenant: TenantInputType;
  spaceIds: Array<Scalars['UUID']>;
  componentIds: Array<Scalars['UUID']>;
};


export type MutationUpdateMappingsArgs = {
  tenant: TenantInputType;
  mappings: Array<UpdateMappingInputType>;
};


export type MutationDeleteMappingsArgs = {
  tenant: TenantInputType;
  mappingIds: Array<Scalars['UUID']>;
};


export type MutationReleaseDataToExternalArgs = {
  tenant: TenantInputType;
};


export type MutationUpdateInternalAggServProgressesArgs = {
  tenant: TenantInputType;
};


export type MutationCreateJiraIssueArgs = {
  tenant: TenantInputType;
  issue: CreateJiraIssueInputType;
};


export type MutationDeleteJiraIssueArgs = {
  tenant: TenantInputType;
  issueId: Scalars['String'];
};


export type MutationCreateArrowArgs = {
  tenant: TenantInputType;
  arrow: CreateArrowInputType;
};


export type MutationUpdateArrowArgs = {
  tenant: TenantInputType;
  arrow: UpdateArrowInputType;
};


export type MutationUpdateArrowLocationInImageArgs = {
  tenant: TenantInputType;
  id: Scalars['UUID'];
  locationInImage: AnnotationLocationInputType;
};


export type MutationDeleteArrowArgs = {
  tenant: TenantInputType;
  id: Scalars['UUID'];
};


export type MutationDeleteAllHolidaysArgs = {
  tenant: TenantInputType;
};


export type MutationZeroPointShotArgs = {
  tenant: TenantInputType;
  shotId: Scalars['UUID'];
  newYaw: Scalars['Float'];
};


export type MutationDeleteShotArgs = {
  tenant: TenantInputType;
  shotId: Scalars['UUID'];
};


export type MutationPatchShotArgs = {
  tenant: TenantInputType;
  shotId: Scalars['UUID'];
  patch: PatchShotInputType;
};


export type MutationCreateProjectArgs = {
  input: CreateProjectInputType;
};


export type MutationDeleteProjectArgs = {
  input: DeleteProjectInputType;
};


export type MutationUpdateProjectStartDateArgs = {
  input: UpdateStartDateInputType;
};


export type MutationCreateSubcontractorsArgs = {
  tenant: TenantInputType;
  subcontractors: Array<Scalars['String']>;
};


export type MutationCreateComponentTypeArgs = {
  tenant: TenantInputType;
  name: Scalars['String'];
  description: Scalars['String'];
};


export type MutationShareScreenshotArgs = {
  tenant: TenantInputType;
  to: Array<Scalars['String']>;
  cc: Array<Scalars['String']>;
  subject: Scalars['String'];
  body: Scalars['String'];
  screenshotName: Scalars['String'];
  screenshotPayload: Scalars['String'];
};


export type MutationRefreshComponentsTaskTagsArgs = {
  tenant: TenantInputType;
};


export type MutationCreateFloorplanArgs = {
  tenant: TenantInputType;
  floorplan: CreateFloorplanInputType;
};


export type MutationPatchFloorplanArgs = {
  tenant: TenantInputType;
  floorplan: PatchFloorplanInputType;
};


export type MutationDeleteFloorplanArgs = {
  tenant: TenantInputType;
  id: Scalars['UUID'];
};


export type MutationCreateScanSequenceArgs = {
  tenant: TenantInputType;
  scanSequence: CreateScanSequenceInputType;
};


export type MutationUpdateScanSequenceArgs = {
  tenant: TenantInputType;
  scanSequence: UpdateScanSequenceInputType;
};


export type MutationDeleteScanSequenceArgs = {
  tenant: TenantInputType;
  id: Scalars['UUID'];
};


export type MutationUpdateIsReleasedProjectConfigArgs = {
  input: UpdateIsReleasedProjectConfigInput;
};

export type NullableFloatUpdate = {
  newValue?: Maybe<Scalars['Float']>;
};

export type NullableStringUpdate = {
  newValue?: Maybe<Scalars['String']>;
};

export type NullableUuidUpdate = {
  newValue?: Maybe<Scalars['UUID']>;
};

export enum P2SpotlightType {
  PotentialRework = 'PotentialRework',
  PotentialDelay = 'PotentialDelay',
  ReworkNeeded = 'ReworkNeeded',
  ReworkInProgress = 'ReworkInProgress',
  NonConformance = 'NonConformance',
  Unknown = 'Unknown'
}

export type PvaSpaceInput = {
  mappings: Array<SubcontractorSpaceMapping>;
};

export type PvaSubcontractorProgress = {
  __typename?: 'PVASubcontractorProgress';
  spaceId: Scalars['UUID'];
  subcontractorId: Scalars['UUID'];
  actualProgress?: Maybe<Scalars['Float']>;
  plannedProgress?: Maybe<Scalars['Float']>;
  batchIds: Scalars['UUID'];
  batchTimestamps: Scalars['Date'];
};

export type PvaTaskProgress = {
  __typename?: 'PVATaskProgress';
  activityIds: Array<Scalars['UUID']>;
  spaceId: Scalars['UUID'];
  subcontractorId: Scalars['UUID'];
  actualProgress?: Maybe<Scalars['Float']>;
  plannedProgress?: Maybe<Scalars['Float']>;
};

export type PanoramaConfig = {
  __typename?: 'PanoramaConfig';
  imageId: Scalars['String'];
  initialHfov: Scalars['Float'];
  initialYaw: Scalars['Float'];
  initialPitch: Scalars['Float'];
  basePath: Scalars['String'];
  pathTemplate: Scalars['String'];
  cubeResolution: Scalars['Int'];
  tileResolution: Scalars['Int'];
  maxLevel: Scalars['Int'];
  extension: Scalars['String'];
  authQueryStrings: Array<AuthQueryStringMapping>;
  uniqueImageId?: Maybe<Scalars['String']>;
};

export type ParsingMapping = {
  __typename?: 'ParsingMapping';
  id: Scalars['UUID'];
  ancestorNames: Array<Scalars['String']>;
};

export type PatchFloorplanInputType = {
  id: Scalars['UUID'];
  setSpaceId?: Maybe<Scalars['UUID']>;
  setOriginX?: Maybe<Scalars['Float']>;
  setOriginY?: Maybe<Scalars['Float']>;
  setWidth?: Maybe<Scalars['Float']>;
  setHeight?: Maybe<Scalars['Float']>;
  setMMScaleFactor?: Maybe<Scalars['Float']>;
  setFloorplanFile?: Maybe<Scalars['Upload']>;
  setBimOriginX?: Maybe<Scalars['Float']>;
  setBimOriginY?: Maybe<Scalars['Float']>;
  setBimOriginZ?: Maybe<Scalars['Float']>;
  setFloorHeight?: Maybe<Scalars['Float']>;
  setAngleToTrueNorth?: Maybe<Scalars['Float']>;
  setBimProjectId?: Maybe<Scalars['String']>;
  setBimFloorIds?: Maybe<Array<Scalars['String']>>;
};

export type PatchSceneInputType = {
  setName?: Maybe<StringUpdate>;
  setSpaceId?: Maybe<UuidUpdate>;
  setMMX?: Maybe<NullableFloatUpdate>;
  setMMY?: Maybe<NullableFloatUpdate>;
  setMMZOffset?: Maybe<FloatUpdate>;
  setAliasSceneId?: Maybe<NullableUuidUpdate>;
  addSubjects?: Maybe<Array<SceneSubject>>;
  removeSubjects?: Maybe<Array<SceneSubject>>;
};

export type PatchShotInputType = {
  setYaw?: Maybe<FloatUpdate>;
  setImageId?: Maybe<NullableStringUpdate>;
};

export type PatchSpaceInputType = {
  setName?: Maybe<StringUpdate>;
  setCategory?: Maybe<StringUpdate>;
  setType?: Maybe<StringUpdate>;
  addTags?: Maybe<Array<Scalars['String']>>;
  removeTags?: Maybe<Array<Scalars['String']>>;
  setParentId?: Maybe<NullableUuidUpdate>;
};

export type Permission = {
  action: Action;
  resource: Scalars['String'];
};

export type Plan = {
  __typename?: 'Plan';
  id: Scalars['UUID'];
  shell360Id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  tasks: Array<Task>;
  components: Array<Component>;
  aggregatedProgresses: Array<AggregatedProgress>;
  aggregatedPlannedProgresses: Array<AggregatedPlannedProgress>;
  actualDates: Array<Dates>;
};


export type PlanTasksArgs = {
  rootTasksOnly?: Scalars['Boolean'];
};


export type PlanAggregatedProgressesArgs = {
  spaceIds: Array<Scalars['UUID']>;
  spaceTags?: Maybe<Array<Scalars['String']>>;
  spaceGroupingTags?: Maybe<Array<Scalars['String']>>;
  componentTags?: Maybe<Array<Scalars['String']>>;
  batchIds: Array<Scalars['UUID']>;
  subcontractorId?: Maybe<Scalars['UUID']>;
  subcontractorIds?: Maybe<Array<Scalars['UUID']>>;
  excludeHoistSpaces?: Maybe<Scalars['Boolean']>;
  forceSlowAggs?: Maybe<Scalars['Boolean']>;
  onlyInProgressTasks?: Maybe<Scalars['Boolean']>;
};


export type PlanAggregatedPlannedProgressesArgs = {
  spaceIds: Array<Scalars['UUID']>;
  spaceTags?: Maybe<Array<Scalars['String']>>;
  spaceGroupingTags?: Maybe<Array<Scalars['String']>>;
  componentTags?: Maybe<Array<Scalars['String']>>;
  timestamps: Array<Scalars['Date']>;
  subcontractorId?: Maybe<Scalars['UUID']>;
  subcontractorIds?: Maybe<Array<Scalars['UUID']>>;
  excludeHoistSpaces?: Maybe<Scalars['Boolean']>;
  forceSlowAggs?: Maybe<Scalars['Boolean']>;
};


export type PlanActualDatesArgs = {
  spaceIds: Array<Scalars['UUID']>;
  spaceTags?: Maybe<Array<Scalars['String']>>;
  spaceGroupingTags?: Maybe<Array<Scalars['String']>>;
  componentTags?: Maybe<Array<Scalars['String']>>;
  excludeHoistSpaces?: Maybe<Scalars['Boolean']>;
};

export type PlannedBatch = {
  __typename?: 'PlannedBatch';
  id: Scalars['UUID'];
  timestamp: Scalars['Date'];
  weekNumber: Scalars['Int'];
};

export type ProgressFilter = {
  taskId: Scalars['UUID'];
  spaceId: Scalars['UUID'];
  batchId: Scalars['UUID'];
};

export type ProgressParsingFilter = {
  depth: Scalars['Int'];
  name: Scalars['String'];
};

export enum ProgressStatus {
  NotStarted = 'NOT_STARTED',
  InProgress = 'IN_PROGRESS',
  Finished = 'FINISHED'
}

export type Project = {
  __typename?: 'Project';
  customer: Scalars['String'];
  project: Scalars['String'];
  scopes: Array<Scope>;
  config: ProjectConfig;
};

export type ProjectConfig = {
  __typename?: 'ProjectConfig';
  displayName?: Maybe<Scalars['String']>;
  defaultLanguage: Scalars['String'];
  startDate?: Maybe<Scalars['Date']>;
  isReleased: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  aggservSyncDate?: Maybe<Scalars['Date']>;
  batches: Array<Batch>;
  bimToken: BimToken;
  bimUrns?: Maybe<Array<BimUrn>>;
  components: Array<Component>;
  componentsByIds: Array<Component>;
  component: Component;
  componentsExcludingPlan: Array<Component>;
  componentTypes: Array<ComponentType>;
  objectTypes: Array<Scalars['String']>;
  componentTagTypes: Array<Scalars['String']>;
  componentTagTypesByPlan: Array<Scalars['String']>;
  /**
   * Get floorplans passing an optional list of floorplan ids.
   * If ids are not provided all floorplans will be returned.
   */
  floorplans: Array<Floorplan>;
  plans: Array<Plan>;
  plan: Plan;
  tasksByParentPlan: Array<Task>;
  task: Task;
  tasksByIds: Array<Task>;
  space: Space;
  scene?: Maybe<Scene>;
  scenesByIds: Array<Scene>;
  projects: Array<Project>;
  projectsForPermissions: Array<Project>;
  jiraIssueForShotAndIssueType: JiraIssue;
  jiraIssuesForShot: Array<JiraIssue>;
  /**
   * Get the list of scenes that specify the order they meant to be captured
   * by the site scanner (ScanSequence) based on a set of Filters
   */
  scanSequencesByFilter: Array<ScanSequence>;
  spacesByFilter: Array<Space>;
  searchSpaces: Array<Space>;
  spacesByIds: Array<Space>;
  subcontractor: Subcontractor;
  subcontractors: Array<Subcontractor>;
  shot?: Maybe<Shot>;
  /** @deprecated this will go soon, use the spotlightsByIds */
  spotlight: Spotlight;
  spotlightsByIds: Array<Spotlight>;
  spotlightsByFilter: Array<Spotlight>;
  spotlightHistoryBySpotlightIds: Array<SpotlightHistoryBySpotlightId>;
  mappingsForParse: Array<ParsingMapping>;
  spaceCategories: Array<Scalars['String']>;
  spaceTypes: Array<Scalars['String']>;
  spaceTags: Array<Scalars['String']>;
  generateDatesQAData: Array<RawSchedule>;
  generateDumpSheetData: Array<DumpSheetSceneData>;
  arbitraryAggregation: Array<AggregatedArbitraryProgress>;
  arbitraryPlannedAggregation: Array<AggregatedArbitraryPlannedProgress>;
  holidays: Array<Scalars['Date']>;
  releaseHistory: Array<ReleaseLog>;
  documents: Array<FileGroup>;
  documentByPath: FileGroup;
  canEditJiraIssues?: Maybe<Scalars['Boolean']>;
  earliestTimestampOnWorkQueue?: Maybe<Scalars['Date']>;
  shotByBatchAvailability?: Maybe<Array<ShotByBatchResult>>;
  getSequenceDetails: Array<SequenceDetail>;
  plannedBatches?: Maybe<Array<PlannedBatch>>;
  pvaTasksProgressBySpace: Array<PvaTaskProgress>;
  pvaSubcontractorProgressBySpace: Array<PvaSubcontractorProgress>;
};


export type QueryAggservSyncDateArgs = {
  tenant: TenantInputType;
};


export type QueryBatchesArgs = {
  tenant: TenantInputType;
  ids?: Maybe<Array<Scalars['UUID']>>;
};


export type QueryBimTokenArgs = {
  tenant: TenantInputType;
};


export type QueryBimUrnsArgs = {
  tenant: TenantInputType;
};


export type QueryComponentsArgs = {
  tenant: TenantInputType;
  taskId?: Maybe<Scalars['UUID']>;
};


export type QueryComponentsByIdsArgs = {
  tenant: TenantInputType;
  componentIds: Array<Scalars['UUID']>;
};


export type QueryComponentArgs = {
  tenant: TenantInputType;
  id: Scalars['UUID'];
};


export type QueryComponentsExcludingPlanArgs = {
  tenant: TenantInputType;
  planIdToExclude: Scalars['UUID'];
};


export type QueryComponentTypesArgs = {
  tenant: TenantInputType;
};


export type QueryObjectTypesArgs = {
  tenant: TenantInputType;
};


export type QueryComponentTagTypesArgs = {
  tenant: TenantInputType;
};


export type QueryComponentTagTypesByPlanArgs = {
  tenant: TenantInputType;
  planId: Scalars['UUID'];
};


export type QueryFloorplansArgs = {
  tenant: TenantInputType;
  ids?: Maybe<Array<Scalars['UUID']>>;
};


export type QueryPlansArgs = {
  tenant: TenantInputType;
};


export type QueryPlanArgs = {
  tenant: TenantInputType;
  id: Scalars['UUID'];
};


export type QueryTasksByParentPlanArgs = {
  tenant: TenantInputType;
  planId: Scalars['UUID'];
};


export type QueryTaskArgs = {
  tenant: TenantInputType;
  id: Scalars['UUID'];
};


export type QueryTasksByIdsArgs = {
  tenant: TenantInputType;
  ids: Array<Scalars['UUID']>;
};


export type QuerySpaceArgs = {
  tenant: TenantInputType;
  id: Scalars['UUID'];
};


export type QuerySceneArgs = {
  tenant: TenantInputType;
  id: Scalars['UUID'];
};


export type QueryScenesByIdsArgs = {
  tenant: TenantInputType;
  ids: Array<Scalars['UUID']>;
};


export type QueryProjectsArgs = {
  customer?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['String']>;
};


export type QueryProjectsForPermissionsArgs = {
  templatedPermissions: Array<Permission>;
};


export type QueryJiraIssueForShotAndIssueTypeArgs = {
  tenant: TenantInputType;
  uniqueShotId: Scalars['String'];
  issueType: JiraIssueType;
};


export type QueryJiraIssuesForShotArgs = {
  tenant: TenantInputType;
  uniqueShotId: Scalars['String'];
};


export type QueryScanSequencesByFilterArgs = {
  tenant: TenantInputType;
  scanSequenceFilters: ScanSequenceFilters;
};


export type QuerySpacesByFilterArgs = {
  tenant: TenantInputType;
  spaceFilters: SpaceFilters;
};


export type QuerySearchSpacesArgs = {
  tenant: TenantInputType;
  searchTerms: Array<Scalars['String']>;
  categories: Array<Scalars['String']>;
  types: Array<Scalars['String']>;
  tags: Array<Scalars['String']>;
  includeAncestors?: Maybe<Scalars['Boolean']>;
};


export type QuerySpacesByIdsArgs = {
  tenant: TenantInputType;
  ids?: Maybe<Array<Scalars['UUID']>>;
  shell360Ids?: Maybe<Array<Scalars['String']>>;
};


export type QuerySubcontractorArgs = {
  tenant: TenantInputType;
  id: Scalars['UUID'];
};


export type QuerySubcontractorsArgs = {
  tenant: TenantInputType;
};


export type QueryShotArgs = {
  tenant: TenantInputType;
  id?: Maybe<Scalars['UUID']>;
  sceneId?: Maybe<Scalars['UUID']>;
  batchId?: Maybe<Scalars['UUID']>;
};


export type QuerySpotlightArgs = {
  tenant: TenantInputType;
  id: Scalars['UUID'];
  batchId?: Maybe<Scalars['UUID']>;
};


export type QuerySpotlightsByIdsArgs = {
  tenant: TenantInputType;
  ids: Array<Scalars['UUID']>;
  batchId?: Maybe<Scalars['UUID']>;
};


export type QuerySpotlightsByFilterArgs = {
  tenant: TenantInputType;
  spotlightFilters: SpotlightFilters;
};


export type QuerySpotlightHistoryBySpotlightIdsArgs = {
  tenant: TenantInputType;
  ids: Array<Scalars['UUID']>;
};


export type QueryMappingsForParseArgs = {
  tenant: TenantInputType;
  componentIds: Array<Scalars['UUID']>;
  parsingFilters: Array<ProgressParsingFilter>;
};


export type QuerySpaceCategoriesArgs = {
  tenant: TenantInputType;
};


export type QuerySpaceTypesArgs = {
  tenant: TenantInputType;
};


export type QuerySpaceTagsArgs = {
  tenant: TenantInputType;
};


export type QueryGenerateDatesQaDataArgs = {
  tenant: TenantInputType;
};


export type QueryGenerateDumpSheetDataArgs = {
  tenant: TenantInputType;
};


export type QueryArbitraryAggregationArgs = {
  tenant: TenantInputType;
  spaceIds: Array<Scalars['UUID']>;
  componentIds?: Maybe<Array<Scalars['UUID']>>;
  taskIds?: Maybe<Array<Scalars['UUID']>>;
  planId?: Maybe<Scalars['UUID']>;
  subcontractorId?: Maybe<Scalars['UUID']>;
  batchIds: Array<Scalars['UUID']>;
  excludeHoistSpaces?: Maybe<Scalars['Boolean']>;
  forceSlowAggs?: Maybe<Scalars['Boolean']>;
  forPva?: Maybe<Scalars['Boolean']>;
};


export type QueryArbitraryPlannedAggregationArgs = {
  tenant: TenantInputType;
  spaceIds: Array<Scalars['UUID']>;
  componentIds?: Maybe<Array<Scalars['UUID']>>;
  taskIds?: Maybe<Array<Scalars['UUID']>>;
  planId?: Maybe<Scalars['UUID']>;
  subcontractorId?: Maybe<Scalars['UUID']>;
  timestamps: Array<Scalars['Date']>;
  excludeHoistSpaces?: Maybe<Scalars['Boolean']>;
  forceSlowAggs?: Maybe<Scalars['Boolean']>;
  forPva?: Maybe<Scalars['Boolean']>;
};


export type QueryHolidaysArgs = {
  tenant: TenantInputType;
};


export type QueryReleaseHistoryArgs = {
  tenant: TenantInputType;
  mostRecent?: Maybe<Scalars['Int']>;
};


export type QueryDocumentsArgs = {
  tenant: TenantInputType;
  type: DocumentType;
};


export type QueryDocumentByPathArgs = {
  tenant: TenantInputType;
  path: Scalars['String'];
};


export type QueryCanEditJiraIssuesArgs = {
  tenant: TenantInputType;
};


export type QueryEarliestTimestampOnWorkQueueArgs = {
  tenant: TenantInputType;
};


export type QueryShotByBatchAvailabilityArgs = {
  tenant: TenantInputType;
  sceneId: Scalars['UUID'];
};


export type QueryGetSequenceDetailsArgs = {
  tenant: TenantInputType;
  floorPlanId: Scalars['String'];
};


export type QueryPlannedBatchesArgs = {
  tenant: TenantInputType;
};


export type QueryPvaTasksProgressBySpaceArgs = {
  tenant: TenantInputType;
  activityIds: Array<Scalars['UUID']>;
  spaceIds: Array<Scalars['UUID']>;
  timestamps: Array<Scalars['Date']>;
  subcontractorId: Scalars['UUID'];
  batchIds: Array<Scalars['UUID']>;
  forceSlowAggs?: Maybe<Scalars['Boolean']>;
};


export type QueryPvaSubcontractorProgressBySpaceArgs = {
  tenant: TenantInputType;
  planId: Scalars['UUID'];
  batchIds: Array<Scalars['UUID']>;
  batchTimestamps: Array<Scalars['Date']>;
  subcontractorSpaceMappings: PvaSpaceInput;
  excludeHoistSpaces?: Maybe<Scalars['Boolean']>;
};

export type Range = {
  __typename?: 'Range';
  weight: Scalars['Float'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};

export type RawSchedule = {
  __typename?: 'RawSchedule';
  taskId: Scalars['UUID'];
  spaceId: Scalars['UUID'];
  weight: Scalars['Float'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};

export type ReleaseLog = {
  __typename?: 'ReleaseLog';
  timestamp: Scalars['Date'];
  username: Scalars['String'];
};

export type S3File = {
  __typename?: 'S3File';
  name: Scalars['String'];
  /** @deprecated this will go soon, use the signedUrlInstead */
  key: Scalars['String'];
  /** @deprecated this will go soon, use the signedUrlInstead */
  shell360Path: Scalars['String'];
  signedUrl: Scalars['String'];
};

export type ScanSequence = {
  __typename?: 'ScanSequence';
  id: Scalars['UUID'];
  floorplan: Floorplan;
  sequencePrefix: ScanSequencePrefixType;
  scanLevel: ScanSequenceLevelType;
  scenes: Array<Scene>;
};

export type ScanSequenceFilters = {
  floorplanId?: Maybe<Scalars['UUID']>;
};

export enum ScanSequenceLevelType {
  Early = 'EARLY',
  Mid = 'MID',
  Late = 'LATE'
}

export enum ScanSequencePrefixType {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  L = 'L',
  M = 'M',
  N = 'N',
  O = 'O',
  P = 'P',
  Q = 'Q',
  R = 'R',
  S = 'S',
  T = 'T',
  U = 'U',
  V = 'V',
  W = 'W',
  X = 'X',
  Y = 'Y',
  Z = 'Z'
}

export type Scene = {
  __typename?: 'Scene';
  id: Scalars['UUID'];
  shell360Id: Scalars['String'];
  name: Scalars['String'];
  guiIndex: Scalars['Int'];
  space: Space;
  path: Array<Scalars['String']>;
  shots: Array<Shot>;
  shot?: Maybe<Shot>;
  previousShot?: Maybe<Shot>;
  spotlights: Array<Spotlight>;
  nextScene?: Maybe<Scene>;
  previousScene?: Maybe<Scene>;
  nextIdenticalScene?: Maybe<Scene>;
  previousIdenticalScene?: Maybe<Scene>;
  aliasScene?: Maybe<Scene>;
  subjects: Array<SceneSubject>;
  mmX?: Maybe<Scalars['Float']>;
  mmY?: Maybe<Scalars['Float']>;
  mmZOffset: Scalars['Float'];
};


export type SceneShotArgs = {
  batchId: Scalars['UUID'];
};


export type ScenePreviousShotArgs = {
  batchId: Scalars['UUID'];
};


export type SceneSpotlightsArgs = {
  batchId: Scalars['UUID'];
};


export type SceneNextSceneArgs = {
  batchId?: Maybe<Scalars['UUID']>;
  subTreeRootId?: Maybe<Scalars['UUID']>;
};


export type ScenePreviousSceneArgs = {
  batchId?: Maybe<Scalars['UUID']>;
  subTreeRootId?: Maybe<Scalars['UUID']>;
};


export type SceneNextIdenticalSceneArgs = {
  batchId?: Maybe<Scalars['UUID']>;
  subTree: Scalars['UUID'];
};


export type ScenePreviousIdenticalSceneArgs = {
  batchId?: Maybe<Scalars['UUID']>;
  subTree: Scalars['UUID'];
};

export enum SceneSubject {
  Firestop = 'FIRESTOP',
  Riser = 'RISER',
  Facade = 'FACADE',
  HighCeiling = 'HIGH_CEILING',
  BuildingFullHeight = 'BUILDING_FULL_HEIGHT'
}

export type Schedule = ScheduleInterface & {
  __typename?: 'Schedule';
  task: Task;
  space: Space;
  ranges: Array<Range>;
};

export type ScheduleInterface = {
  task: Task;
  space: Space;
  ranges: Array<Range>;
};

export enum Scope {
  Internal = 'internal',
  External = 'external'
}

export type SequenceDetail = {
  __typename?: 'SequenceDetail';
  x_mm: Scalars['Float'];
  y_mm: Scalars['Float'];
  sequencenumber: Scalars['String'];
};

export type Shot = {
  __typename?: 'Shot';
  id: Scalars['UUID'];
  shell360Id: Scalars['String'];
  imageId?: Maybe<Scalars['String']>;
  /** @deprecated use batch instead */
  batchId: Scalars['UUID'];
  batch: Batch;
  missing: Scalars['Boolean'];
  imageConfig: PanoramaConfig;
  yaw: Scalars['Float'];
  arrows: Array<Arrow>;
  spotlights: Array<Spotlight>;
  scene?: Maybe<Scene>;
};

export type ShotByBatchResult = {
  __typename?: 'ShotByBatchResult';
  batchId: Scalars['String'];
  sceneId: Scalars['String'];
  available: Scalars['Boolean'];
};

export type Space = {
  __typename?: 'Space';
  id: Scalars['UUID'];
  guiIndex: Scalars['Int'];
  guiIndexPath: Array<Scalars['Int']>;
  name: Scalars['String'];
  category?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  tags: Array<Scalars['String']>;
  shell360Id?: Maybe<Scalars['String']>;
  parentSpace?: Maybe<Space>;
  ancestors: Array<Space>;
  siblingSpaces: Array<Space>;
  subSpaces: Array<Space>;
  descendantSpaces: Array<Space>;
  hasImages: Scalars['Boolean'];
  scenes: Array<Scene>;
  defaultScene?: Maybe<Scene>;
  mappings: Array<Mapping>;
  componentsUnderSpace: Array<Component>;
  schedules: Array<ScheduleInterface>;
};


export type SpaceGuiIndexPathArgs = {
  includeSelf?: Scalars['Boolean'];
};


export type SpaceAncestorsArgs = {
  includeSelf?: Scalars['Boolean'];
};


export type SpaceSiblingSpacesArgs = {
  includeSelf?: Scalars['Boolean'];
};


export type SpaceDescendantSpacesArgs = {
  depth?: Maybe<Scalars['Int']>;
  tags?: Maybe<Array<Scalars['String']>>;
};


export type SpaceHasImagesArgs = {
  batchId: Scalars['UUID'];
};


export type SpaceDefaultSceneArgs = {
  batchId?: Maybe<Scalars['UUID']>;
};


export type SpaceMappingsArgs = {
  planId?: Maybe<Scalars['UUID']>;
};


export type SpaceComponentsUnderSpaceArgs = {
  planId?: Maybe<Scalars['UUID']>;
};


export type SpaceSchedulesArgs = {
  planId: Scalars['UUID'];
};

export type SpaceFilters = {
  id?: Maybe<Array<Scalars['UUID']>>;
  parentSpaceId?: Maybe<Scalars['UUID']>;
  ancestorSpaceId?: Maybe<Scalars['UUID']>;
  type?: Maybe<Scalars['UUID']>;
  subSpaceIds?: Maybe<Array<Scalars['UUID']>>;
  tags?: Maybe<Array<Scalars['String']>>;
};

export type Spotlight = {
  __typename?: 'Spotlight';
  id: Scalars['UUID'];
  type: SpotlightType;
  mapping?: Maybe<Mapping>;
  subcontractor?: Maybe<Subcontractor>;
  location: AnnotationLocation;
  scene?: Maybe<Scene>;
  userCreated: Scalars['Boolean'];
  createdInBatch: Batch;
  resolvedInBatch?: Maybe<Batch>;
  description?: Maybe<Scalars['String']>;
  p2Type: P2SpotlightType;
  defaultPriority: SpotlightPriority;
  comment?: Maybe<Scalars['String']>;
  commentAuthor?: Maybe<Scalars['String']>;
  status: SpotlightStatus;
  priority: SpotlightPriority;
};


export type SpotlightLocationArgs = {
  batchId?: Maybe<Scalars['UUID']>;
};

export type SpotlightFilters = {
  batchId?: Maybe<Scalars['UUID']>;
  batchIds?: Maybe<Array<Scalars['UUID']>>;
  spaceIds?: Maybe<Array<Scalars['UUID']>>;
  sceneIds?: Maybe<Array<Scalars['UUID']>>;
  workIds?: Maybe<Array<Scalars['UUID']>>;
  types?: Maybe<Array<SpotlightType>>;
  componentTags?: Maybe<Array<Scalars['String']>>;
  spaceTags?: Maybe<Array<Scalars['String']>>;
  subcontractors?: Maybe<Array<Scalars['UUID']>>;
  p2Types?: Maybe<Array<P2SpotlightType>>;
  priorities?: Maybe<Array<SpotlightPriority>>;
  statuses?: Maybe<Array<SpotlightStatus>>;
};

export type SpotlightHistory = {
  __typename?: 'SpotlightHistory';
  author: Scalars['String'];
  timestamp: Scalars['Date'];
  body: SpotlightHistoryBody;
};

export type SpotlightHistoryBody = {
  __typename?: 'SpotlightHistoryBody';
  id: Scalars['UUID'];
  mappingId?: Maybe<Scalars['UUID']>;
  sceneId?: Maybe<Scalars['UUID']>;
  published?: Maybe<Scalars['Boolean']>;
  userCreated?: Maybe<Scalars['Boolean']>;
  subcontractorId?: Maybe<Scalars['UUID']>;
  type?: Maybe<SpotlightType>;
  location?: Maybe<AnnotationLocation>;
  batchId?: Maybe<Scalars['UUID']>;
  createdInBatchId?: Maybe<Scalars['UUID']>;
  resolvedInBatchId?: Maybe<Scalars['UUID']>;
  description?: Maybe<Scalars['String']>;
  p2Type?: Maybe<P2SpotlightType>;
  defaultPriority?: Maybe<SpotlightPriority>;
  comment?: Maybe<Scalars['String']>;
  commentAuthor?: Maybe<Scalars['String']>;
  status?: Maybe<SpotlightStatus>;
  priority?: Maybe<SpotlightPriority>;
};

export type SpotlightHistoryBySpotlightId = {
  __typename?: 'SpotlightHistoryBySpotlightId';
  spotlightId: Scalars['UUID'];
  history: Array<SpotlightHistory>;
};

export enum SpotlightPriority {
  High = 'HIGH',
  Medium = 'MEDIUM',
  Low = 'LOW'
}

export enum SpotlightStatus {
  Open = 'OPEN',
  ActionList = 'ACTION_LIST',
  Resolved = 'RESOLVED',
  Incorrect = 'INCORRECT'
}

export enum SpotlightType {
  MakeGood = 'MAKE_GOOD',
  NotCompleteAndSoonInaccessible = 'NOT_COMPLETE_AND_SOON_INACCESSIBLE',
  LocationMismatch = 'LOCATION_MISMATCH',
  PartiallyIncomplete = 'PARTIALLY_INCOMPLETE',
  PartiallyRemoved = 'PARTIALLY_REMOVED',
  SequenceMismatch = 'SEQUENCE_MISMATCH',
  ElementMismatch = 'ELEMENT_MISMATCH',
  DoubleCheck = 'DOUBLE_CHECK',
  LeaveDown = 'LEAVE_DOWN',
  Obsolete = 'OBSOLETE',
  ExternalSystemSpotlightType = 'EXTERNAL_SYSTEM_SPOTLIGHT_TYPE'
}

export type StringUpdate = {
  newValue: Scalars['String'];
};

export type Subcontractor = {
  __typename?: 'Subcontractor';
  id: Scalars['UUID'];
  name: Scalars['String'];
  spaces?: Maybe<Array<Space>>;
};

export type SubcontractorSpaceMapping = {
  subcontractorId: Scalars['UUID'];
  spaceIds: Array<Scalars['UUID']>;
};

export type Task = {
  __typename?: 'Task';
  id: Scalars['UUID'];
  shell360Id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  guiIndex: Scalars['Int'];
  parentTask?: Maybe<Task>;
  childTasks: Array<Task>;
  descendantTasks: Array<Task>;
  childComponents: Array<Component>;
  plan: Plan;
  isMapped: Scalars['Boolean'];
  ancestors: Array<Task>;
  aggregatedProgresses: Array<AggregatedProgress>;
  aggregatedPlannedProgresses: Array<AggregatedPlannedProgress>;
  actualDates: Array<Dates>;
};


export type TaskAncestorsArgs = {
  includeSelf?: Scalars['Boolean'];
};


export type TaskAggregatedProgressesArgs = {
  spaceIds: Array<Scalars['UUID']>;
  spaceTags?: Maybe<Array<Scalars['String']>>;
  spaceGroupingTags?: Maybe<Array<Scalars['String']>>;
  componentTags?: Maybe<Array<Scalars['String']>>;
  batchIds: Array<Scalars['UUID']>;
  subcontractorId?: Maybe<Scalars['UUID']>;
  subcontractorIds?: Maybe<Array<Scalars['UUID']>>;
  excludeHoistSpaces?: Maybe<Scalars['Boolean']>;
  forceSlowAggs?: Maybe<Scalars['Boolean']>;
  onlyInProgressTasks?: Maybe<Scalars['Boolean']>;
};


export type TaskAggregatedPlannedProgressesArgs = {
  spaceIds: Array<Scalars['UUID']>;
  spaceTags?: Maybe<Array<Scalars['String']>>;
  spaceGroupingTags?: Maybe<Array<Scalars['String']>>;
  componentTags?: Maybe<Array<Scalars['String']>>;
  timestamps: Array<Scalars['Date']>;
  subcontractorId?: Maybe<Scalars['UUID']>;
  subcontractorIds?: Maybe<Array<Scalars['UUID']>>;
  excludeHoistSpaces?: Maybe<Scalars['Boolean']>;
  forceSlowAggs?: Maybe<Scalars['Boolean']>;
};


export type TaskActualDatesArgs = {
  spaceIds: Array<Scalars['UUID']>;
  spaceTags?: Maybe<Array<Scalars['String']>>;
  spaceGroupingTags?: Maybe<Array<Scalars['String']>>;
  componentTags?: Maybe<Array<Scalars['String']>>;
  excludeHoistSpaces?: Maybe<Scalars['Boolean']>;
};

export type TenantInputType = {
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
};


export type UuidUpdate = {
  newValue: Scalars['UUID'];
};

export type UpdateArrowInputType = {
  id: Scalars['UUID'];
  sceneId: Scalars['UUID'];
  targetSceneId: Scalars['UUID'];
  batchId: Scalars['UUID'];
  location: AnnotationLocationInputType;
};

export type UpdateBatchInputType = {
  id: Scalars['UUID'];
  timestamp: Scalars['Date'];
  weekNumber: Scalars['Int'];
};

export type UpdateComponentInputType = {
  id: Scalars['UUID'];
  taskId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  componentTypeId?: Maybe<Scalars['UUID']>;
  subcontractorId?: Maybe<Scalars['UUID']>;
  weight?: Maybe<Scalars['Float']>;
  guiIndex?: Maybe<Scalars['Int']>;
  object?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
};

export type UpdateIsReleasedProjectConfigInput = {
  customer: Scalars['String'];
  project: Scalars['String'];
  isReleased: Scalars['Boolean'];
};

export type UpdateMappingInputType = {
  id: Scalars['UUID'];
  disabled: Scalars['Boolean'];
};

export type UpdateScanSequenceInputType = {
  id: Scalars['UUID'];
  floorplanId: Scalars['UUID'];
  sequencePrefix: ScanSequencePrefixType;
  scanLevel: ScanSequenceLevelType;
  sceneIds: Array<Scalars['UUID']>;
};

export type UpdateSceneInputType = {
  id: Scalars['UUID'];
  name: Scalars['String'];
  guiIndex: Scalars['Int'];
  spaceId: Scalars['UUID'];
  mmX?: Maybe<Scalars['Float']>;
  mmY?: Maybe<Scalars['Float']>;
  mmZOffset?: Maybe<Scalars['Float']>;
};

export type UpdateSpaceInputType = {
  id: Scalars['UUID'];
  name: Scalars['String'];
  guiIndex: Scalars['Int'];
  category?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type UpdateSpotlightInputType = {
  id: Scalars['UUID'];
  batchId?: Maybe<Scalars['UUID']>;
  type?: Maybe<SpotlightType>;
  mappingId?: Maybe<Scalars['UUID']>;
  subcontractorId?: Maybe<Scalars['UUID']>;
  location?: Maybe<AnnotationLocationInputType>;
  sceneId?: Maybe<Scalars['UUID']>;
  createdInBatchId?: Maybe<Scalars['UUID']>;
  resolvedInBatchId?: Maybe<Scalars['UUID']>;
  description?: Maybe<Scalars['String']>;
  p2Type?: Maybe<P2SpotlightType>;
  defaultPriority?: Maybe<SpotlightPriority>;
  comment?: Maybe<Scalars['String']>;
  status?: Maybe<SpotlightStatus>;
  priority?: Maybe<SpotlightPriority>;
};

export type UpdateStartDateInputType = {
  customer: Scalars['String'];
  project: Scalars['String'];
  startDate: Scalars['Date'];
};

export type UpdateTaskInputType = {
  id: Scalars['UUID'];
  name: Scalars['String'];
  guiIndex: Scalars['Int'];
  planId: Scalars['UUID'];
  parentTaskId?: Maybe<Scalars['UUID']>;
};


export type User = {
  __typename?: 'User';
  username: Scalars['String'];
};

export type UpdateInternalAggservProgressesMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
}>;


export type UpdateInternalAggservProgressesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateInternalAggServProgresses'>
);

export type GetAggservSyncDateQueryVariables = Exact<{
  tenant: TenantInputType;
}>;


export type GetAggservSyncDateQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'aggservSyncDate'>
);

export type UploadBuildingStructureMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  file: Scalars['Upload'];
  parentId?: Maybe<Scalars['UUID']>;
}>;


export type UploadBuildingStructureMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadBuildingStructureFile'>
);

export type GetSpaceNameByIdQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  spaceId: Scalars['UUID'];
}>;


export type GetSpaceNameByIdQuery = (
  { __typename?: 'Query' }
  & { spacesByIds: Array<(
    { __typename?: 'Space' }
    & Pick<Space, 'id' | 'name'>
  )> }
);

export type GetAllComponentsWithTagsQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
}>;


export type GetAllComponentsWithTagsQuery = (
  { __typename?: 'Query' }
  & { components: Array<(
    { __typename?: 'Component' }
    & Pick<Component, 'name' | 'id' | 'tags'>
  )> }
);

export type GetAllSpacesWithTagsQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
}>;


export type GetAllSpacesWithTagsQuery = (
  { __typename?: 'Query' }
  & { spacesByFilter: Array<(
    { __typename?: 'Space' }
    & Pick<Space, 'name' | 'id' | 'tags' | 'guiIndexPath'>
    & { ancestors: Array<(
      { __typename?: 'Space' }
      & Pick<Space, 'name'>
    )>, descendantSpaces: Array<(
      { __typename?: 'Space' }
      & Pick<Space, 'id' | 'name' | 'guiIndexPath' | 'tags'>
      & { ancestors: Array<(
        { __typename?: 'Space' }
        & Pick<Space, 'name'>
      )> }
    )> }
  )> }
);

export type ComponentFragment = (
  { __typename?: 'Component' }
  & Pick<Component, 'id' | 'name' | 'weight' | 'guiIndex' | 'object' | 'tags'>
  & { componentType: (
    { __typename?: 'ComponentType' }
    & Pick<ComponentType, 'id' | 'name'>
  ), subcontractor?: Maybe<(
    { __typename?: 'Subcontractor' }
    & Pick<Subcontractor, 'id' | 'name'>
  )> }
);

export type ComponentWithoutTaskFragment = (
  { __typename?: 'Component' }
  & Pick<Component, 'id' | 'name' | 'object' | 'tags'>
  & { componentType: (
    { __typename?: 'ComponentType' }
    & Pick<ComponentType, 'id' | 'name'>
  ), subcontractor?: Maybe<(
    { __typename?: 'Subcontractor' }
    & Pick<Subcontractor, 'id' | 'name'>
  )> }
);

export type GetComponentsByTaskQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  taskId?: Maybe<Scalars['UUID']>;
}>;


export type GetComponentsByTaskQuery = (
  { __typename?: 'Query' }
  & { components: Array<(
    { __typename?: 'Component' }
    & ComponentFragment
  )> }
);

export type GetComponentsExcludingPlanQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  planIdToExclude: Scalars['UUID'];
}>;


export type GetComponentsExcludingPlanQuery = (
  { __typename?: 'Query' }
  & { componentsExcludingPlan: Array<(
    { __typename?: 'Component' }
    & ComponentWithoutTaskFragment
  )> }
);

export type GetObjectTypesQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
}>;


export type GetObjectTypesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'objectTypes'>
);

export type GetComponentTagTypesQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
}>;


export type GetComponentTagTypesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'componentTagTypes'>
);

export type CreateComponentMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  name: Scalars['String'];
  taskId?: Maybe<Scalars['UUID']>;
  componentTypeId: Scalars['UUID'];
  subcontractorId?: Maybe<Scalars['UUID']>;
  weight?: Maybe<Scalars['Float']>;
  object: Scalars['String'];
  tags: Array<Scalars['String']> | Scalars['String'];
}>;


export type CreateComponentMutation = (
  { __typename?: 'Mutation' }
  & { createComponent: (
    { __typename?: 'Component' }
    & ComponentFragment
  ) }
);

export type DeleteComponentsMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  ids: Array<Scalars['UUID']> | Scalars['UUID'];
}>;


export type DeleteComponentsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteComponents'>
);

export type RemoveComponentsFromTaskMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  componentIds: Array<Scalars['UUID']> | Scalars['UUID'];
  taskId: Scalars['UUID'];
}>;


export type RemoveComponentsFromTaskMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeComponentsFromTask'>
);

export type UpdateComponentsMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  components: Array<UpdateComponentInputType> | UpdateComponentInputType;
  taskId?: Maybe<Scalars['UUID']>;
}>;


export type UpdateComponentsMutation = (
  { __typename?: 'Mutation' }
  & { updateComponents: Array<(
    { __typename?: 'Component' }
    & ComponentFragment
  )> }
);

export type CreateMappingsMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  componentIds: Array<Scalars['UUID']> | Scalars['UUID'];
  spaceIds: Array<Scalars['UUID']> | Scalars['UUID'];
}>;


export type CreateMappingsMutation = (
  { __typename?: 'Mutation' }
  & { createMappings: Array<(
    { __typename?: 'Mapping' }
    & Pick<Mapping, 'id' | 'disabled'>
    & { space: (
      { __typename?: 'Space' }
      & Pick<Space, 'id' | 'name'>
    ), component: (
      { __typename?: 'Component' }
      & Pick<Component, 'id' | 'name'>
    ) }
  )> }
);

export type UpdateMappingsMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  mappingUpdates: Array<UpdateMappingInputType> | UpdateMappingInputType;
}>;


export type UpdateMappingsMutation = (
  { __typename?: 'Mutation' }
  & { updateMappings: Array<(
    { __typename?: 'Mapping' }
    & Pick<Mapping, 'id' | 'disabled'>
  )> }
);

export type DeleteMappingsMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  mappingIds: Array<Scalars['UUID']> | Scalars['UUID'];
}>;


export type DeleteMappingsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteMappings'>
);

export type SpaceWithMappingDetailsFragment = (
  { __typename?: 'Space' }
  & Pick<Space, 'id' | 'name' | 'guiIndex'>
  & { ancestors: Array<(
    { __typename?: 'Space' }
    & Pick<Space, 'name' | 'guiIndex'>
  )>, mappings: Array<(
    { __typename?: 'Mapping' }
    & Pick<Mapping, 'disabled'>
    & MappingFragmentFragment
  )> }
);

export type GetSpacesAndComponentsDataQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  spaceIds: Array<Scalars['UUID']> | Scalars['UUID'];
  componentIds: Array<Scalars['UUID']> | Scalars['UUID'];
}>;


export type GetSpacesAndComponentsDataQuery = (
  { __typename?: 'Query' }
  & { spacesByIds: Array<(
    { __typename?: 'Space' }
    & SpaceWithMappingDetailsFragment
  )>, componentsByIds: Array<(
    { __typename?: 'Component' }
    & Pick<Component, 'id' | 'name'>
  )> }
);

export type UpdateComponentTagsMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  components: Array<UpdateComponentInputType> | UpdateComponentInputType;
}>;


export type UpdateComponentTagsMutation = (
  { __typename?: 'Mutation' }
  & { updateComponents: Array<(
    { __typename?: 'Component' }
    & Pick<Component, 'name' | 'id' | 'tags'>
  )> }
);

export type GetAllComponentTagTypesByPlanQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  planId: Scalars['UUID'];
}>;


export type GetAllComponentTagTypesByPlanQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'componentTagTypesByPlan'>
);

export type GetExistingComponentsQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  componentIds: Array<Scalars['UUID']> | Scalars['UUID'];
  planId: Scalars['UUID'];
}>;


export type GetExistingComponentsQuery = (
  { __typename?: 'Query' }
  & { componentsByIds: Array<(
    { __typename?: 'Component' }
    & Pick<Component, 'name' | 'id' | 'tags' | 'guiIndex'>
    & { parentTask?: Maybe<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'name' | 'guiIndex'>
      & { ancestors: Array<(
        { __typename?: 'Task' }
        & Pick<Task, 'id' | 'name' | 'guiIndex'>
      )> }
    )> }
  )> }
);

export type PatchSpacesMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  ids: Array<Scalars['UUID']> | Scalars['UUID'];
  update: PatchSpaceInputType;
}>;


export type PatchSpacesMutation = (
  { __typename?: 'Mutation' }
  & { patchSpaces: Array<(
    { __typename?: 'Space' }
    & Pick<Space, 'id' | 'name' | 'type' | 'category' | 'tags'>
    & { parentSpace?: Maybe<(
      { __typename?: 'Space' }
      & Pick<Space, 'id'>
    )> }
  )> }
);

export type CreateSpacesMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  spaces: Array<CreateSpaceInputType> | CreateSpaceInputType;
}>;


export type CreateSpacesMutation = (
  { __typename?: 'Mutation' }
  & { createSpaces: Array<(
    { __typename?: 'Space' }
    & Pick<Space, 'id' | 'name' | 'type' | 'category' | 'tags'>
    & { parentSpace?: Maybe<(
      { __typename?: 'Space' }
      & Pick<Space, 'id'>
    )> }
  )> }
);

export type DeleteSpacesMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  spaceIds: Array<Scalars['UUID']> | Scalars['UUID'];
}>;


export type DeleteSpacesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSpaces'>
);

export type UpdateScenesMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  scenes: Array<UpdateSceneInputType> | UpdateSceneInputType;
}>;


export type UpdateScenesMutation = (
  { __typename?: 'Mutation' }
  & { updateScenes: Array<(
    { __typename?: 'Scene' }
    & Pick<Scene, 'id' | 'name' | 'guiIndex'>
    & { space: (
      { __typename?: 'Space' }
      & Pick<Space, 'id'>
    ) }
  )> }
);

export type CreateScenesMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  scenes: Array<CreateSceneInputType> | CreateSceneInputType;
}>;


export type CreateScenesMutation = (
  { __typename?: 'Mutation' }
  & { createScenes: Array<(
    { __typename?: 'Scene' }
    & Pick<Scene, 'id' | 'name' | 'guiIndex'>
    & { space: (
      { __typename?: 'Space' }
      & Pick<Space, 'id'>
    ) }
  )> }
);

export type DeleteScenesMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  sceneIds: Array<Scalars['UUID']> | Scalars['UUID'];
}>;


export type DeleteScenesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteScenes'>
);

export type GetExistingSpaceTagsQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
}>;


export type GetExistingSpaceTagsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'spaceTags'>
);

export type GetDatesQaDataQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
}>;


export type GetDatesQaDataQuery = (
  { __typename?: 'Query' }
  & { generateDatesQAData: Array<(
    { __typename?: 'RawSchedule' }
    & Pick<RawSchedule, 'taskId' | 'spaceId' | 'weight' | 'startDate' | 'endDate'>
  )> }
);

export type DeleteShotMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  shotId: Scalars['UUID'];
}>;


export type DeleteShotMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteShot'>
);

export type MarkShotMissingMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  shotId: Scalars['UUID'];
}>;


export type MarkShotMissingMutation = (
  { __typename?: 'Mutation' }
  & { patchShot: (
    { __typename?: 'Shot' }
    & Pick<Shot, 'id'>
  ) }
);

export type MappingFragmentFragment = (
  { __typename?: 'Mapping' }
  & Pick<Mapping, 'id'>
  & { component: (
    { __typename?: 'Component' }
    & Pick<Component, 'id' | 'name'>
    & { subcontractor?: Maybe<(
      { __typename?: 'Subcontractor' }
      & Pick<Subcontractor, 'id' | 'name'>
    )> }
  ) }
);

export type SpotlightFragmentFragment = (
  { __typename?: 'Spotlight' }
  & Pick<Spotlight, 'id' | 'type'>
  & { location: (
    { __typename?: 'AnnotationLocation' }
    & Pick<AnnotationLocation, 'yaw' | 'pitch'>
  ), mapping?: Maybe<(
    { __typename?: 'Mapping' }
    & { space: (
      { __typename?: 'Space' }
      & Pick<Space, 'id' | 'name'>
    ) }
    & MappingFragmentFragment
  )>, createdInBatch: (
    { __typename?: 'Batch' }
    & Pick<Batch, 'id'>
  ), resolvedInBatch?: Maybe<(
    { __typename?: 'Batch' }
    & Pick<Batch, 'id'>
  )> }
);

export type ArrowFragmentFragment = (
  { __typename?: 'Arrow' }
  & Pick<Arrow, 'id' | 'missing'>
  & { targetScene?: Maybe<(
    { __typename?: 'Scene' }
    & Pick<Scene, 'id' | 'name'>
    & { space: (
      { __typename?: 'Space' }
      & Pick<Space, 'id' | 'name'>
    ) }
  )>, location: (
    { __typename?: 'AnnotationLocation' }
    & Pick<AnnotationLocation, 'yaw' | 'pitch'>
  ) }
);

export type ShotFragmentFragment = (
  { __typename?: 'Shot' }
  & Pick<Shot, 'id'>
  & { imageConfig: (
    { __typename?: 'PanoramaConfig' }
    & Pick<PanoramaConfig, 'imageId' | 'uniqueImageId' | 'initialHfov' | 'initialYaw' | 'initialPitch' | 'basePath' | 'pathTemplate' | 'cubeResolution' | 'tileResolution' | 'extension' | 'maxLevel'>
    & { authQueryStrings: Array<(
      { __typename?: 'AuthQueryStringMapping' }
      & Pick<AuthQueryStringMapping, 'tileSuffix' | 'queryString'>
    )> }
  ), scene?: Maybe<(
    { __typename?: 'Scene' }
    & Pick<Scene, 'id' | 'shell360Id' | 'mmX' | 'mmY' | 'mmZOffset'>
  )>, spotlights: Array<(
    { __typename?: 'Spotlight' }
    & SpotlightFragmentFragment
  )>, arrows: Array<(
    { __typename?: 'Arrow' }
    & ArrowFragmentFragment
  )> }
);

export type GetShotBySceneAndBatchQueryVariables = Exact<{
  batchId: Scalars['UUID'];
  sceneId: Scalars['UUID'];
  tenant: TenantInputType;
}>;


export type GetShotBySceneAndBatchQuery = (
  { __typename?: 'Query' }
  & { shot?: Maybe<(
    { __typename?: 'Shot' }
    & ShotFragmentFragment
  )> }
);

export type SpaceWithMappingFragment = (
  { __typename?: 'Space' }
  & Pick<Space, 'id' | 'name'>
  & { mappings: Array<(
    { __typename?: 'Mapping' }
    & MappingFragmentFragment
  )> }
);

export type ZeroPointShotMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  shotId: Scalars['UUID'];
  yaw: Scalars['Float'];
  batchId: Scalars['UUID'];
}>;


export type ZeroPointShotMutation = (
  { __typename?: 'Mutation' }
  & { zeroPointShot: (
    { __typename?: 'Shot' }
    & ShotFragmentFragment
  ) }
);

export type GetMappingsForSpaceQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  spaceId: Scalars['UUID'];
}>;


export type GetMappingsForSpaceQuery = (
  { __typename?: 'Query' }
  & { space: (
    { __typename?: 'Space' }
    & { ancestors: Array<(
      { __typename?: 'Space' }
      & SpaceWithMappingFragment
    )>, siblingSpaces: Array<(
      { __typename?: 'Space' }
      & SpaceWithMappingFragment
    )>, descendantSpaces: Array<(
      { __typename?: 'Space' }
      & SpaceWithMappingFragment
    )> }
    & SpaceWithMappingFragment
  ) }
);

export type GetSceneQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  sceneId: Scalars['UUID'];
}>;


export type GetSceneQuery = (
  { __typename?: 'Query' }
  & { scene?: Maybe<(
    { __typename?: 'Scene' }
    & Pick<Scene, 'id' | 'shell360Id'>
    & { space: (
      { __typename?: 'Space' }
      & Pick<Space, 'id'>
    ) }
  )> }
);

export type GetSceneCoordinatesQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  sceneId: Scalars['UUID'];
}>;


export type GetSceneCoordinatesQuery = (
  { __typename?: 'Query' }
  & { scene?: Maybe<(
    { __typename?: 'Scene' }
    & Pick<Scene, 'id' | 'mmX' | 'mmY' | 'mmZOffset'>
  )> }
);

export type CreateSpotlightMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  sceneId: Scalars['UUID'];
  mappingId: Scalars['UUID'];
  type: SpotlightType;
  yaw: Scalars['Float'];
  pitch: Scalars['Float'];
  createdInBatchId: Scalars['UUID'];
  batchId: Scalars['UUID'];
}>;


export type CreateSpotlightMutation = (
  { __typename?: 'Mutation' }
  & { createSpotlight: (
    { __typename?: 'Spotlight' }
    & SpotlightFragmentFragment
  ) }
);

export type UpdateSpotlightMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  id: Scalars['UUID'];
  batchId: Scalars['UUID'];
  mappingId?: Maybe<Scalars['UUID']>;
  type?: Maybe<SpotlightType>;
  location?: Maybe<AnnotationLocationInputType>;
  createdInBatchId?: Maybe<Scalars['UUID']>;
  resolvedInBatchId?: Maybe<Scalars['UUID']>;
}>;


export type UpdateSpotlightMutation = (
  { __typename?: 'Mutation' }
  & { updateSpotlight?: Maybe<(
    { __typename?: 'Spotlight' }
    & SpotlightFragmentFragment
  )> }
);

export type DeleteArrowMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  id: Scalars['UUID'];
}>;


export type DeleteArrowMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteArrow'>
);

export type CreateArrowMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  sceneId: Scalars['UUID'];
  targetSceneId: Scalars['UUID'];
  batchId: Scalars['UUID'];
  yaw: Scalars['Float'];
  pitch: Scalars['Float'];
}>;


export type CreateArrowMutation = (
  { __typename?: 'Mutation' }
  & { createArrow: (
    { __typename?: 'Arrow' }
    & ArrowFragmentFragment
  ) }
);

export type UpdateArrowMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  id: Scalars['UUID'];
  sceneId: Scalars['UUID'];
  targetSceneId: Scalars['UUID'];
  batchId: Scalars['UUID'];
  yaw: Scalars['Float'];
  pitch: Scalars['Float'];
}>;


export type UpdateArrowMutation = (
  { __typename?: 'Mutation' }
  & { updateArrow: (
    { __typename?: 'Arrow' }
    & ArrowFragmentFragment
  ) }
);

export type GetBimTokenQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
}>;


export type GetBimTokenQuery = (
  { __typename?: 'Query' }
  & { bimToken: (
    { __typename?: 'BimToken' }
    & Pick<BimToken, 'token' | 'expiresIn'>
  ) }
);

export type GetBimUrnQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
}>;


export type GetBimUrnQuery = (
  { __typename?: 'Query' }
  & { bimUrns?: Maybe<Array<(
    { __typename?: 'BimUrn' }
    & Pick<BimUrn, 'id' | 'title' | 'urn'>
  )>> }
);

export type UploadProgressFileMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  file: Scalars['Upload'];
}>;


export type UploadProgressFileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadProgressFile'>
);

export type UploadDatesFileMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  file: Scalars['Upload'];
}>;


export type UploadDatesFileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadDatesFile'>
);

export type FloorplanIdsQueryVariables = Exact<{
  tenant: TenantInputType;
}>;


export type FloorplanIdsQuery = (
  { __typename?: 'Query' }
  & { floorplans: Array<(
    { __typename?: 'Floorplan' }
    & Pick<Floorplan, 'id'>
    & { space: (
      { __typename?: 'Space' }
      & Pick<Space, 'id'>
    ) }
  )> }
);

export type FloorplanByIdQueryVariables = Exact<{
  tenant: TenantInputType;
  id: Scalars['UUID'];
}>;


export type FloorplanByIdQuery = (
  { __typename?: 'Query' }
  & { floorplans: Array<(
    { __typename?: 'Floorplan' }
    & Pick<Floorplan, 'id' | 'signedUrl' | 'originX' | 'originY' | 'width' | 'height' | 'mmScaleFactor' | 'bimOriginX' | 'bimOriginY' | 'bimOriginZ' | 'floorHeight' | 'angleToTrueNorth' | 'bimProjectId' | 'bimFloorIds'>
    & { space: (
      { __typename?: 'Space' }
      & Pick<Space, 'id' | 'name'>
      & { ancestors: Array<(
        { __typename?: 'Space' }
        & Pick<Space, 'name'>
      )> }
    ) }
  )> }
);

export type CreateFloorplanMutationVariables = Exact<{
  tenant: TenantInputType;
  floorplan: CreateFloorplanInputType;
}>;


export type CreateFloorplanMutation = (
  { __typename?: 'Mutation' }
  & { createFloorplan: (
    { __typename?: 'Floorplan' }
    & Pick<Floorplan, 'id'>
    & { space: (
      { __typename?: 'Space' }
      & Pick<Space, 'id'>
    ) }
  ) }
);

export type PatchFloorplanMutationVariables = Exact<{
  tenant: TenantInputType;
  floorplan: PatchFloorplanInputType;
}>;


export type PatchFloorplanMutation = (
  { __typename?: 'Mutation' }
  & { patchFloorplan: (
    { __typename?: 'Floorplan' }
    & Pick<Floorplan, 'id'>
    & { space: (
      { __typename?: 'Space' }
      & Pick<Space, 'id'>
    ) }
  ) }
);

export type DeleteFoorplanMutationVariables = Exact<{
  tenant: TenantInputType;
  id: Scalars['UUID'];
}>;


export type DeleteFoorplanMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteFloorplan'>
);

export type GetHolidaysQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
}>;


export type GetHolidaysQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'holidays'>
);

export type UploadHolidaysFileMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  file: Scalars['Upload'];
}>;


export type UploadHolidaysFileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadHolidaysFile'>
);

export type DeleteAllHolidaysMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
}>;


export type DeleteAllHolidaysMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAllHolidays'>
);

export type GetProjectConfigQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
}>;


export type GetProjectConfigQuery = (
  { __typename?: 'Query' }
  & { projects: Array<(
    { __typename?: 'Project' }
    & Pick<Project, 'customer' | 'project'>
    & { config: (
      { __typename?: 'ProjectConfig' }
      & Pick<ProjectConfig, 'displayName' | 'defaultLanguage' | 'startDate' | 'isReleased'>
    ) }
  )> }
);

export type UpdateIsReleasedProjectConfigMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  isReleased: Scalars['Boolean'];
}>;


export type UpdateIsReleasedProjectConfigMutation = (
  { __typename?: 'Mutation' }
  & { updateIsReleasedProjectConfig: (
    { __typename?: 'ProjectConfig' }
    & Pick<ProjectConfig, 'isReleased'>
  ) }
);

export type GetSequenceDetailsQueryVariables = Exact<{
  tenant: TenantInputType;
  floorPlanId: Scalars['String'];
}>;


export type GetSequenceDetailsQuery = (
  { __typename?: 'Query' }
  & { getSequenceDetails: Array<(
    { __typename?: 'SequenceDetail' }
    & Pick<SequenceDetail, 'x_mm' | 'y_mm' | 'sequencenumber'>
  )> }
);

export type TaskFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'id' | 'name' | 'guiIndex'>
);

export type ComponentInTaskTreeFragment = (
  { __typename?: 'Component' }
  & Pick<Component, 'id' | 'name' | 'guiIndex' | 'object' | 'tags'>
  & { subcontractor?: Maybe<(
    { __typename?: 'Subcontractor' }
    & Pick<Subcontractor, 'name'>
  )> }
);

export type GetTaskForTreeNodeQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  taskId: Scalars['UUID'];
}>;


export type GetTaskForTreeNodeQuery = (
  { __typename?: 'Query' }
  & { task: (
    { __typename?: 'Task' }
    & TaskFragment
  ) }
);

export type GetComponentForTreeNodeQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  componentId: Scalars['UUID'];
}>;


export type GetComponentForTreeNodeQuery = (
  { __typename?: 'Query' }
  & { component: (
    { __typename?: 'Component' }
    & ComponentInTaskTreeFragment
  ) }
);

export type GetTasksByParentPlanQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  planId: Scalars['UUID'];
}>;


export type GetTasksByParentPlanQuery = (
  { __typename?: 'Query' }
  & { tasksByParentPlan: Array<(
    { __typename?: 'Task' }
    & { parentTask?: Maybe<(
      { __typename?: 'Task' }
      & Pick<Task, 'id'>
    )>, childComponents: Array<(
      { __typename?: 'Component' }
      & ComponentInTaskTreeFragment
    )> }
    & TaskFragment
  )> }
);

export type GetAllPlansQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
}>;


export type GetAllPlansQuery = (
  { __typename?: 'Query' }
  & { plans: Array<(
    { __typename?: 'Plan' }
    & Pick<Plan, 'name' | 'id'>
  )> }
);

export type CreatePlanMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  name: Scalars['String'];
}>;


export type CreatePlanMutation = (
  { __typename?: 'Mutation' }
  & { createPlan: (
    { __typename?: 'Plan' }
    & Pick<Plan, 'id' | 'name' | 'startDate'>
  ) }
);

export type UpdatePlanMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  planId: Scalars['UUID'];
  name: Scalars['String'];
  startDate?: Maybe<Scalars['Date']>;
}>;


export type UpdatePlanMutation = (
  { __typename?: 'Mutation' }
  & { updatePlan: (
    { __typename?: 'Plan' }
    & Pick<Plan, 'id' | 'name' | 'startDate'>
  ) }
);

export type DeletePlanMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  planId: Scalars['UUID'];
}>;


export type DeletePlanMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePlan'>
);

export type CreateTaskMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  name: Scalars['String'];
  planId: Scalars['UUID'];
  parentTaskId?: Maybe<Scalars['UUID']>;
}>;


export type CreateTaskMutation = (
  { __typename?: 'Mutation' }
  & { createTask: (
    { __typename?: 'Task' }
    & TaskFragment
  ) }
);

export type UpdateTasksMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  tasks: Array<UpdateTaskInputType> | UpdateTaskInputType;
}>;


export type UpdateTasksMutation = (
  { __typename?: 'Mutation' }
  & { updateTasks: Array<(
    { __typename?: 'Task' }
    & TaskFragment
  )> }
);

export type UploadPlansTreeMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  file: Scalars['Upload'];
  planId: Scalars['UUID'];
}>;


export type UploadPlansTreeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadPlansTreeFile'>
);

export type ComponentWithAncestorsFragment = (
  { __typename?: 'Component' }
  & Pick<Component, 'id' | 'name' | 'guiIndex'>
  & { parentTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
    & { ancestors: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'name' | 'guiIndex'>
    )> }
  )> }
);

export type MappingFragment = (
  { __typename?: 'Mapping' }
  & Pick<Mapping, 'id' | 'disabled' | 'weight'>
  & { component: (
    { __typename?: 'Component' }
    & Pick<Component, 'id'>
  ) }
);

export type SpaceWithMappingsFragment = (
  { __typename?: 'Space' }
  & Pick<Space, 'id' | 'name'>
  & { mappings: Array<(
    { __typename?: 'Mapping' }
    & MappingFragment
  )> }
);

export type GetSpaceWithMappingsQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  spaceId: Scalars['UUID'];
  planId: Scalars['UUID'];
}>;


export type GetSpaceWithMappingsQuery = (
  { __typename?: 'Query' }
  & { spacesByIds: Array<(
    { __typename?: 'Space' }
    & { subSpaces: Array<(
      { __typename?: 'Space' }
      & SpaceWithMappingsFragment
    )> }
    & SpaceWithMappingsFragment
  )> }
);

export type GetSpaceByIdQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  spaceId: Scalars['UUID'];
  planId: Scalars['UUID'];
  includeSelf: Scalars['Boolean'];
  includeComponentsUnderSpace?: Maybe<Scalars['Boolean']>;
}>;


export type GetSpaceByIdQuery = (
  { __typename?: 'Query' }
  & { spacesByIds: Array<(
    { __typename?: 'Space' }
    & Pick<Space, 'id' | 'name'>
    & { subSpaces: Array<(
      { __typename?: 'Space' }
      & Pick<Space, 'id' | 'name' | 'guiIndex'>
    )>, componentsUnderSpace?: Maybe<Array<(
      { __typename?: 'Component' }
      & ComponentWithAncestorsFragment
    )>> }
  )> }
);

export type GetComponentsForPlanQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  planId: Scalars['UUID'];
  includeSelf: Scalars['Boolean'];
}>;


export type GetComponentsForPlanQuery = (
  { __typename?: 'Query' }
  & { plan: (
    { __typename?: 'Plan' }
    & { components: Array<(
      { __typename?: 'Component' }
      & ComponentWithAncestorsFragment
    )> }
  ) }
);

export type GetComponentProgressesQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  planId: Scalars['UUID'];
  componentIds: Array<Scalars['UUID']> | Scalars['UUID'];
  spaceIds: Array<Scalars['UUID']> | Scalars['UUID'];
  batchIds: Array<Scalars['UUID']> | Scalars['UUID'];
}>;


export type GetComponentProgressesQuery = (
  { __typename?: 'Query' }
  & { componentsByIds: Array<(
    { __typename?: 'Component' }
    & Pick<Component, 'id'>
    & { aggregatedProgresses: Array<(
      { __typename?: 'AggregatedProgress' }
      & Pick<AggregatedProgress, 'progress' | 'status'>
      & { space: (
        { __typename?: 'Space' }
        & Pick<Space, 'id'>
      ), batch: (
        { __typename?: 'Batch' }
        & Pick<Batch, 'id'>
      ) }
    )> }
  )> }
);

export type GetBatchesQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
}>;


export type GetBatchesQuery = (
  { __typename?: 'Query' }
  & { batches: Array<(
    { __typename?: 'Batch' }
    & Pick<Batch, 'id' | 'timestamp' | 'weekNumber'>
  )> }
);

export type UpdateRawMappingProgressesMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  batchId: Scalars['UUID'];
  componentId: Scalars['UUID'];
  spaceId: Scalars['UUID'];
  progress: Scalars['Int'];
}>;


export type UpdateRawMappingProgressesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateRawMappingProgresses'>
);

export type UpdateEnabledDisabledMappingsMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  mappings: Array<UpdateMappingInputType> | UpdateMappingInputType;
}>;


export type UpdateEnabledDisabledMappingsMutation = (
  { __typename?: 'Mutation' }
  & { updateMappings: Array<(
    { __typename?: 'Mapping' }
    & Pick<Mapping, 'id' | 'disabled'>
    & { component: (
      { __typename?: 'Component' }
      & Pick<Component, 'id'>
    ), space: (
      { __typename?: 'Space' }
      & Pick<Space, 'id'>
    ) }
  )> }
);

export type CreateMappingMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  componentId: Scalars['UUID'];
  spaceId: Scalars['UUID'];
}>;


export type CreateMappingMutation = (
  { __typename?: 'Mutation' }
  & { createMappings: Array<(
    { __typename?: 'Mapping' }
    & Pick<Mapping, 'id' | 'disabled'>
    & { space: (
      { __typename?: 'Space' }
      & Pick<Space, 'id' | 'name'>
    ), component: (
      { __typename?: 'Component' }
      & Pick<Component, 'id' | 'name'>
    ) }
  )> }
);

export type DeleteMappingMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  mappingId: Scalars['UUID'];
}>;


export type DeleteMappingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteMappings'>
);

export type ReleaseProjectMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
}>;


export type ReleaseProjectMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'releaseDataToExternal'>
);

export type GetAvailableProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAvailableProjectsQuery = (
  { __typename?: 'Query' }
  & { projects: Array<(
    { __typename?: 'Project' }
    & Pick<Project, 'customer' | 'project' | 'scopes'>
  )> }
);

export type GetEarliestTimestampOnWorkQueueQueryVariables = Exact<{
  tenant: TenantInputType;
}>;


export type GetEarliestTimestampOnWorkQueueQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'earliestTimestampOnWorkQueue'>
);

export type ProjectFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'customer' | 'project'>
  & { config: (
    { __typename?: 'ProjectConfig' }
    & Pick<ProjectConfig, 'displayName' | 'defaultLanguage' | 'startDate'>
  ) }
);

export type GetAllProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllProjectsQuery = (
  { __typename?: 'Query' }
  & { projects: Array<(
    { __typename?: 'Project' }
    & ProjectFragment
  )> }
);

export type UpdateProjectStartDateMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  startDate: Scalars['Date'];
}>;


export type UpdateProjectStartDateMutation = (
  { __typename?: 'Mutation' }
  & { updateProjectStartDate: (
    { __typename?: 'Project' }
    & ProjectFragment
  ) }
);

export type UpdateBatchMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  batchId: Scalars['UUID'];
  timestamp: Scalars['Date'];
  weekNumber: Scalars['Int'];
}>;


export type UpdateBatchMutation = (
  { __typename?: 'Mutation' }
  & { updateBatches: Array<(
    { __typename?: 'Batch' }
    & Pick<Batch, 'id' | 'weekNumber' | 'timestamp'>
  )> }
);

export type CreateNewProjectMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  defaultLanguage: Scalars['String'];
  displayName: Scalars['String'];
}>;


export type CreateNewProjectMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createProject'>
);

export type GetAncestorsForSceneQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  sceneId: Scalars['UUID'];
  includeSelfSpace?: Maybe<Scalars['Boolean']>;
}>;


export type GetAncestorsForSceneQuery = (
  { __typename?: 'Query' }
  & { scene?: Maybe<(
    { __typename?: 'Scene' }
    & Pick<Scene, 'id' | 'name'>
    & { space: (
      { __typename?: 'Space' }
      & Pick<Space, 'id' | 'name'>
      & { ancestors: Array<(
        { __typename?: 'Space' }
        & Pick<Space, 'id' | 'name'>
      )> }
    ) }
  )> }
);

export type GetSiblingSpacesQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  spaceId: Scalars['UUID'];
}>;


export type GetSiblingSpacesQuery = (
  { __typename?: 'Query' }
  & { space: (
    { __typename?: 'Space' }
    & Pick<Space, 'id'>
    & { parentSpace?: Maybe<(
      { __typename?: 'Space' }
      & Pick<Space, 'id'>
      & { subSpaces: Array<(
        { __typename?: 'Space' }
        & Pick<Space, 'id' | 'name' | 'guiIndex'>
      )> }
    )> }
  ) }
);

export type GetScenesQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  spaceId: Scalars['UUID'];
}>;


export type GetScenesQuery = (
  { __typename?: 'Query' }
  & { space: (
    { __typename?: 'Space' }
    & Pick<Space, 'id'>
    & { scenes: Array<(
      { __typename?: 'Scene' }
      & Pick<Scene, 'id' | 'name' | 'guiIndex'>
    )> }
  ) }
);

export type GetSubSpacesQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  spaceId: Scalars['UUID'];
}>;


export type GetSubSpacesQuery = (
  { __typename?: 'Query' }
  & { space: (
    { __typename?: 'Space' }
    & Pick<Space, 'id'>
    & { subSpaces: Array<(
      { __typename?: 'Space' }
      & Pick<Space, 'id' | 'name'>
    )> }
  ) }
);

export type GenerateSceneSpacesMappingsQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
}>;


export type GenerateSceneSpacesMappingsQuery = (
  { __typename?: 'Query' }
  & { generateDumpSheetData: Array<(
    { __typename?: 'DumpSheetSceneData' }
    & Pick<DumpSheetSceneData, 'sceneShell360Id' | 'sceneName' | 'ancestorIds' | 'ancestorNames' | 'sceneLocation' | 'floorName' | 'scanLevel' | 'sequenceNumber' | 'sceneAliasShell360Id'>
  )> }
);

export type BatchesQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
}>;


export type BatchesQuery = (
  { __typename?: 'Query' }
  & { batches: Array<(
    { __typename?: 'Batch' }
    & Pick<Batch, 'id' | 'weekNumber' | 'timestamp'>
  )> }
);

export type GetComponentTypesQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
}>;


export type GetComponentTypesQuery = (
  { __typename?: 'Query' }
  & { componentTypes: Array<(
    { __typename?: 'ComponentType' }
    & Pick<ComponentType, 'id' | 'name'>
  )> }
);

export type ComponentTypeFragment = (
  { __typename?: 'ComponentType' }
  & Pick<ComponentType, 'id' | 'name' | 'description'>
);

export type CreateComponentTypeMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  name: Scalars['String'];
  description: Scalars['String'];
}>;


export type CreateComponentTypeMutation = (
  { __typename?: 'Mutation' }
  & { createComponentType: (
    { __typename?: 'ComponentType' }
    & ComponentTypeFragment
  ) }
);

export type PlansQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
}>;


export type PlansQuery = (
  { __typename?: 'Query' }
  & { plans: Array<(
    { __typename?: 'Plan' }
    & Pick<Plan, 'id' | 'name' | 'startDate'>
  )> }
);

export type GetAllSpaceCategoriesQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
}>;


export type GetAllSpaceCategoriesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'spaceCategories'>
);

export type GetFullNameForSpaceQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  spaceId: Scalars['UUID'];
}>;


export type GetFullNameForSpaceQuery = (
  { __typename?: 'Query' }
  & { spacesByIds: Array<(
    { __typename?: 'Space' }
    & { ancestors: Array<(
      { __typename?: 'Space' }
      & Pick<Space, 'id' | 'name'>
    )> }
  )> }
);

export type GetAllSpaceTagsQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
}>;


export type GetAllSpaceTagsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'spaceTags'>
);

export type GetAllSpaceTypesQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
}>;


export type GetAllSpaceTypesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'spaceTypes'>
);

export type GetSubcontractorsQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
}>;


export type GetSubcontractorsQuery = (
  { __typename?: 'Query' }
  & { subcontractors: Array<(
    { __typename?: 'Subcontractor' }
    & Pick<Subcontractor, 'id' | 'name'>
  )> }
);

export type SubcontractorFragment = (
  { __typename?: 'Subcontractor' }
  & Pick<Subcontractor, 'id' | 'name'>
);

export type CreateSubcontractorsMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  subcontractors: Array<Scalars['String']> | Scalars['String'];
}>;


export type CreateSubcontractorsMutation = (
  { __typename?: 'Mutation' }
  & { createSubcontractors: Array<(
    { __typename?: 'Subcontractor' }
    & SubcontractorFragment
  )> }
);

export type SequenceMapsFloorplanFragmentFragment = (
  { __typename?: 'Floorplan' }
  & Pick<Floorplan, 'id' | 'signedUrl' | 'originX' | 'originY' | 'width' | 'height' | 'mmScaleFactor'>
);

export type SequenceMapsSceneFragmentFragment = (
  { __typename?: 'Scene' }
  & Pick<Scene, 'id' | 'mmX' | 'mmY' | 'mmZOffset' | 'subjects'>
);

export type GetAllFloorplansScanSequencesAndScenesQueryVariables = Exact<{
  tenant: TenantInputType;
}>;


export type GetAllFloorplansScanSequencesAndScenesQuery = (
  { __typename?: 'Query' }
  & { scanSequencesByFilter: Array<(
    { __typename?: 'ScanSequence' }
    & Pick<ScanSequence, 'sequencePrefix' | 'scanLevel'>
    & { floorplan: (
      { __typename?: 'Floorplan' }
      & { space: (
        { __typename?: 'Space' }
        & Pick<Space, 'name' | 'category'>
        & { scenes: Array<(
          { __typename?: 'Scene' }
          & SequenceMapsSceneFragmentFragment
        )>, ancestors: Array<(
          { __typename?: 'Space' }
          & Pick<Space, 'name'>
        )>, descendantSpaces: Array<(
          { __typename?: 'Space' }
          & Pick<Space, 'category'>
          & { scenes: Array<(
            { __typename?: 'Scene' }
            & SequenceMapsSceneFragmentFragment
          )> }
        )> }
      ) }
      & SequenceMapsFloorplanFragmentFragment
    ), scenes: Array<(
      { __typename?: 'Scene' }
      & Pick<Scene, 'id'>
    )> }
  )> }
);

export type SequenceFragment = (
  { __typename?: 'ScanSequence' }
  & Pick<ScanSequence, 'id' | 'sequencePrefix' | 'scanLevel'>
  & { floorplan: (
    { __typename?: 'Floorplan' }
    & Pick<Floorplan, 'id'>
  ), scenes: Array<(
    { __typename?: 'Scene' }
    & Pick<Scene, 'id'>
  )> }
);

export type ScanSequencesByFilterQueryVariables = Exact<{
  tenant: TenantInputType;
  floorplanId: Scalars['UUID'];
}>;


export type ScanSequencesByFilterQuery = (
  { __typename?: 'Query' }
  & { scanSequencesByFilter: Array<(
    { __typename?: 'ScanSequence' }
    & SequenceFragment
  )> }
);

export type CreateScanSequenceMutationVariables = Exact<{
  tenant: TenantInputType;
  floorplanId: Scalars['UUID'];
  sequencePrefix: ScanSequencePrefixType;
  scanLevel: ScanSequenceLevelType;
  sceneIds: Array<Scalars['UUID']> | Scalars['UUID'];
}>;


export type CreateScanSequenceMutation = (
  { __typename?: 'Mutation' }
  & { createScanSequence: (
    { __typename?: 'ScanSequence' }
    & SequenceFragment
  ) }
);

export type UpdateScanSequenceMutationVariables = Exact<{
  tenant: TenantInputType;
  id: Scalars['UUID'];
  floorplanId: Scalars['UUID'];
  sequencePrefix: ScanSequencePrefixType;
  scanLevel: ScanSequenceLevelType;
  sceneIds: Array<Scalars['UUID']> | Scalars['UUID'];
}>;


export type UpdateScanSequenceMutation = (
  { __typename?: 'Mutation' }
  & { updateScanSequence: (
    { __typename?: 'ScanSequence' }
    & SequenceFragment
  ) }
);

export type CreateJiraIssueMutationVariables = Exact<{
  tenant: TenantInputType;
  issue: CreateJiraIssueInputType;
}>;


export type CreateJiraIssueMutation = (
  { __typename?: 'Mutation' }
  & { createJiraIssue: (
    { __typename?: 'JiraIssue' }
    & Pick<JiraIssue, 'id' | 'uniqueShotId' | 'type' | 'message'>
  ) }
);

export type DeleteJiraIssueMutationVariables = Exact<{
  tenant: TenantInputType;
  issueId: Scalars['String'];
}>;


export type DeleteJiraIssueMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteJiraIssue'>
);

export type GetJiraIssuesForShotQueryVariables = Exact<{
  tenant: TenantInputType;
  uniqueShotId: Scalars['String'];
}>;


export type GetJiraIssuesForShotQuery = (
  { __typename?: 'Query' }
  & { jiraIssuesForShot: Array<(
    { __typename?: 'JiraIssue' }
    & Pick<JiraIssue, 'id' | 'type' | 'uniqueShotId' | 'message'>
  )> }
);

export type SpaceFragment = (
  { __typename?: 'Space' }
  & MakeOptional<Pick<Space, 'id' | 'name' | 'guiIndex' | 'category' | 'type' | 'tags'>, 'category' | 'type' | 'tags'>
);

export type MappingInSpacesTreeFragment = (
  { __typename?: 'Mapping' }
  & Pick<Mapping, 'id' | 'disabled'>
  & { component: (
    { __typename?: 'Component' }
    & Pick<Component, 'id' | 'name'>
  ) }
);

export type SceneInSpacesTreeFragment = (
  { __typename?: 'Scene' }
  & Pick<Scene, 'id' | 'name' | 'guiIndex'>
);

export type SpaceWithScenesAndParentFragment = (
  { __typename?: 'Space' }
  & { scenes: Array<(
    { __typename?: 'Scene' }
    & SceneInSpacesTreeFragment
  )>, parentSpace?: Maybe<(
    { __typename?: 'Space' }
    & Pick<Space, 'id' | 'name'>
  )> }
  & SpaceFragment
);

export type GetSpacesTreeWithDescendantSpacesQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  spaceFilters: SpaceFilters;
  includeMetadata?: Maybe<Scalars['Boolean']>;
}>;


export type GetSpacesTreeWithDescendantSpacesQuery = (
  { __typename?: 'Query' }
  & { spacesByFilter: Array<(
    { __typename?: 'Space' }
    & { descendantSpaces: Array<(
      { __typename?: 'Space' }
      & SpaceWithScenesAndParentFragment
    )> }
    & SpaceWithScenesAndParentFragment
  )> }
);

export type SearchSpacesQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  searchTerms: Array<Scalars['String']> | Scalars['String'];
  categories: Array<Scalars['String']> | Scalars['String'];
  types: Array<Scalars['String']> | Scalars['String'];
  tags: Array<Scalars['String']> | Scalars['String'];
  includeMetadata?: Maybe<Scalars['Boolean']>;
}>;


export type SearchSpacesQuery = (
  { __typename?: 'Query' }
  & { searchSpaces: Array<(
    { __typename?: 'Space' }
    & { ancestors: Array<(
      { __typename?: 'Space' }
      & Pick<Space, 'id' | 'name'>
    )> }
    & SpaceFragment
  )> }
);

export type GetSpacesTreeQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  includeMetadata?: Maybe<Scalars['Boolean']>;
  spaceFilters?: Maybe<SpaceFilters>;
}>;


export type GetSpacesTreeQuery = (
  { __typename?: 'Query' }
  & { spacesByFilter: Array<(
    { __typename?: 'Space' }
    & SpaceFragment
  )> }
);

export type GetChildSpacesQueryVariables = Exact<{
  parentId: Scalars['UUID'];
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  includeMetadata?: Maybe<Scalars['Boolean']>;
  includeScenes?: Maybe<Scalars['Boolean']>;
  includeMappings?: Maybe<Scalars['Boolean']>;
  includeShots?: Maybe<Scalars['Boolean']>;
}>;


export type GetChildSpacesQuery = (
  { __typename?: 'Query' }
  & { spacesByFilter: Array<(
    { __typename?: 'Space' }
    & SpaceFragment
  )>, space: (
    { __typename?: 'Space' }
    & Pick<Space, 'id' | 'name' | 'guiIndex'>
    & { scenes?: Maybe<Array<(
      { __typename?: 'Scene' }
      & Pick<Scene, 'id' | 'name' | 'guiIndex'>
      & { shots?: Maybe<Array<(
        { __typename?: 'Shot' }
        & Pick<Shot, 'id'>
        & { batch: (
          { __typename?: 'Batch' }
          & Pick<Batch, 'id' | 'weekNumber' | 'timestamp'>
        ) }
      )>> }
    )>>, mappings?: Maybe<Array<(
      { __typename?: 'Mapping' }
      & MappingInSpacesTreeFragment
    )>> }
  ) }
);

export type GetShotsOnSceneQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  sceneId: Scalars['UUID'];
}>;


export type GetShotsOnSceneQuery = (
  { __typename?: 'Query' }
  & { scene?: Maybe<(
    { __typename?: 'Scene' }
    & Pick<Scene, 'id' | 'name'>
    & { shots: Array<(
      { __typename?: 'Shot' }
      & Pick<Shot, 'id'>
      & { batch: (
        { __typename?: 'Batch' }
        & Pick<Batch, 'id' | 'weekNumber' | 'timestamp'>
      ) }
    )> }
  )> }
);

export type GetMappingsOnSpaceQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  spaceId: Scalars['UUID'];
}>;


export type GetMappingsOnSpaceQuery = (
  { __typename?: 'Query' }
  & { space: (
    { __typename?: 'Space' }
    & Pick<Space, 'id'>
    & { mappings: Array<(
      { __typename?: 'Mapping' }
      & Pick<Mapping, 'id' | 'disabled'>
      & { component: (
        { __typename?: 'Component' }
        & Pick<Component, 'id' | 'name'>
      ) }
    )> }
  ) }
);

export type UpdateSpacesMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  spaces: Array<UpdateSpaceInputType> | UpdateSpaceInputType;
}>;


export type UpdateSpacesMutation = (
  { __typename?: 'Mutation' }
  & { updateSpaces: Array<(
    { __typename?: 'Space' }
    & Pick<Space, 'id' | 'name' | 'type' | 'category' | 'tags'>
    & { parentSpace?: Maybe<(
      { __typename?: 'Space' }
      & Pick<Space, 'id'>
    )> }
  )> }
);

export type SetSpaceParentMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  spaceId: Scalars['UUID'];
  newParentId?: Maybe<Scalars['UUID']>;
}>;


export type SetSpaceParentMutation = (
  { __typename?: 'Mutation' }
  & { patchSpaces: Array<(
    { __typename?: 'Space' }
    & Pick<Space, 'id' | 'name' | 'type' | 'category' | 'tags'>
    & { parentSpace?: Maybe<(
      { __typename?: 'Space' }
      & Pick<Space, 'id'>
    )> }
  )> }
);

export type SetSceneParentMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  sceneId: Scalars['UUID'];
  newSpaceId: Scalars['UUID'];
}>;


export type SetSceneParentMutation = (
  { __typename?: 'Mutation' }
  & { patchScenes: Array<(
    { __typename?: 'Scene' }
    & Pick<Scene, 'id' | 'name'>
    & { space: (
      { __typename?: 'Space' }
      & Pick<Space, 'id'>
    ) }
  )> }
);

export type GetSceneSpatialConfigQueryVariables = Exact<{
  tenant: TenantInputType;
  sceneId: Scalars['UUID'];
}>;


export type GetSceneSpatialConfigQuery = (
  { __typename?: 'Query' }
  & { scenesByIds: Array<(
    { __typename?: 'Scene' }
    & Pick<Scene, 'id' | 'name' | 'path'>
  )> }
);

export type BaseSceneFragmentFragment = (
  { __typename?: 'Scene' }
  & Pick<Scene, 'id' | 'name' | 'mmX' | 'mmY' | 'mmZOffset' | 'guiIndex'>
);

export type SceneWithSpacesFragmentFragment = (
  { __typename?: 'Scene' }
  & { space: (
    { __typename?: 'Space' }
    & Pick<Space, 'id'>
  ) }
  & BaseSceneFragmentFragment
);

export type SceneWithAliasesAndSubjectsFragmentFragment = (
  { __typename?: 'Scene' }
  & Pick<Scene, 'subjects'>
  & { aliasScene?: Maybe<(
    { __typename?: 'Scene' }
    & Pick<Scene, 'id'>
  )> }
  & BaseSceneFragmentFragment
);

export type SceneWithSpacesAliasesAndSubjectsFragmentFragment = (
  { __typename?: 'Scene' }
  & { space: (
    { __typename?: 'Space' }
    & Pick<Space, 'id'>
  ) }
  & SceneWithAliasesAndSubjectsFragmentFragment
);

export type PatchScenesSpatialConfigMutationVariables = Exact<{
  tenant: TenantInputType;
  sceneIds: Array<Scalars['UUID']> | Scalars['UUID'];
  update: PatchSceneInputType;
}>;


export type PatchScenesSpatialConfigMutation = (
  { __typename?: 'Mutation' }
  & { patchScenes: Array<(
    { __typename?: 'Scene' }
    & SceneWithSpacesAliasesAndSubjectsFragmentFragment
  )> }
);

export type GetScenesUnderSpaceQueryVariables = Exact<{
  tenant: TenantInputType;
  spaceId: Scalars['UUID'];
}>;


export type GetScenesUnderSpaceQuery = (
  { __typename?: 'Query' }
  & { spacesByFilter: Array<(
    { __typename?: 'Space' }
    & Pick<Space, 'id' | 'name' | 'category'>
    & { scenes: Array<(
      { __typename?: 'Scene' }
      & SceneWithAliasesAndSubjectsFragmentFragment
    )>, descendantSpaces: Array<(
      { __typename?: 'Space' }
      & Pick<Space, 'id' | 'name' | 'category'>
      & { parentSpace?: Maybe<(
        { __typename?: 'Space' }
        & Pick<Space, 'id'>
      )>, scenes: Array<(
        { __typename?: 'Scene' }
        & SceneWithAliasesAndSubjectsFragmentFragment
      )> }
    )> }
  )> }
);

export type DeleteScenesSpatialConfigMutationVariables = Exact<{
  tenant: TenantInputType;
  sceneIds: Array<Scalars['UUID']> | Scalars['UUID'];
}>;


export type DeleteScenesSpatialConfigMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteScenes'>
);

export type CreateSceneUnderSpaceMutationVariables = Exact<{
  tenant: TenantInputType;
  scene: CreateSceneInputType;
}>;


export type CreateSceneUnderSpaceMutation = (
  { __typename?: 'Mutation' }
  & { createScenes: Array<(
    { __typename?: 'Scene' }
    & SceneWithSpacesAliasesAndSubjectsFragmentFragment
  )> }
);

export type UpdateScenesSpatialConfigMutationVariables = Exact<{
  tenant: TenantInputType;
  scenes: Array<UpdateSceneInputType> | UpdateSceneInputType;
}>;


export type UpdateScenesSpatialConfigMutation = (
  { __typename?: 'Mutation' }
  & { updateScenes: Array<(
    { __typename?: 'Scene' }
    & SceneWithSpacesFragmentFragment
  )> }
);

export type GetSpaceOptionsByIdQueryVariables = Exact<{
  tenant: TenantInputType;
  spaceId: Scalars['UUID'];
}>;


export type GetSpaceOptionsByIdQuery = (
  { __typename?: 'Query' }
  & { spacesByFilter: Array<(
    { __typename?: 'Space' }
    & Pick<Space, 'id' | 'name' | 'type' | 'category' | 'tags'>
  )> }
);

export type SpaceWithParentFragmentFragment = (
  { __typename?: 'Space' }
  & Pick<Space, 'id' | 'name' | 'guiIndex' | 'category' | 'type' | 'tags'>
  & { parentSpace?: Maybe<(
    { __typename?: 'Space' }
    & Pick<Space, 'id'>
  )> }
);

export type SpaceWithParentAndAncestorsFragmentFragment = (
  { __typename?: 'Space' }
  & Pick<Space, 'id' | 'name' | 'guiIndex' | 'category' | 'type' | 'tags'>
  & { ancestors: Array<(
    { __typename?: 'Space' }
    & Pick<Space, 'id'>
  )>, parentSpace?: Maybe<(
    { __typename?: 'Space' }
    & Pick<Space, 'id'>
  )> }
);

export type CreateSpacesSpatialConfigMutationVariables = Exact<{
  tenant: TenantInputType;
  spaces: Array<CreateSpaceInputType> | CreateSpaceInputType;
}>;


export type CreateSpacesSpatialConfigMutation = (
  { __typename?: 'Mutation' }
  & { createSpaces: Array<(
    { __typename?: 'Space' }
    & SpaceWithParentAndAncestorsFragmentFragment
  )> }
);

export type PatchSpacesSpatialConfigMutationVariables = Exact<{
  tenant: TenantInputType;
  ids: Array<Scalars['UUID']> | Scalars['UUID'];
  update: PatchSpaceInputType;
}>;


export type PatchSpacesSpatialConfigMutation = (
  { __typename?: 'Mutation' }
  & { patchSpaces: Array<(
    { __typename?: 'Space' }
    & SpaceWithParentFragmentFragment
  )> }
);

export type DeleteSpacesSpatialConfigMutationVariables = Exact<{
  tenant: TenantInputType;
  spaceIds: Array<Scalars['UUID']> | Scalars['UUID'];
}>;


export type DeleteSpacesSpatialConfigMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSpaces'>
);

export type CopySpatialDataMutationVariables = Exact<{
  tenant: TenantInputType;
  spaceId: Scalars['UUID'];
  newParentSpaceId: Scalars['UUID'];
  newSpaceName?: Maybe<Scalars['String']>;
  newFloorHeight?: Maybe<Scalars['Float']>;
}>;


export type CopySpatialDataMutation = (
  { __typename?: 'Mutation' }
  & { copySpatialData: Array<(
    { __typename?: 'Space' }
    & Pick<Space, 'id'>
  )> }
);

export type QaSpotlightFragment = (
  { __typename?: 'Spotlight' }
  & Pick<Spotlight, 'id' | 'type'>
  & { mapping?: Maybe<(
    { __typename?: 'Mapping' }
    & Pick<Mapping, 'id'>
    & { component: (
      { __typename?: 'Component' }
      & Pick<Component, 'id' | 'name'>
      & { subcontractor?: Maybe<(
        { __typename?: 'Subcontractor' }
        & Pick<Subcontractor, 'id' | 'name'>
      )> }
    ) }
  )>, scene?: Maybe<(
    { __typename?: 'Scene' }
    & Pick<Scene, 'id'>
  )>, location: (
    { __typename?: 'AnnotationLocation' }
    & Pick<AnnotationLocation, 'yaw' | 'pitch'>
  ), createdInBatch: (
    { __typename?: 'Batch' }
    & Pick<Batch, 'id'>
  ), resolvedInBatch?: Maybe<(
    { __typename?: 'Batch' }
    & Pick<Batch, 'id'>
  )> }
);

export type QaSpaceFragment = (
  { __typename?: 'Space' }
  & Pick<Space, 'id' | 'name'>
  & { scenes: Array<(
    { __typename?: 'Scene' }
    & Pick<Scene, 'id'>
    & { spotlights: Array<(
      { __typename?: 'Spotlight' }
      & QaSpotlightFragment
    )> }
  )> }
);

export type GetSpotlightsForRootSpaceQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  batchId: Scalars['UUID'];
  spaceId: Scalars['UUID'];
}>;


export type GetSpotlightsForRootSpaceQuery = (
  { __typename?: 'Query' }
  & { space: (
    { __typename?: 'Space' }
    & { descendantSpaces: Array<(
      { __typename?: 'Space' }
      & QaSpaceFragment
    )> }
    & QaSpaceFragment
  ) }
);

export type GetTaskQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  taskId: Scalars['UUID'];
}>;


export type GetTaskQuery = (
  { __typename?: 'Query' }
  & { task: (
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'name' | 'guiIndex'>
    & { plan: (
      { __typename?: 'Plan' }
      & Pick<Plan, 'id' | 'name'>
    ), parentTask?: Maybe<(
      { __typename?: 'Task' }
      & Pick<Task, 'id'>
    )> }
  ) }
);

export type DeleteTaskMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  taskId: Scalars['UUID'];
}>;


export type DeleteTaskMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTasks'>
);

export type UpdateTaskMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  task: UpdateTaskInputType;
}>;


export type UpdateTaskMutation = (
  { __typename?: 'Mutation' }
  & { updateTasks: Array<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'name' | 'guiIndex'>
  )> }
);

export type CanEditJiraIssuesQueryVariables = Exact<{
  tenant: TenantInputType;
}>;


export type CanEditJiraIssuesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'canEditJiraIssues'>
);

export type GetDefaultSceneQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  spaceId: Scalars['UUID'];
  batchId?: Maybe<Scalars['UUID']>;
}>;


export type GetDefaultSceneQuery = (
  { __typename?: 'Query' }
  & { space: (
    { __typename?: 'Space' }
    & Pick<Space, 'id'>
    & { defaultScene?: Maybe<(
      { __typename?: 'Scene' }
      & Pick<Scene, 'id'>
      & { space: (
        { __typename?: 'Space' }
        & Pick<Space, 'id'>
      ) }
    )> }
  ) }
);

export type GetNextSceneQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  sceneId: Scalars['UUID'];
  rootSpaceId: Scalars['UUID'];
  batchId?: Maybe<Scalars['UUID']>;
}>;


export type GetNextSceneQuery = (
  { __typename?: 'Query' }
  & { scene?: Maybe<(
    { __typename?: 'Scene' }
    & Pick<Scene, 'id'>
    & { nextScene?: Maybe<(
      { __typename?: 'Scene' }
      & Pick<Scene, 'id'>
      & { space: (
        { __typename?: 'Space' }
        & Pick<Space, 'id'>
      ) }
    )> }
  )> }
);

export type GetPreviousSceneQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  sceneId: Scalars['UUID'];
  rootSpaceId: Scalars['UUID'];
  batchId?: Maybe<Scalars['UUID']>;
}>;


export type GetPreviousSceneQuery = (
  { __typename?: 'Query' }
  & { scene?: Maybe<(
    { __typename?: 'Scene' }
    & Pick<Scene, 'id'>
    & { previousScene?: Maybe<(
      { __typename?: 'Scene' }
      & Pick<Scene, 'id'>
      & { space: (
        { __typename?: 'Space' }
        & Pick<Space, 'id'>
      ) }
    )> }
  )> }
);

export type GetNextIdenticalSceneQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  sceneId: Scalars['UUID'];
  rootSpaceId: Scalars['UUID'];
  batchId?: Maybe<Scalars['UUID']>;
}>;


export type GetNextIdenticalSceneQuery = (
  { __typename?: 'Query' }
  & { scene?: Maybe<(
    { __typename?: 'Scene' }
    & Pick<Scene, 'id'>
    & { nextIdenticalScene?: Maybe<(
      { __typename?: 'Scene' }
      & Pick<Scene, 'id'>
      & { space: (
        { __typename?: 'Space' }
        & Pick<Space, 'id'>
      ) }
    )> }
  )> }
);

export type GetPreviousIdenticalSceneQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  sceneId: Scalars['UUID'];
  rootSpaceId: Scalars['UUID'];
  batchId?: Maybe<Scalars['UUID']>;
}>;


export type GetPreviousIdenticalSceneQuery = (
  { __typename?: 'Query' }
  & { scene?: Maybe<(
    { __typename?: 'Scene' }
    & Pick<Scene, 'id'>
    & { previousIdenticalScene?: Maybe<(
      { __typename?: 'Scene' }
      & Pick<Scene, 'id'>
      & { space: (
        { __typename?: 'Space' }
        & Pick<Space, 'id'>
      ) }
    )> }
  )> }
);

export type FloorplansWithDescendantsQueryVariables = Exact<{
  tenant: TenantInputType;
}>;


export type FloorplansWithDescendantsQuery = (
  { __typename?: 'Query' }
  & { floorplans: Array<(
    { __typename?: 'Floorplan' }
    & Pick<Floorplan, 'id' | 'bimOriginX' | 'bimOriginY' | 'bimOriginZ' | 'angleToTrueNorth' | 'floorHeight'>
    & { space: (
      { __typename?: 'Space' }
      & Pick<Space, 'id'>
      & { descendantSpaces: Array<(
        { __typename?: 'Space' }
        & Pick<Space, 'id'>
      )> }
    ) }
  )> }
);

export type ClearDatesMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  planId: Scalars['UUID'];
}>;


export type ClearDatesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'clearDates'>
);

export type GetPlanQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  planId: Scalars['UUID'];
}>;


export type GetPlanQuery = (
  { __typename?: 'Query' }
  & { plan: (
    { __typename?: 'Plan' }
    & Pick<Plan, 'id' | 'name' | 'startDate'>
  ) }
);

export type UpdatePlanStartDateMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  planId: Scalars['UUID'];
  name: Scalars['String'];
  startDate?: Maybe<Scalars['Date']>;
}>;


export type UpdatePlanStartDateMutation = (
  { __typename?: 'Mutation' }
  & { updatePlan: (
    { __typename?: 'Plan' }
    & Pick<Plan, 'id' | 'name' | 'startDate'>
  ) }
);

export type GetSpaceAncestorsQueryVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  spaceId: Scalars['UUID'];
}>;


export type GetSpaceAncestorsQuery = (
  { __typename?: 'Query' }
  & { space: (
    { __typename?: 'Space' }
    & Pick<Space, 'id' | 'name'>
    & { ancestors: Array<(
      { __typename?: 'Space' }
      & Pick<Space, 'id' | 'name'>
    )> }
  ) }
);

export type ReweightMutationVariables = Exact<{
  customer: Scalars['String'];
  project: Scalars['String'];
  scope: Scope;
  planId: Scalars['UUID'];
}>;


export type ReweightMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'calculateWeights'>
);

export type GetSpaceAncestorNamesByIdQueryVariables = Exact<{
  tenant: TenantInputType;
  spaceIds?: Maybe<Array<Scalars['UUID']> | Scalars['UUID']>;
}>;


export type GetSpaceAncestorNamesByIdQuery = (
  { __typename?: 'Query' }
  & { spacesByFilter: Array<(
    { __typename?: 'Space' }
    & Pick<Space, 'id' | 'name'>
    & { ancestors: Array<(
      { __typename?: 'Space' }
      & Pick<Space, 'name'>
    )> }
  )> }
);

export const ComponentFragmentDoc = gql`
    fragment Component on Component {
  id
  name
  componentType {
    id
    name
  }
  subcontractor {
    id
    name
  }
  weight(taskId: $taskId)
  guiIndex(taskId: $taskId)
  object
  tags
}
    `;
export const ComponentWithoutTaskFragmentDoc = gql`
    fragment ComponentWithoutTask on Component {
  id
  name
  componentType {
    id
    name
  }
  subcontractor {
    id
    name
  }
  object
  tags
}
    `;
export const MappingFragmentFragmentDoc = gql`
    fragment MappingFragment on Mapping {
  id
  component {
    id
    name
    subcontractor {
      id
      name
    }
  }
}
    `;
export const SpaceWithMappingDetailsFragmentDoc = gql`
    fragment SpaceWithMappingDetails on Space {
  id
  name
  guiIndex
  ancestors {
    name
    guiIndex
  }
  mappings {
    ...MappingFragment
    disabled
  }
}
    ${MappingFragmentFragmentDoc}`;
export const SpotlightFragmentFragmentDoc = gql`
    fragment SpotlightFragment on Spotlight {
  id
  type
  location(batchId: $batchId) {
    yaw
    pitch
  }
  mapping {
    ...MappingFragment
    space {
      id
      name
    }
  }
  createdInBatch {
    id
  }
  resolvedInBatch {
    id
  }
}
    ${MappingFragmentFragmentDoc}`;
export const ArrowFragmentFragmentDoc = gql`
    fragment ArrowFragment on Arrow {
  id
  targetScene {
    id
    name
    space {
      id
      name
    }
  }
  missing
  location {
    yaw
    pitch
  }
}
    `;
export const ShotFragmentFragmentDoc = gql`
    fragment ShotFragment on Shot {
  id
  imageConfig {
    imageId
    uniqueImageId
    initialHfov
    initialYaw
    initialPitch
    basePath
    pathTemplate
    cubeResolution
    tileResolution
    extension
    maxLevel
    authQueryStrings {
      tileSuffix
      queryString
    }
  }
  scene {
    id
    shell360Id
    mmX
    mmY
    mmZOffset
  }
  spotlights {
    ...SpotlightFragment
  }
  arrows {
    ...ArrowFragment
  }
}
    ${SpotlightFragmentFragmentDoc}
${ArrowFragmentFragmentDoc}`;
export const SpaceWithMappingFragmentDoc = gql`
    fragment SpaceWithMapping on Space {
  id
  name
  mappings {
    ...MappingFragment
  }
}
    ${MappingFragmentFragmentDoc}`;
export const TaskFragmentDoc = gql`
    fragment Task on Task {
  id
  name
  guiIndex
}
    `;
export const ComponentInTaskTreeFragmentDoc = gql`
    fragment ComponentInTaskTree on Component {
  id
  name
  guiIndex
  object
  subcontractor {
    name
  }
  tags
}
    `;
export const ComponentWithAncestorsFragmentDoc = gql`
    fragment ComponentWithAncestors on Component {
  id
  name
  guiIndex(planId: $planId)
  parentTask(planId: $planId) {
    id
    ancestors(includeSelf: $includeSelf) {
      id
      name
      guiIndex
    }
  }
}
    `;
export const MappingFragmentDoc = gql`
    fragment Mapping on Mapping {
  id
  disabled
  component {
    id
  }
  weight
}
    `;
export const SpaceWithMappingsFragmentDoc = gql`
    fragment SpaceWithMappings on Space {
  id
  name
  mappings(planId: $planId) {
    ...Mapping
  }
}
    ${MappingFragmentDoc}`;
export const ProjectFragmentDoc = gql`
    fragment Project on Project {
  customer
  project
  config {
    displayName
    defaultLanguage
    startDate
  }
}
    `;
export const ComponentTypeFragmentDoc = gql`
    fragment ComponentType on ComponentType {
  id
  name
  description
}
    `;
export const SubcontractorFragmentDoc = gql`
    fragment Subcontractor on Subcontractor {
  id
  name
}
    `;
export const SequenceMapsFloorplanFragmentFragmentDoc = gql`
    fragment SequenceMapsFloorplanFragment on Floorplan {
  id
  signedUrl
  originX
  originY
  width
  height
  mmScaleFactor
}
    `;
export const SequenceMapsSceneFragmentFragmentDoc = gql`
    fragment SequenceMapsSceneFragment on Scene {
  id
  mmX
  mmY
  mmZOffset
  subjects
}
    `;
export const SequenceFragmentDoc = gql`
    fragment Sequence on ScanSequence {
  id
  floorplan {
    id
  }
  sequencePrefix
  scanLevel
  scenes {
    id
  }
}
    `;
export const MappingInSpacesTreeFragmentDoc = gql`
    fragment MappingInSpacesTree on Mapping {
  id
  disabled
  component {
    id
    name
  }
}
    `;
export const SpaceFragmentDoc = gql`
    fragment Space on Space {
  id
  name
  guiIndex
  category @include(if: $includeMetadata)
  type @include(if: $includeMetadata)
  tags @include(if: $includeMetadata)
}
    `;
export const SceneInSpacesTreeFragmentDoc = gql`
    fragment SceneInSpacesTree on Scene {
  id
  name
  guiIndex
}
    `;
export const SpaceWithScenesAndParentFragmentDoc = gql`
    fragment SpaceWithScenesAndParent on Space {
  ...Space
  scenes {
    ...SceneInSpacesTree
  }
  parentSpace {
    id
    name
  }
}
    ${SpaceFragmentDoc}
${SceneInSpacesTreeFragmentDoc}`;
export const BaseSceneFragmentFragmentDoc = gql`
    fragment BaseSceneFragment on Scene {
  id
  name
  mmX
  mmY
  mmZOffset
  guiIndex
}
    `;
export const SceneWithSpacesFragmentFragmentDoc = gql`
    fragment SceneWithSpacesFragment on Scene {
  ...BaseSceneFragment
  space {
    id
  }
}
    ${BaseSceneFragmentFragmentDoc}`;
export const SceneWithAliasesAndSubjectsFragmentFragmentDoc = gql`
    fragment SceneWithAliasesAndSubjectsFragment on Scene {
  ...BaseSceneFragment
  aliasScene {
    id
  }
  subjects
}
    ${BaseSceneFragmentFragmentDoc}`;
export const SceneWithSpacesAliasesAndSubjectsFragmentFragmentDoc = gql`
    fragment SceneWithSpacesAliasesAndSubjectsFragment on Scene {
  ...SceneWithAliasesAndSubjectsFragment
  space {
    id
  }
}
    ${SceneWithAliasesAndSubjectsFragmentFragmentDoc}`;
export const SpaceWithParentFragmentFragmentDoc = gql`
    fragment SpaceWithParentFragment on Space {
  id
  name
  guiIndex
  category
  type
  tags
  parentSpace {
    id
  }
}
    `;
export const SpaceWithParentAndAncestorsFragmentFragmentDoc = gql`
    fragment SpaceWithParentAndAncestorsFragment on Space {
  id
  name
  guiIndex
  category
  type
  tags
  ancestors {
    id
  }
  parentSpace {
    id
  }
}
    `;
export const QaSpotlightFragmentDoc = gql`
    fragment QASpotlight on Spotlight {
  id
  type
  mapping {
    id
    component {
      id
      name
      subcontractor {
        id
        name
      }
    }
  }
  scene {
    id
  }
  location(batchId: $batchId) {
    yaw
    pitch
  }
  createdInBatch {
    id
  }
  resolvedInBatch {
    id
  }
}
    `;
export const QaSpaceFragmentDoc = gql`
    fragment QASpace on Space {
  id
  name
  scenes {
    id
    spotlights(batchId: $batchId) {
      ...QASpotlight
    }
  }
}
    ${QaSpotlightFragmentDoc}`;
export const UpdateInternalAggservProgressesDocument = gql`
    mutation UpdateInternalAggservProgresses($customer: String!, $project: String!) {
  updateInternalAggServProgresses(
    tenant: {customer: $customer, project: $project, scope: internal}
  )
}
    `;
export type UpdateInternalAggservProgressesMutationFn = Apollo.MutationFunction<UpdateInternalAggservProgressesMutation, UpdateInternalAggservProgressesMutationVariables>;

/**
 * __useUpdateInternalAggservProgressesMutation__
 *
 * To run a mutation, you first call `useUpdateInternalAggservProgressesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInternalAggservProgressesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInternalAggservProgressesMutation, { data, loading, error }] = useUpdateInternalAggservProgressesMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *   },
 * });
 */
export function useUpdateInternalAggservProgressesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInternalAggservProgressesMutation, UpdateInternalAggservProgressesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInternalAggservProgressesMutation, UpdateInternalAggservProgressesMutationVariables>(UpdateInternalAggservProgressesDocument, options);
      }
export type UpdateInternalAggservProgressesMutationHookResult = ReturnType<typeof useUpdateInternalAggservProgressesMutation>;
export type UpdateInternalAggservProgressesMutationResult = Apollo.MutationResult<UpdateInternalAggservProgressesMutation>;
export type UpdateInternalAggservProgressesMutationOptions = Apollo.BaseMutationOptions<UpdateInternalAggservProgressesMutation, UpdateInternalAggservProgressesMutationVariables>;
export const GetAggservSyncDateDocument = gql`
    query GetAggservSyncDate($tenant: TenantInputType!) {
  aggservSyncDate(tenant: $tenant)
}
    `;

/**
 * __useGetAggservSyncDateQuery__
 *
 * To run a query within a React component, call `useGetAggservSyncDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAggservSyncDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAggservSyncDateQuery({
 *   variables: {
 *      tenant: // value for 'tenant'
 *   },
 * });
 */
export function useGetAggservSyncDateQuery(baseOptions: Apollo.QueryHookOptions<GetAggservSyncDateQuery, GetAggservSyncDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAggservSyncDateQuery, GetAggservSyncDateQueryVariables>(GetAggservSyncDateDocument, options);
      }
export function useGetAggservSyncDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAggservSyncDateQuery, GetAggservSyncDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAggservSyncDateQuery, GetAggservSyncDateQueryVariables>(GetAggservSyncDateDocument, options);
        }
export type GetAggservSyncDateQueryHookResult = ReturnType<typeof useGetAggservSyncDateQuery>;
export type GetAggservSyncDateLazyQueryHookResult = ReturnType<typeof useGetAggservSyncDateLazyQuery>;
export type GetAggservSyncDateQueryResult = Apollo.QueryResult<GetAggservSyncDateQuery, GetAggservSyncDateQueryVariables>;
export const UploadBuildingStructureDocument = gql`
    mutation UploadBuildingStructure($customer: String!, $project: String!, $scope: Scope!, $file: Upload!, $parentId: UUID) {
  uploadBuildingStructureFile(
    tenant: {customer: $customer, project: $project, scope: $scope}
    buildingStructureFile: $file
    parentId: $parentId
  )
}
    `;
export type UploadBuildingStructureMutationFn = Apollo.MutationFunction<UploadBuildingStructureMutation, UploadBuildingStructureMutationVariables>;

/**
 * __useUploadBuildingStructureMutation__
 *
 * To run a mutation, you first call `useUploadBuildingStructureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadBuildingStructureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadBuildingStructureMutation, { data, loading, error }] = useUploadBuildingStructureMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      file: // value for 'file'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useUploadBuildingStructureMutation(baseOptions?: Apollo.MutationHookOptions<UploadBuildingStructureMutation, UploadBuildingStructureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadBuildingStructureMutation, UploadBuildingStructureMutationVariables>(UploadBuildingStructureDocument, options);
      }
export type UploadBuildingStructureMutationHookResult = ReturnType<typeof useUploadBuildingStructureMutation>;
export type UploadBuildingStructureMutationResult = Apollo.MutationResult<UploadBuildingStructureMutation>;
export type UploadBuildingStructureMutationOptions = Apollo.BaseMutationOptions<UploadBuildingStructureMutation, UploadBuildingStructureMutationVariables>;
export const GetSpaceNameByIdDocument = gql`
    query GetSpaceNameById($customer: String!, $project: String!, $scope: Scope!, $spaceId: UUID!) {
  spacesByIds(
    tenant: {customer: $customer, project: $project, scope: $scope}
    ids: [$spaceId]
  ) {
    id
    name
  }
}
    `;

/**
 * __useGetSpaceNameByIdQuery__
 *
 * To run a query within a React component, call `useGetSpaceNameByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpaceNameByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpaceNameByIdQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useGetSpaceNameByIdQuery(baseOptions: Apollo.QueryHookOptions<GetSpaceNameByIdQuery, GetSpaceNameByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSpaceNameByIdQuery, GetSpaceNameByIdQueryVariables>(GetSpaceNameByIdDocument, options);
      }
export function useGetSpaceNameByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSpaceNameByIdQuery, GetSpaceNameByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSpaceNameByIdQuery, GetSpaceNameByIdQueryVariables>(GetSpaceNameByIdDocument, options);
        }
export type GetSpaceNameByIdQueryHookResult = ReturnType<typeof useGetSpaceNameByIdQuery>;
export type GetSpaceNameByIdLazyQueryHookResult = ReturnType<typeof useGetSpaceNameByIdLazyQuery>;
export type GetSpaceNameByIdQueryResult = Apollo.QueryResult<GetSpaceNameByIdQuery, GetSpaceNameByIdQueryVariables>;
export const GetAllComponentsWithTagsDocument = gql`
    query GetAllComponentsWithTags($customer: String!, $project: String!, $scope: Scope!) {
  components(tenant: {customer: $customer, project: $project, scope: $scope}) {
    name
    id
    tags
  }
}
    `;

/**
 * __useGetAllComponentsWithTagsQuery__
 *
 * To run a query within a React component, call `useGetAllComponentsWithTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllComponentsWithTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllComponentsWithTagsQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useGetAllComponentsWithTagsQuery(baseOptions: Apollo.QueryHookOptions<GetAllComponentsWithTagsQuery, GetAllComponentsWithTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllComponentsWithTagsQuery, GetAllComponentsWithTagsQueryVariables>(GetAllComponentsWithTagsDocument, options);
      }
export function useGetAllComponentsWithTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllComponentsWithTagsQuery, GetAllComponentsWithTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllComponentsWithTagsQuery, GetAllComponentsWithTagsQueryVariables>(GetAllComponentsWithTagsDocument, options);
        }
export type GetAllComponentsWithTagsQueryHookResult = ReturnType<typeof useGetAllComponentsWithTagsQuery>;
export type GetAllComponentsWithTagsLazyQueryHookResult = ReturnType<typeof useGetAllComponentsWithTagsLazyQuery>;
export type GetAllComponentsWithTagsQueryResult = Apollo.QueryResult<GetAllComponentsWithTagsQuery, GetAllComponentsWithTagsQueryVariables>;
export const GetAllSpacesWithTagsDocument = gql`
    query GetAllSpacesWithTags($customer: String!, $project: String!, $scope: Scope!) {
  spacesByFilter(
    tenant: {customer: $customer, project: $project, scope: $scope}
    spaceFilters: {parentSpaceId: null}
  ) {
    name
    id
    tags
    guiIndexPath(includeSelf: true)
    ancestors {
      name
    }
    descendantSpaces {
      id
      name
      guiIndexPath(includeSelf: true)
      tags
      ancestors {
        name
      }
    }
  }
}
    `;

/**
 * __useGetAllSpacesWithTagsQuery__
 *
 * To run a query within a React component, call `useGetAllSpacesWithTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSpacesWithTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSpacesWithTagsQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useGetAllSpacesWithTagsQuery(baseOptions: Apollo.QueryHookOptions<GetAllSpacesWithTagsQuery, GetAllSpacesWithTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllSpacesWithTagsQuery, GetAllSpacesWithTagsQueryVariables>(GetAllSpacesWithTagsDocument, options);
      }
export function useGetAllSpacesWithTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllSpacesWithTagsQuery, GetAllSpacesWithTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllSpacesWithTagsQuery, GetAllSpacesWithTagsQueryVariables>(GetAllSpacesWithTagsDocument, options);
        }
export type GetAllSpacesWithTagsQueryHookResult = ReturnType<typeof useGetAllSpacesWithTagsQuery>;
export type GetAllSpacesWithTagsLazyQueryHookResult = ReturnType<typeof useGetAllSpacesWithTagsLazyQuery>;
export type GetAllSpacesWithTagsQueryResult = Apollo.QueryResult<GetAllSpacesWithTagsQuery, GetAllSpacesWithTagsQueryVariables>;
export const GetComponentsByTaskDocument = gql`
    query GetComponentsByTask($customer: String!, $project: String!, $scope: Scope!, $taskId: UUID) {
  components(
    tenant: {customer: $customer, project: $project, scope: $scope}
    taskId: $taskId
  ) {
    ...Component
  }
}
    ${ComponentFragmentDoc}`;

/**
 * __useGetComponentsByTaskQuery__
 *
 * To run a query within a React component, call `useGetComponentsByTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComponentsByTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComponentsByTaskQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetComponentsByTaskQuery(baseOptions: Apollo.QueryHookOptions<GetComponentsByTaskQuery, GetComponentsByTaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetComponentsByTaskQuery, GetComponentsByTaskQueryVariables>(GetComponentsByTaskDocument, options);
      }
export function useGetComponentsByTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetComponentsByTaskQuery, GetComponentsByTaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetComponentsByTaskQuery, GetComponentsByTaskQueryVariables>(GetComponentsByTaskDocument, options);
        }
export type GetComponentsByTaskQueryHookResult = ReturnType<typeof useGetComponentsByTaskQuery>;
export type GetComponentsByTaskLazyQueryHookResult = ReturnType<typeof useGetComponentsByTaskLazyQuery>;
export type GetComponentsByTaskQueryResult = Apollo.QueryResult<GetComponentsByTaskQuery, GetComponentsByTaskQueryVariables>;
export const GetComponentsExcludingPlanDocument = gql`
    query GetComponentsExcludingPlan($customer: String!, $project: String!, $scope: Scope!, $planIdToExclude: UUID!) {
  componentsExcludingPlan(
    tenant: {customer: $customer, project: $project, scope: $scope}
    planIdToExclude: $planIdToExclude
  ) {
    ...ComponentWithoutTask
  }
}
    ${ComponentWithoutTaskFragmentDoc}`;

/**
 * __useGetComponentsExcludingPlanQuery__
 *
 * To run a query within a React component, call `useGetComponentsExcludingPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComponentsExcludingPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComponentsExcludingPlanQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      planIdToExclude: // value for 'planIdToExclude'
 *   },
 * });
 */
export function useGetComponentsExcludingPlanQuery(baseOptions: Apollo.QueryHookOptions<GetComponentsExcludingPlanQuery, GetComponentsExcludingPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetComponentsExcludingPlanQuery, GetComponentsExcludingPlanQueryVariables>(GetComponentsExcludingPlanDocument, options);
      }
export function useGetComponentsExcludingPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetComponentsExcludingPlanQuery, GetComponentsExcludingPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetComponentsExcludingPlanQuery, GetComponentsExcludingPlanQueryVariables>(GetComponentsExcludingPlanDocument, options);
        }
export type GetComponentsExcludingPlanQueryHookResult = ReturnType<typeof useGetComponentsExcludingPlanQuery>;
export type GetComponentsExcludingPlanLazyQueryHookResult = ReturnType<typeof useGetComponentsExcludingPlanLazyQuery>;
export type GetComponentsExcludingPlanQueryResult = Apollo.QueryResult<GetComponentsExcludingPlanQuery, GetComponentsExcludingPlanQueryVariables>;
export const GetObjectTypesDocument = gql`
    query GetObjectTypes($customer: String!, $project: String!, $scope: Scope!) {
  objectTypes(tenant: {customer: $customer, project: $project, scope: $scope})
}
    `;

/**
 * __useGetObjectTypesQuery__
 *
 * To run a query within a React component, call `useGetObjectTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetObjectTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetObjectTypesQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useGetObjectTypesQuery(baseOptions: Apollo.QueryHookOptions<GetObjectTypesQuery, GetObjectTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetObjectTypesQuery, GetObjectTypesQueryVariables>(GetObjectTypesDocument, options);
      }
export function useGetObjectTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetObjectTypesQuery, GetObjectTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetObjectTypesQuery, GetObjectTypesQueryVariables>(GetObjectTypesDocument, options);
        }
export type GetObjectTypesQueryHookResult = ReturnType<typeof useGetObjectTypesQuery>;
export type GetObjectTypesLazyQueryHookResult = ReturnType<typeof useGetObjectTypesLazyQuery>;
export type GetObjectTypesQueryResult = Apollo.QueryResult<GetObjectTypesQuery, GetObjectTypesQueryVariables>;
export const GetComponentTagTypesDocument = gql`
    query GetComponentTagTypes($customer: String!, $project: String!, $scope: Scope!) {
  componentTagTypes(
    tenant: {customer: $customer, project: $project, scope: $scope}
  )
}
    `;

/**
 * __useGetComponentTagTypesQuery__
 *
 * To run a query within a React component, call `useGetComponentTagTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComponentTagTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComponentTagTypesQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useGetComponentTagTypesQuery(baseOptions: Apollo.QueryHookOptions<GetComponentTagTypesQuery, GetComponentTagTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetComponentTagTypesQuery, GetComponentTagTypesQueryVariables>(GetComponentTagTypesDocument, options);
      }
export function useGetComponentTagTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetComponentTagTypesQuery, GetComponentTagTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetComponentTagTypesQuery, GetComponentTagTypesQueryVariables>(GetComponentTagTypesDocument, options);
        }
export type GetComponentTagTypesQueryHookResult = ReturnType<typeof useGetComponentTagTypesQuery>;
export type GetComponentTagTypesLazyQueryHookResult = ReturnType<typeof useGetComponentTagTypesLazyQuery>;
export type GetComponentTagTypesQueryResult = Apollo.QueryResult<GetComponentTagTypesQuery, GetComponentTagTypesQueryVariables>;
export const CreateComponentDocument = gql`
    mutation CreateComponent($customer: String!, $project: String!, $scope: Scope!, $name: String!, $taskId: UUID, $componentTypeId: UUID!, $subcontractorId: UUID, $weight: Float, $object: String!, $tags: [String!]!) {
  createComponent(
    tenant: {customer: $customer, project: $project, scope: $scope}
    component: {name: $name, taskId: $taskId, componentTypeId: $componentTypeId, subcontractorId: $subcontractorId, weight: $weight, object: $object, tags: $tags}
  ) {
    ...Component
  }
}
    ${ComponentFragmentDoc}`;
export type CreateComponentMutationFn = Apollo.MutationFunction<CreateComponentMutation, CreateComponentMutationVariables>;

/**
 * __useCreateComponentMutation__
 *
 * To run a mutation, you first call `useCreateComponentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateComponentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createComponentMutation, { data, loading, error }] = useCreateComponentMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      name: // value for 'name'
 *      taskId: // value for 'taskId'
 *      componentTypeId: // value for 'componentTypeId'
 *      subcontractorId: // value for 'subcontractorId'
 *      weight: // value for 'weight'
 *      object: // value for 'object'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useCreateComponentMutation(baseOptions?: Apollo.MutationHookOptions<CreateComponentMutation, CreateComponentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateComponentMutation, CreateComponentMutationVariables>(CreateComponentDocument, options);
      }
export type CreateComponentMutationHookResult = ReturnType<typeof useCreateComponentMutation>;
export type CreateComponentMutationResult = Apollo.MutationResult<CreateComponentMutation>;
export type CreateComponentMutationOptions = Apollo.BaseMutationOptions<CreateComponentMutation, CreateComponentMutationVariables>;
export const DeleteComponentsDocument = gql`
    mutation DeleteComponents($customer: String!, $project: String!, $scope: Scope!, $ids: [UUID!]!) {
  deleteComponents(
    tenant: {customer: $customer, project: $project, scope: $scope}
    ids: $ids
  )
}
    `;
export type DeleteComponentsMutationFn = Apollo.MutationFunction<DeleteComponentsMutation, DeleteComponentsMutationVariables>;

/**
 * __useDeleteComponentsMutation__
 *
 * To run a mutation, you first call `useDeleteComponentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteComponentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteComponentsMutation, { data, loading, error }] = useDeleteComponentsMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteComponentsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteComponentsMutation, DeleteComponentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteComponentsMutation, DeleteComponentsMutationVariables>(DeleteComponentsDocument, options);
      }
export type DeleteComponentsMutationHookResult = ReturnType<typeof useDeleteComponentsMutation>;
export type DeleteComponentsMutationResult = Apollo.MutationResult<DeleteComponentsMutation>;
export type DeleteComponentsMutationOptions = Apollo.BaseMutationOptions<DeleteComponentsMutation, DeleteComponentsMutationVariables>;
export const RemoveComponentsFromTaskDocument = gql`
    mutation RemoveComponentsFromTask($customer: String!, $project: String!, $scope: Scope!, $componentIds: [UUID!]!, $taskId: UUID!) {
  removeComponentsFromTask(
    tenant: {customer: $customer, project: $project, scope: $scope}
    componentIds: $componentIds
    taskId: $taskId
  )
}
    `;
export type RemoveComponentsFromTaskMutationFn = Apollo.MutationFunction<RemoveComponentsFromTaskMutation, RemoveComponentsFromTaskMutationVariables>;

/**
 * __useRemoveComponentsFromTaskMutation__
 *
 * To run a mutation, you first call `useRemoveComponentsFromTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveComponentsFromTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeComponentsFromTaskMutation, { data, loading, error }] = useRemoveComponentsFromTaskMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      componentIds: // value for 'componentIds'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useRemoveComponentsFromTaskMutation(baseOptions?: Apollo.MutationHookOptions<RemoveComponentsFromTaskMutation, RemoveComponentsFromTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveComponentsFromTaskMutation, RemoveComponentsFromTaskMutationVariables>(RemoveComponentsFromTaskDocument, options);
      }
export type RemoveComponentsFromTaskMutationHookResult = ReturnType<typeof useRemoveComponentsFromTaskMutation>;
export type RemoveComponentsFromTaskMutationResult = Apollo.MutationResult<RemoveComponentsFromTaskMutation>;
export type RemoveComponentsFromTaskMutationOptions = Apollo.BaseMutationOptions<RemoveComponentsFromTaskMutation, RemoveComponentsFromTaskMutationVariables>;
export const UpdateComponentsDocument = gql`
    mutation UpdateComponents($customer: String!, $project: String!, $scope: Scope!, $components: [UpdateComponentInputType!]!, $taskId: UUID) {
  updateComponents(
    tenant: {customer: $customer, project: $project, scope: $scope}
    components: $components
  ) {
    ...Component
  }
}
    ${ComponentFragmentDoc}`;
export type UpdateComponentsMutationFn = Apollo.MutationFunction<UpdateComponentsMutation, UpdateComponentsMutationVariables>;

/**
 * __useUpdateComponentsMutation__
 *
 * To run a mutation, you first call `useUpdateComponentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateComponentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateComponentsMutation, { data, loading, error }] = useUpdateComponentsMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      components: // value for 'components'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useUpdateComponentsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateComponentsMutation, UpdateComponentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateComponentsMutation, UpdateComponentsMutationVariables>(UpdateComponentsDocument, options);
      }
export type UpdateComponentsMutationHookResult = ReturnType<typeof useUpdateComponentsMutation>;
export type UpdateComponentsMutationResult = Apollo.MutationResult<UpdateComponentsMutation>;
export type UpdateComponentsMutationOptions = Apollo.BaseMutationOptions<UpdateComponentsMutation, UpdateComponentsMutationVariables>;
export const CreateMappingsDocument = gql`
    mutation CreateMappings($customer: String!, $project: String!, $scope: Scope!, $componentIds: [UUID!]!, $spaceIds: [UUID!]!) {
  createMappings(
    tenant: {customer: $customer, project: $project, scope: $scope}
    componentIds: $componentIds
    spaceIds: $spaceIds
  ) {
    id
    space {
      id
      name
    }
    component {
      id
      name
    }
    disabled
  }
}
    `;
export type CreateMappingsMutationFn = Apollo.MutationFunction<CreateMappingsMutation, CreateMappingsMutationVariables>;

/**
 * __useCreateMappingsMutation__
 *
 * To run a mutation, you first call `useCreateMappingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMappingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMappingsMutation, { data, loading, error }] = useCreateMappingsMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      componentIds: // value for 'componentIds'
 *      spaceIds: // value for 'spaceIds'
 *   },
 * });
 */
export function useCreateMappingsMutation(baseOptions?: Apollo.MutationHookOptions<CreateMappingsMutation, CreateMappingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMappingsMutation, CreateMappingsMutationVariables>(CreateMappingsDocument, options);
      }
export type CreateMappingsMutationHookResult = ReturnType<typeof useCreateMappingsMutation>;
export type CreateMappingsMutationResult = Apollo.MutationResult<CreateMappingsMutation>;
export type CreateMappingsMutationOptions = Apollo.BaseMutationOptions<CreateMappingsMutation, CreateMappingsMutationVariables>;
export const UpdateMappingsDocument = gql`
    mutation UpdateMappings($customer: String!, $project: String!, $scope: Scope!, $mappingUpdates: [UpdateMappingInputType!]!) {
  updateMappings(
    tenant: {customer: $customer, project: $project, scope: $scope}
    mappings: $mappingUpdates
  ) {
    id
    disabled
  }
}
    `;
export type UpdateMappingsMutationFn = Apollo.MutationFunction<UpdateMappingsMutation, UpdateMappingsMutationVariables>;

/**
 * __useUpdateMappingsMutation__
 *
 * To run a mutation, you first call `useUpdateMappingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMappingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMappingsMutation, { data, loading, error }] = useUpdateMappingsMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      mappingUpdates: // value for 'mappingUpdates'
 *   },
 * });
 */
export function useUpdateMappingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMappingsMutation, UpdateMappingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMappingsMutation, UpdateMappingsMutationVariables>(UpdateMappingsDocument, options);
      }
export type UpdateMappingsMutationHookResult = ReturnType<typeof useUpdateMappingsMutation>;
export type UpdateMappingsMutationResult = Apollo.MutationResult<UpdateMappingsMutation>;
export type UpdateMappingsMutationOptions = Apollo.BaseMutationOptions<UpdateMappingsMutation, UpdateMappingsMutationVariables>;
export const DeleteMappingsDocument = gql`
    mutation DeleteMappings($customer: String!, $project: String!, $scope: Scope!, $mappingIds: [UUID!]!) {
  deleteMappings(
    tenant: {customer: $customer, project: $project, scope: $scope}
    mappingIds: $mappingIds
  )
}
    `;
export type DeleteMappingsMutationFn = Apollo.MutationFunction<DeleteMappingsMutation, DeleteMappingsMutationVariables>;

/**
 * __useDeleteMappingsMutation__
 *
 * To run a mutation, you first call `useDeleteMappingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMappingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMappingsMutation, { data, loading, error }] = useDeleteMappingsMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      mappingIds: // value for 'mappingIds'
 *   },
 * });
 */
export function useDeleteMappingsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMappingsMutation, DeleteMappingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMappingsMutation, DeleteMappingsMutationVariables>(DeleteMappingsDocument, options);
      }
export type DeleteMappingsMutationHookResult = ReturnType<typeof useDeleteMappingsMutation>;
export type DeleteMappingsMutationResult = Apollo.MutationResult<DeleteMappingsMutation>;
export type DeleteMappingsMutationOptions = Apollo.BaseMutationOptions<DeleteMappingsMutation, DeleteMappingsMutationVariables>;
export const GetSpacesAndComponentsDataDocument = gql`
    query GetSpacesAndComponentsData($customer: String!, $project: String!, $scope: Scope!, $spaceIds: [UUID!]!, $componentIds: [UUID!]!) {
  spacesByIds(
    tenant: {customer: $customer, project: $project, scope: $scope}
    ids: $spaceIds
  ) {
    ...SpaceWithMappingDetails
  }
  componentsByIds(
    tenant: {customer: $customer, project: $project, scope: $scope}
    componentIds: $componentIds
  ) {
    id
    name
  }
}
    ${SpaceWithMappingDetailsFragmentDoc}`;

/**
 * __useGetSpacesAndComponentsDataQuery__
 *
 * To run a query within a React component, call `useGetSpacesAndComponentsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpacesAndComponentsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpacesAndComponentsDataQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      spaceIds: // value for 'spaceIds'
 *      componentIds: // value for 'componentIds'
 *   },
 * });
 */
export function useGetSpacesAndComponentsDataQuery(baseOptions: Apollo.QueryHookOptions<GetSpacesAndComponentsDataQuery, GetSpacesAndComponentsDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSpacesAndComponentsDataQuery, GetSpacesAndComponentsDataQueryVariables>(GetSpacesAndComponentsDataDocument, options);
      }
export function useGetSpacesAndComponentsDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSpacesAndComponentsDataQuery, GetSpacesAndComponentsDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSpacesAndComponentsDataQuery, GetSpacesAndComponentsDataQueryVariables>(GetSpacesAndComponentsDataDocument, options);
        }
export type GetSpacesAndComponentsDataQueryHookResult = ReturnType<typeof useGetSpacesAndComponentsDataQuery>;
export type GetSpacesAndComponentsDataLazyQueryHookResult = ReturnType<typeof useGetSpacesAndComponentsDataLazyQuery>;
export type GetSpacesAndComponentsDataQueryResult = Apollo.QueryResult<GetSpacesAndComponentsDataQuery, GetSpacesAndComponentsDataQueryVariables>;
export const UpdateComponentTagsDocument = gql`
    mutation UpdateComponentTags($customer: String!, $project: String!, $scope: Scope!, $components: [UpdateComponentInputType!]!) {
  updateComponents(
    tenant: {customer: $customer, project: $project, scope: $scope}
    components: $components
  ) {
    name
    id
    tags
  }
}
    `;
export type UpdateComponentTagsMutationFn = Apollo.MutationFunction<UpdateComponentTagsMutation, UpdateComponentTagsMutationVariables>;

/**
 * __useUpdateComponentTagsMutation__
 *
 * To run a mutation, you first call `useUpdateComponentTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateComponentTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateComponentTagsMutation, { data, loading, error }] = useUpdateComponentTagsMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      components: // value for 'components'
 *   },
 * });
 */
export function useUpdateComponentTagsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateComponentTagsMutation, UpdateComponentTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateComponentTagsMutation, UpdateComponentTagsMutationVariables>(UpdateComponentTagsDocument, options);
      }
export type UpdateComponentTagsMutationHookResult = ReturnType<typeof useUpdateComponentTagsMutation>;
export type UpdateComponentTagsMutationResult = Apollo.MutationResult<UpdateComponentTagsMutation>;
export type UpdateComponentTagsMutationOptions = Apollo.BaseMutationOptions<UpdateComponentTagsMutation, UpdateComponentTagsMutationVariables>;
export const GetAllComponentTagTypesByPlanDocument = gql`
    query GetAllComponentTagTypesByPlan($customer: String!, $project: String!, $scope: Scope!, $planId: UUID!) {
  componentTagTypesByPlan(
    tenant: {customer: $customer, project: $project, scope: $scope}
    planId: $planId
  )
}
    `;

/**
 * __useGetAllComponentTagTypesByPlanQuery__
 *
 * To run a query within a React component, call `useGetAllComponentTagTypesByPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllComponentTagTypesByPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllComponentTagTypesByPlanQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useGetAllComponentTagTypesByPlanQuery(baseOptions: Apollo.QueryHookOptions<GetAllComponentTagTypesByPlanQuery, GetAllComponentTagTypesByPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllComponentTagTypesByPlanQuery, GetAllComponentTagTypesByPlanQueryVariables>(GetAllComponentTagTypesByPlanDocument, options);
      }
export function useGetAllComponentTagTypesByPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllComponentTagTypesByPlanQuery, GetAllComponentTagTypesByPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllComponentTagTypesByPlanQuery, GetAllComponentTagTypesByPlanQueryVariables>(GetAllComponentTagTypesByPlanDocument, options);
        }
export type GetAllComponentTagTypesByPlanQueryHookResult = ReturnType<typeof useGetAllComponentTagTypesByPlanQuery>;
export type GetAllComponentTagTypesByPlanLazyQueryHookResult = ReturnType<typeof useGetAllComponentTagTypesByPlanLazyQuery>;
export type GetAllComponentTagTypesByPlanQueryResult = Apollo.QueryResult<GetAllComponentTagTypesByPlanQuery, GetAllComponentTagTypesByPlanQueryVariables>;
export const GetExistingComponentsDocument = gql`
    query GetExistingComponents($customer: String!, $project: String!, $scope: Scope!, $componentIds: [UUID!]!, $planId: UUID!) {
  componentsByIds(
    tenant: {customer: $customer, project: $project, scope: $scope}
    componentIds: $componentIds
  ) {
    name
    id
    tags
    guiIndex(planId: $planId)
    parentTask(planId: $planId) {
      id
      name
      guiIndex
      ancestors {
        id
        name
        guiIndex
      }
    }
  }
}
    `;

/**
 * __useGetExistingComponentsQuery__
 *
 * To run a query within a React component, call `useGetExistingComponentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExistingComponentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExistingComponentsQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      componentIds: // value for 'componentIds'
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useGetExistingComponentsQuery(baseOptions: Apollo.QueryHookOptions<GetExistingComponentsQuery, GetExistingComponentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExistingComponentsQuery, GetExistingComponentsQueryVariables>(GetExistingComponentsDocument, options);
      }
export function useGetExistingComponentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExistingComponentsQuery, GetExistingComponentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExistingComponentsQuery, GetExistingComponentsQueryVariables>(GetExistingComponentsDocument, options);
        }
export type GetExistingComponentsQueryHookResult = ReturnType<typeof useGetExistingComponentsQuery>;
export type GetExistingComponentsLazyQueryHookResult = ReturnType<typeof useGetExistingComponentsLazyQuery>;
export type GetExistingComponentsQueryResult = Apollo.QueryResult<GetExistingComponentsQuery, GetExistingComponentsQueryVariables>;
export const PatchSpacesDocument = gql`
    mutation PatchSpaces($customer: String!, $project: String!, $scope: Scope!, $ids: [UUID!]!, $update: PatchSpaceInputType!) {
  patchSpaces(
    tenant: {customer: $customer, project: $project, scope: $scope}
    spaceIds: $ids
    update: $update
  ) {
    id
    name
    type
    category
    tags
    parentSpace {
      id
    }
  }
}
    `;
export type PatchSpacesMutationFn = Apollo.MutationFunction<PatchSpacesMutation, PatchSpacesMutationVariables>;

/**
 * __usePatchSpacesMutation__
 *
 * To run a mutation, you first call `usePatchSpacesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchSpacesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchSpacesMutation, { data, loading, error }] = usePatchSpacesMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      ids: // value for 'ids'
 *      update: // value for 'update'
 *   },
 * });
 */
export function usePatchSpacesMutation(baseOptions?: Apollo.MutationHookOptions<PatchSpacesMutation, PatchSpacesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PatchSpacesMutation, PatchSpacesMutationVariables>(PatchSpacesDocument, options);
      }
export type PatchSpacesMutationHookResult = ReturnType<typeof usePatchSpacesMutation>;
export type PatchSpacesMutationResult = Apollo.MutationResult<PatchSpacesMutation>;
export type PatchSpacesMutationOptions = Apollo.BaseMutationOptions<PatchSpacesMutation, PatchSpacesMutationVariables>;
export const CreateSpacesDocument = gql`
    mutation CreateSpaces($customer: String!, $project: String!, $scope: Scope!, $spaces: [CreateSpaceInputType!]!) {
  createSpaces(
    tenant: {customer: $customer, project: $project, scope: $scope}
    spaces: $spaces
  ) {
    id
    name
    type
    category
    tags
    parentSpace {
      id
    }
  }
}
    `;
export type CreateSpacesMutationFn = Apollo.MutationFunction<CreateSpacesMutation, CreateSpacesMutationVariables>;

/**
 * __useCreateSpacesMutation__
 *
 * To run a mutation, you first call `useCreateSpacesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSpacesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSpacesMutation, { data, loading, error }] = useCreateSpacesMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      spaces: // value for 'spaces'
 *   },
 * });
 */
export function useCreateSpacesMutation(baseOptions?: Apollo.MutationHookOptions<CreateSpacesMutation, CreateSpacesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSpacesMutation, CreateSpacesMutationVariables>(CreateSpacesDocument, options);
      }
export type CreateSpacesMutationHookResult = ReturnType<typeof useCreateSpacesMutation>;
export type CreateSpacesMutationResult = Apollo.MutationResult<CreateSpacesMutation>;
export type CreateSpacesMutationOptions = Apollo.BaseMutationOptions<CreateSpacesMutation, CreateSpacesMutationVariables>;
export const DeleteSpacesDocument = gql`
    mutation DeleteSpaces($customer: String!, $project: String!, $scope: Scope!, $spaceIds: [UUID!]!) {
  deleteSpaces(
    tenant: {customer: $customer, project: $project, scope: $scope}
    spaceIds: $spaceIds
  )
}
    `;
export type DeleteSpacesMutationFn = Apollo.MutationFunction<DeleteSpacesMutation, DeleteSpacesMutationVariables>;

/**
 * __useDeleteSpacesMutation__
 *
 * To run a mutation, you first call `useDeleteSpacesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSpacesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSpacesMutation, { data, loading, error }] = useDeleteSpacesMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      spaceIds: // value for 'spaceIds'
 *   },
 * });
 */
export function useDeleteSpacesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSpacesMutation, DeleteSpacesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSpacesMutation, DeleteSpacesMutationVariables>(DeleteSpacesDocument, options);
      }
export type DeleteSpacesMutationHookResult = ReturnType<typeof useDeleteSpacesMutation>;
export type DeleteSpacesMutationResult = Apollo.MutationResult<DeleteSpacesMutation>;
export type DeleteSpacesMutationOptions = Apollo.BaseMutationOptions<DeleteSpacesMutation, DeleteSpacesMutationVariables>;
export const UpdateScenesDocument = gql`
    mutation UpdateScenes($customer: String!, $project: String!, $scope: Scope!, $scenes: [UpdateSceneInputType!]!) {
  updateScenes(
    tenant: {customer: $customer, project: $project, scope: $scope}
    scenes: $scenes
  ) {
    id
    name
    guiIndex
    space {
      id
    }
  }
}
    `;
export type UpdateScenesMutationFn = Apollo.MutationFunction<UpdateScenesMutation, UpdateScenesMutationVariables>;

/**
 * __useUpdateScenesMutation__
 *
 * To run a mutation, you first call `useUpdateScenesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScenesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScenesMutation, { data, loading, error }] = useUpdateScenesMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      scenes: // value for 'scenes'
 *   },
 * });
 */
export function useUpdateScenesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateScenesMutation, UpdateScenesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateScenesMutation, UpdateScenesMutationVariables>(UpdateScenesDocument, options);
      }
export type UpdateScenesMutationHookResult = ReturnType<typeof useUpdateScenesMutation>;
export type UpdateScenesMutationResult = Apollo.MutationResult<UpdateScenesMutation>;
export type UpdateScenesMutationOptions = Apollo.BaseMutationOptions<UpdateScenesMutation, UpdateScenesMutationVariables>;
export const CreateScenesDocument = gql`
    mutation CreateScenes($customer: String!, $project: String!, $scope: Scope!, $scenes: [CreateSceneInputType!]!) {
  createScenes(
    tenant: {customer: $customer, project: $project, scope: $scope}
    scenes: $scenes
  ) {
    id
    name
    guiIndex
    space {
      id
    }
  }
}
    `;
export type CreateScenesMutationFn = Apollo.MutationFunction<CreateScenesMutation, CreateScenesMutationVariables>;

/**
 * __useCreateScenesMutation__
 *
 * To run a mutation, you first call `useCreateScenesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScenesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScenesMutation, { data, loading, error }] = useCreateScenesMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      scenes: // value for 'scenes'
 *   },
 * });
 */
export function useCreateScenesMutation(baseOptions?: Apollo.MutationHookOptions<CreateScenesMutation, CreateScenesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateScenesMutation, CreateScenesMutationVariables>(CreateScenesDocument, options);
      }
export type CreateScenesMutationHookResult = ReturnType<typeof useCreateScenesMutation>;
export type CreateScenesMutationResult = Apollo.MutationResult<CreateScenesMutation>;
export type CreateScenesMutationOptions = Apollo.BaseMutationOptions<CreateScenesMutation, CreateScenesMutationVariables>;
export const DeleteScenesDocument = gql`
    mutation DeleteScenes($customer: String!, $project: String!, $scope: Scope!, $sceneIds: [UUID!]!) {
  deleteScenes(
    tenant: {customer: $customer, project: $project, scope: $scope}
    sceneIds: $sceneIds
  )
}
    `;
export type DeleteScenesMutationFn = Apollo.MutationFunction<DeleteScenesMutation, DeleteScenesMutationVariables>;

/**
 * __useDeleteScenesMutation__
 *
 * To run a mutation, you first call `useDeleteScenesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScenesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScenesMutation, { data, loading, error }] = useDeleteScenesMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      sceneIds: // value for 'sceneIds'
 *   },
 * });
 */
export function useDeleteScenesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteScenesMutation, DeleteScenesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteScenesMutation, DeleteScenesMutationVariables>(DeleteScenesDocument, options);
      }
export type DeleteScenesMutationHookResult = ReturnType<typeof useDeleteScenesMutation>;
export type DeleteScenesMutationResult = Apollo.MutationResult<DeleteScenesMutation>;
export type DeleteScenesMutationOptions = Apollo.BaseMutationOptions<DeleteScenesMutation, DeleteScenesMutationVariables>;
export const GetExistingSpaceTagsDocument = gql`
    query GetExistingSpaceTags($customer: String!, $project: String!, $scope: Scope!) {
  spaceTags(tenant: {customer: $customer, project: $project, scope: $scope})
}
    `;

/**
 * __useGetExistingSpaceTagsQuery__
 *
 * To run a query within a React component, call `useGetExistingSpaceTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExistingSpaceTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExistingSpaceTagsQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useGetExistingSpaceTagsQuery(baseOptions: Apollo.QueryHookOptions<GetExistingSpaceTagsQuery, GetExistingSpaceTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExistingSpaceTagsQuery, GetExistingSpaceTagsQueryVariables>(GetExistingSpaceTagsDocument, options);
      }
export function useGetExistingSpaceTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExistingSpaceTagsQuery, GetExistingSpaceTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExistingSpaceTagsQuery, GetExistingSpaceTagsQueryVariables>(GetExistingSpaceTagsDocument, options);
        }
export type GetExistingSpaceTagsQueryHookResult = ReturnType<typeof useGetExistingSpaceTagsQuery>;
export type GetExistingSpaceTagsLazyQueryHookResult = ReturnType<typeof useGetExistingSpaceTagsLazyQuery>;
export type GetExistingSpaceTagsQueryResult = Apollo.QueryResult<GetExistingSpaceTagsQuery, GetExistingSpaceTagsQueryVariables>;
export const GetDatesQaDataDocument = gql`
    query GetDatesQAData($customer: String!, $project: String!, $scope: Scope!) {
  generateDatesQAData(
    tenant: {customer: $customer, project: $project, scope: $scope}
  ) {
    taskId
    spaceId
    weight
    startDate
    endDate
  }
}
    `;

/**
 * __useGetDatesQaDataQuery__
 *
 * To run a query within a React component, call `useGetDatesQaDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDatesQaDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDatesQaDataQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useGetDatesQaDataQuery(baseOptions: Apollo.QueryHookOptions<GetDatesQaDataQuery, GetDatesQaDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDatesQaDataQuery, GetDatesQaDataQueryVariables>(GetDatesQaDataDocument, options);
      }
export function useGetDatesQaDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDatesQaDataQuery, GetDatesQaDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDatesQaDataQuery, GetDatesQaDataQueryVariables>(GetDatesQaDataDocument, options);
        }
export type GetDatesQaDataQueryHookResult = ReturnType<typeof useGetDatesQaDataQuery>;
export type GetDatesQaDataLazyQueryHookResult = ReturnType<typeof useGetDatesQaDataLazyQuery>;
export type GetDatesQaDataQueryResult = Apollo.QueryResult<GetDatesQaDataQuery, GetDatesQaDataQueryVariables>;
export const DeleteShotDocument = gql`
    mutation DeleteShot($customer: String!, $project: String!, $scope: Scope!, $shotId: UUID!) {
  deleteShot(
    tenant: {customer: $customer, project: $project, scope: $scope}
    shotId: $shotId
  )
}
    `;
export type DeleteShotMutationFn = Apollo.MutationFunction<DeleteShotMutation, DeleteShotMutationVariables>;

/**
 * __useDeleteShotMutation__
 *
 * To run a mutation, you first call `useDeleteShotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShotMutation, { data, loading, error }] = useDeleteShotMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      shotId: // value for 'shotId'
 *   },
 * });
 */
export function useDeleteShotMutation(baseOptions?: Apollo.MutationHookOptions<DeleteShotMutation, DeleteShotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteShotMutation, DeleteShotMutationVariables>(DeleteShotDocument, options);
      }
export type DeleteShotMutationHookResult = ReturnType<typeof useDeleteShotMutation>;
export type DeleteShotMutationResult = Apollo.MutationResult<DeleteShotMutation>;
export type DeleteShotMutationOptions = Apollo.BaseMutationOptions<DeleteShotMutation, DeleteShotMutationVariables>;
export const MarkShotMissingDocument = gql`
    mutation markShotMissing($customer: String!, $project: String!, $scope: Scope!, $shotId: UUID!) {
  patchShot(
    tenant: {customer: $customer, project: $project, scope: $scope}
    shotId: $shotId
    patch: {setImageId: {newValue: null}}
  ) {
    id
  }
}
    `;
export type MarkShotMissingMutationFn = Apollo.MutationFunction<MarkShotMissingMutation, MarkShotMissingMutationVariables>;

/**
 * __useMarkShotMissingMutation__
 *
 * To run a mutation, you first call `useMarkShotMissingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkShotMissingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markShotMissingMutation, { data, loading, error }] = useMarkShotMissingMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      shotId: // value for 'shotId'
 *   },
 * });
 */
export function useMarkShotMissingMutation(baseOptions?: Apollo.MutationHookOptions<MarkShotMissingMutation, MarkShotMissingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkShotMissingMutation, MarkShotMissingMutationVariables>(MarkShotMissingDocument, options);
      }
export type MarkShotMissingMutationHookResult = ReturnType<typeof useMarkShotMissingMutation>;
export type MarkShotMissingMutationResult = Apollo.MutationResult<MarkShotMissingMutation>;
export type MarkShotMissingMutationOptions = Apollo.BaseMutationOptions<MarkShotMissingMutation, MarkShotMissingMutationVariables>;
export const GetShotBySceneAndBatchDocument = gql`
    query getShotBySceneAndBatch($batchId: UUID!, $sceneId: UUID!, $tenant: TenantInputType!) {
  shot(tenant: $tenant, sceneId: $sceneId, batchId: $batchId) {
    ...ShotFragment
  }
}
    ${ShotFragmentFragmentDoc}`;

/**
 * __useGetShotBySceneAndBatchQuery__
 *
 * To run a query within a React component, call `useGetShotBySceneAndBatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShotBySceneAndBatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShotBySceneAndBatchQuery({
 *   variables: {
 *      batchId: // value for 'batchId'
 *      sceneId: // value for 'sceneId'
 *      tenant: // value for 'tenant'
 *   },
 * });
 */
export function useGetShotBySceneAndBatchQuery(baseOptions: Apollo.QueryHookOptions<GetShotBySceneAndBatchQuery, GetShotBySceneAndBatchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShotBySceneAndBatchQuery, GetShotBySceneAndBatchQueryVariables>(GetShotBySceneAndBatchDocument, options);
      }
export function useGetShotBySceneAndBatchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShotBySceneAndBatchQuery, GetShotBySceneAndBatchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShotBySceneAndBatchQuery, GetShotBySceneAndBatchQueryVariables>(GetShotBySceneAndBatchDocument, options);
        }
export type GetShotBySceneAndBatchQueryHookResult = ReturnType<typeof useGetShotBySceneAndBatchQuery>;
export type GetShotBySceneAndBatchLazyQueryHookResult = ReturnType<typeof useGetShotBySceneAndBatchLazyQuery>;
export type GetShotBySceneAndBatchQueryResult = Apollo.QueryResult<GetShotBySceneAndBatchQuery, GetShotBySceneAndBatchQueryVariables>;
export const ZeroPointShotDocument = gql`
    mutation zeroPointShot($customer: String!, $project: String!, $scope: Scope!, $shotId: UUID!, $yaw: Float!, $batchId: UUID!) {
  zeroPointShot(
    tenant: {customer: $customer, project: $project, scope: $scope}
    shotId: $shotId
    newYaw: $yaw
  ) {
    ...ShotFragment
  }
}
    ${ShotFragmentFragmentDoc}`;
export type ZeroPointShotMutationFn = Apollo.MutationFunction<ZeroPointShotMutation, ZeroPointShotMutationVariables>;

/**
 * __useZeroPointShotMutation__
 *
 * To run a mutation, you first call `useZeroPointShotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useZeroPointShotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [zeroPointShotMutation, { data, loading, error }] = useZeroPointShotMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      shotId: // value for 'shotId'
 *      yaw: // value for 'yaw'
 *      batchId: // value for 'batchId'
 *   },
 * });
 */
export function useZeroPointShotMutation(baseOptions?: Apollo.MutationHookOptions<ZeroPointShotMutation, ZeroPointShotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ZeroPointShotMutation, ZeroPointShotMutationVariables>(ZeroPointShotDocument, options);
      }
export type ZeroPointShotMutationHookResult = ReturnType<typeof useZeroPointShotMutation>;
export type ZeroPointShotMutationResult = Apollo.MutationResult<ZeroPointShotMutation>;
export type ZeroPointShotMutationOptions = Apollo.BaseMutationOptions<ZeroPointShotMutation, ZeroPointShotMutationVariables>;
export const GetMappingsForSpaceDocument = gql`
    query getMappingsForSpace($customer: String!, $project: String!, $scope: Scope!, $spaceId: UUID!) {
  space(
    tenant: {customer: $customer, project: $project, scope: $scope}
    id: $spaceId
  ) {
    ...SpaceWithMapping
    ancestors {
      ...SpaceWithMapping
    }
    siblingSpaces {
      ...SpaceWithMapping
    }
    descendantSpaces {
      ...SpaceWithMapping
    }
  }
}
    ${SpaceWithMappingFragmentDoc}`;

/**
 * __useGetMappingsForSpaceQuery__
 *
 * To run a query within a React component, call `useGetMappingsForSpaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMappingsForSpaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMappingsForSpaceQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useGetMappingsForSpaceQuery(baseOptions: Apollo.QueryHookOptions<GetMappingsForSpaceQuery, GetMappingsForSpaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMappingsForSpaceQuery, GetMappingsForSpaceQueryVariables>(GetMappingsForSpaceDocument, options);
      }
export function useGetMappingsForSpaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMappingsForSpaceQuery, GetMappingsForSpaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMappingsForSpaceQuery, GetMappingsForSpaceQueryVariables>(GetMappingsForSpaceDocument, options);
        }
export type GetMappingsForSpaceQueryHookResult = ReturnType<typeof useGetMappingsForSpaceQuery>;
export type GetMappingsForSpaceLazyQueryHookResult = ReturnType<typeof useGetMappingsForSpaceLazyQuery>;
export type GetMappingsForSpaceQueryResult = Apollo.QueryResult<GetMappingsForSpaceQuery, GetMappingsForSpaceQueryVariables>;
export const GetSceneDocument = gql`
    query getScene($customer: String!, $project: String!, $scope: Scope!, $sceneId: UUID!) {
  scene(
    tenant: {customer: $customer, project: $project, scope: $scope}
    id: $sceneId
  ) {
    id
    shell360Id
    space {
      id
    }
  }
}
    `;

/**
 * __useGetSceneQuery__
 *
 * To run a query within a React component, call `useGetSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSceneQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      sceneId: // value for 'sceneId'
 *   },
 * });
 */
export function useGetSceneQuery(baseOptions: Apollo.QueryHookOptions<GetSceneQuery, GetSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSceneQuery, GetSceneQueryVariables>(GetSceneDocument, options);
      }
export function useGetSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSceneQuery, GetSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSceneQuery, GetSceneQueryVariables>(GetSceneDocument, options);
        }
export type GetSceneQueryHookResult = ReturnType<typeof useGetSceneQuery>;
export type GetSceneLazyQueryHookResult = ReturnType<typeof useGetSceneLazyQuery>;
export type GetSceneQueryResult = Apollo.QueryResult<GetSceneQuery, GetSceneQueryVariables>;
export const GetSceneCoordinatesDocument = gql`
    query GetSceneCoordinates($customer: String!, $project: String!, $scope: Scope!, $sceneId: UUID!) {
  scene(
    tenant: {customer: $customer, project: $project, scope: $scope}
    id: $sceneId
  ) {
    id
    mmX
    mmY
    mmZOffset
  }
}
    `;

/**
 * __useGetSceneCoordinatesQuery__
 *
 * To run a query within a React component, call `useGetSceneCoordinatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSceneCoordinatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSceneCoordinatesQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      sceneId: // value for 'sceneId'
 *   },
 * });
 */
export function useGetSceneCoordinatesQuery(baseOptions: Apollo.QueryHookOptions<GetSceneCoordinatesQuery, GetSceneCoordinatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSceneCoordinatesQuery, GetSceneCoordinatesQueryVariables>(GetSceneCoordinatesDocument, options);
      }
export function useGetSceneCoordinatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSceneCoordinatesQuery, GetSceneCoordinatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSceneCoordinatesQuery, GetSceneCoordinatesQueryVariables>(GetSceneCoordinatesDocument, options);
        }
export type GetSceneCoordinatesQueryHookResult = ReturnType<typeof useGetSceneCoordinatesQuery>;
export type GetSceneCoordinatesLazyQueryHookResult = ReturnType<typeof useGetSceneCoordinatesLazyQuery>;
export type GetSceneCoordinatesQueryResult = Apollo.QueryResult<GetSceneCoordinatesQuery, GetSceneCoordinatesQueryVariables>;
export const CreateSpotlightDocument = gql`
    mutation createSpotlight($customer: String!, $project: String!, $scope: Scope!, $sceneId: UUID!, $mappingId: UUID!, $type: SpotlightType!, $yaw: Float!, $pitch: Float!, $createdInBatchId: UUID!, $batchId: UUID!) {
  createSpotlight(
    tenant: {customer: $customer, project: $project, scope: $scope}
    spotlight: {sceneId: $sceneId, type: $type, mappingId: $mappingId, location: {yaw: $yaw, pitch: $pitch}, createdInBatchId: $createdInBatchId}
  ) {
    ...SpotlightFragment
  }
}
    ${SpotlightFragmentFragmentDoc}`;
export type CreateSpotlightMutationFn = Apollo.MutationFunction<CreateSpotlightMutation, CreateSpotlightMutationVariables>;

/**
 * __useCreateSpotlightMutation__
 *
 * To run a mutation, you first call `useCreateSpotlightMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSpotlightMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSpotlightMutation, { data, loading, error }] = useCreateSpotlightMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      sceneId: // value for 'sceneId'
 *      mappingId: // value for 'mappingId'
 *      type: // value for 'type'
 *      yaw: // value for 'yaw'
 *      pitch: // value for 'pitch'
 *      createdInBatchId: // value for 'createdInBatchId'
 *      batchId: // value for 'batchId'
 *   },
 * });
 */
export function useCreateSpotlightMutation(baseOptions?: Apollo.MutationHookOptions<CreateSpotlightMutation, CreateSpotlightMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSpotlightMutation, CreateSpotlightMutationVariables>(CreateSpotlightDocument, options);
      }
export type CreateSpotlightMutationHookResult = ReturnType<typeof useCreateSpotlightMutation>;
export type CreateSpotlightMutationResult = Apollo.MutationResult<CreateSpotlightMutation>;
export type CreateSpotlightMutationOptions = Apollo.BaseMutationOptions<CreateSpotlightMutation, CreateSpotlightMutationVariables>;
export const UpdateSpotlightDocument = gql`
    mutation updateSpotlight($customer: String!, $project: String!, $scope: Scope!, $id: UUID!, $batchId: UUID!, $mappingId: UUID, $type: SpotlightType, $location: AnnotationLocationInputType, $createdInBatchId: UUID, $resolvedInBatchId: UUID) {
  updateSpotlight(
    tenant: {customer: $customer, project: $project, scope: $scope}
    spotlight: {id: $id, batchId: $batchId, type: $type, mappingId: $mappingId, location: $location, createdInBatchId: $createdInBatchId, resolvedInBatchId: $resolvedInBatchId}
  ) {
    ...SpotlightFragment
  }
}
    ${SpotlightFragmentFragmentDoc}`;
export type UpdateSpotlightMutationFn = Apollo.MutationFunction<UpdateSpotlightMutation, UpdateSpotlightMutationVariables>;

/**
 * __useUpdateSpotlightMutation__
 *
 * To run a mutation, you first call `useUpdateSpotlightMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSpotlightMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSpotlightMutation, { data, loading, error }] = useUpdateSpotlightMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      id: // value for 'id'
 *      batchId: // value for 'batchId'
 *      mappingId: // value for 'mappingId'
 *      type: // value for 'type'
 *      location: // value for 'location'
 *      createdInBatchId: // value for 'createdInBatchId'
 *      resolvedInBatchId: // value for 'resolvedInBatchId'
 *   },
 * });
 */
export function useUpdateSpotlightMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSpotlightMutation, UpdateSpotlightMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSpotlightMutation, UpdateSpotlightMutationVariables>(UpdateSpotlightDocument, options);
      }
export type UpdateSpotlightMutationHookResult = ReturnType<typeof useUpdateSpotlightMutation>;
export type UpdateSpotlightMutationResult = Apollo.MutationResult<UpdateSpotlightMutation>;
export type UpdateSpotlightMutationOptions = Apollo.BaseMutationOptions<UpdateSpotlightMutation, UpdateSpotlightMutationVariables>;
export const DeleteArrowDocument = gql`
    mutation deleteArrow($customer: String!, $project: String!, $scope: Scope!, $id: UUID!) {
  deleteArrow(
    tenant: {customer: $customer, project: $project, scope: $scope}
    id: $id
  )
}
    `;
export type DeleteArrowMutationFn = Apollo.MutationFunction<DeleteArrowMutation, DeleteArrowMutationVariables>;

/**
 * __useDeleteArrowMutation__
 *
 * To run a mutation, you first call `useDeleteArrowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArrowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArrowMutation, { data, loading, error }] = useDeleteArrowMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteArrowMutation(baseOptions?: Apollo.MutationHookOptions<DeleteArrowMutation, DeleteArrowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteArrowMutation, DeleteArrowMutationVariables>(DeleteArrowDocument, options);
      }
export type DeleteArrowMutationHookResult = ReturnType<typeof useDeleteArrowMutation>;
export type DeleteArrowMutationResult = Apollo.MutationResult<DeleteArrowMutation>;
export type DeleteArrowMutationOptions = Apollo.BaseMutationOptions<DeleteArrowMutation, DeleteArrowMutationVariables>;
export const CreateArrowDocument = gql`
    mutation createArrow($customer: String!, $project: String!, $scope: Scope!, $sceneId: UUID!, $targetSceneId: UUID!, $batchId: UUID!, $yaw: Float!, $pitch: Float!) {
  createArrow(
    tenant: {customer: $customer, project: $project, scope: $scope}
    arrow: {sceneId: $sceneId, targetSceneId: $targetSceneId, batchId: $batchId, location: {pitch: $pitch, yaw: $yaw}}
  ) {
    ...ArrowFragment
  }
}
    ${ArrowFragmentFragmentDoc}`;
export type CreateArrowMutationFn = Apollo.MutationFunction<CreateArrowMutation, CreateArrowMutationVariables>;

/**
 * __useCreateArrowMutation__
 *
 * To run a mutation, you first call `useCreateArrowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArrowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArrowMutation, { data, loading, error }] = useCreateArrowMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      sceneId: // value for 'sceneId'
 *      targetSceneId: // value for 'targetSceneId'
 *      batchId: // value for 'batchId'
 *      yaw: // value for 'yaw'
 *      pitch: // value for 'pitch'
 *   },
 * });
 */
export function useCreateArrowMutation(baseOptions?: Apollo.MutationHookOptions<CreateArrowMutation, CreateArrowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateArrowMutation, CreateArrowMutationVariables>(CreateArrowDocument, options);
      }
export type CreateArrowMutationHookResult = ReturnType<typeof useCreateArrowMutation>;
export type CreateArrowMutationResult = Apollo.MutationResult<CreateArrowMutation>;
export type CreateArrowMutationOptions = Apollo.BaseMutationOptions<CreateArrowMutation, CreateArrowMutationVariables>;
export const UpdateArrowDocument = gql`
    mutation updateArrow($customer: String!, $project: String!, $scope: Scope!, $id: UUID!, $sceneId: UUID!, $targetSceneId: UUID!, $batchId: UUID!, $yaw: Float!, $pitch: Float!) {
  updateArrow(
    tenant: {customer: $customer, project: $project, scope: $scope}
    arrow: {id: $id, sceneId: $sceneId, targetSceneId: $targetSceneId, batchId: $batchId, location: {pitch: $pitch, yaw: $yaw}}
  ) {
    ...ArrowFragment
  }
}
    ${ArrowFragmentFragmentDoc}`;
export type UpdateArrowMutationFn = Apollo.MutationFunction<UpdateArrowMutation, UpdateArrowMutationVariables>;

/**
 * __useUpdateArrowMutation__
 *
 * To run a mutation, you first call `useUpdateArrowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArrowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArrowMutation, { data, loading, error }] = useUpdateArrowMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      id: // value for 'id'
 *      sceneId: // value for 'sceneId'
 *      targetSceneId: // value for 'targetSceneId'
 *      batchId: // value for 'batchId'
 *      yaw: // value for 'yaw'
 *      pitch: // value for 'pitch'
 *   },
 * });
 */
export function useUpdateArrowMutation(baseOptions?: Apollo.MutationHookOptions<UpdateArrowMutation, UpdateArrowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateArrowMutation, UpdateArrowMutationVariables>(UpdateArrowDocument, options);
      }
export type UpdateArrowMutationHookResult = ReturnType<typeof useUpdateArrowMutation>;
export type UpdateArrowMutationResult = Apollo.MutationResult<UpdateArrowMutation>;
export type UpdateArrowMutationOptions = Apollo.BaseMutationOptions<UpdateArrowMutation, UpdateArrowMutationVariables>;
export const GetBimTokenDocument = gql`
    query GetBimToken($customer: String!, $project: String!, $scope: Scope!) {
  bimToken(tenant: {customer: $customer, project: $project, scope: $scope}) {
    token
    expiresIn
  }
}
    `;

/**
 * __useGetBimTokenQuery__
 *
 * To run a query within a React component, call `useGetBimTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBimTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBimTokenQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useGetBimTokenQuery(baseOptions: Apollo.QueryHookOptions<GetBimTokenQuery, GetBimTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBimTokenQuery, GetBimTokenQueryVariables>(GetBimTokenDocument, options);
      }
export function useGetBimTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBimTokenQuery, GetBimTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBimTokenQuery, GetBimTokenQueryVariables>(GetBimTokenDocument, options);
        }
export type GetBimTokenQueryHookResult = ReturnType<typeof useGetBimTokenQuery>;
export type GetBimTokenLazyQueryHookResult = ReturnType<typeof useGetBimTokenLazyQuery>;
export type GetBimTokenQueryResult = Apollo.QueryResult<GetBimTokenQuery, GetBimTokenQueryVariables>;
export const GetBimUrnDocument = gql`
    query GetBimUrn($customer: String!, $project: String!, $scope: Scope!) {
  bimUrns(tenant: {customer: $customer, project: $project, scope: $scope}) {
    id
    title
    urn
  }
}
    `;

/**
 * __useGetBimUrnQuery__
 *
 * To run a query within a React component, call `useGetBimUrnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBimUrnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBimUrnQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useGetBimUrnQuery(baseOptions: Apollo.QueryHookOptions<GetBimUrnQuery, GetBimUrnQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBimUrnQuery, GetBimUrnQueryVariables>(GetBimUrnDocument, options);
      }
export function useGetBimUrnLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBimUrnQuery, GetBimUrnQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBimUrnQuery, GetBimUrnQueryVariables>(GetBimUrnDocument, options);
        }
export type GetBimUrnQueryHookResult = ReturnType<typeof useGetBimUrnQuery>;
export type GetBimUrnLazyQueryHookResult = ReturnType<typeof useGetBimUrnLazyQuery>;
export type GetBimUrnQueryResult = Apollo.QueryResult<GetBimUrnQuery, GetBimUrnQueryVariables>;
export const UploadProgressFileDocument = gql`
    mutation UploadProgressFile($customer: String!, $project: String!, $scope: Scope!, $file: Upload!) {
  uploadProgressFile(
    tenant: {customer: $customer, project: $project, scope: $scope}
    progressFile: $file
  )
}
    `;
export type UploadProgressFileMutationFn = Apollo.MutationFunction<UploadProgressFileMutation, UploadProgressFileMutationVariables>;

/**
 * __useUploadProgressFileMutation__
 *
 * To run a mutation, you first call `useUploadProgressFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadProgressFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadProgressFileMutation, { data, loading, error }] = useUploadProgressFileMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadProgressFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadProgressFileMutation, UploadProgressFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadProgressFileMutation, UploadProgressFileMutationVariables>(UploadProgressFileDocument, options);
      }
export type UploadProgressFileMutationHookResult = ReturnType<typeof useUploadProgressFileMutation>;
export type UploadProgressFileMutationResult = Apollo.MutationResult<UploadProgressFileMutation>;
export type UploadProgressFileMutationOptions = Apollo.BaseMutationOptions<UploadProgressFileMutation, UploadProgressFileMutationVariables>;
export const UploadDatesFileDocument = gql`
    mutation UploadDatesFile($customer: String!, $project: String!, $scope: Scope!, $file: Upload!) {
  uploadDatesFile(
    tenant: {customer: $customer, project: $project, scope: $scope}
    datesFile: $file
  )
}
    `;
export type UploadDatesFileMutationFn = Apollo.MutationFunction<UploadDatesFileMutation, UploadDatesFileMutationVariables>;

/**
 * __useUploadDatesFileMutation__
 *
 * To run a mutation, you first call `useUploadDatesFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadDatesFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadDatesFileMutation, { data, loading, error }] = useUploadDatesFileMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadDatesFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadDatesFileMutation, UploadDatesFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadDatesFileMutation, UploadDatesFileMutationVariables>(UploadDatesFileDocument, options);
      }
export type UploadDatesFileMutationHookResult = ReturnType<typeof useUploadDatesFileMutation>;
export type UploadDatesFileMutationResult = Apollo.MutationResult<UploadDatesFileMutation>;
export type UploadDatesFileMutationOptions = Apollo.BaseMutationOptions<UploadDatesFileMutation, UploadDatesFileMutationVariables>;
export const FloorplanIdsDocument = gql`
    query floorplanIds($tenant: TenantInputType!) {
  floorplans(tenant: $tenant) {
    id
    space {
      id
    }
  }
}
    `;

/**
 * __useFloorplanIdsQuery__
 *
 * To run a query within a React component, call `useFloorplanIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFloorplanIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFloorplanIdsQuery({
 *   variables: {
 *      tenant: // value for 'tenant'
 *   },
 * });
 */
export function useFloorplanIdsQuery(baseOptions: Apollo.QueryHookOptions<FloorplanIdsQuery, FloorplanIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FloorplanIdsQuery, FloorplanIdsQueryVariables>(FloorplanIdsDocument, options);
      }
export function useFloorplanIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FloorplanIdsQuery, FloorplanIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FloorplanIdsQuery, FloorplanIdsQueryVariables>(FloorplanIdsDocument, options);
        }
export type FloorplanIdsQueryHookResult = ReturnType<typeof useFloorplanIdsQuery>;
export type FloorplanIdsLazyQueryHookResult = ReturnType<typeof useFloorplanIdsLazyQuery>;
export type FloorplanIdsQueryResult = Apollo.QueryResult<FloorplanIdsQuery, FloorplanIdsQueryVariables>;
export const FloorplanByIdDocument = gql`
    query floorplanById($tenant: TenantInputType!, $id: UUID!) {
  floorplans(tenant: $tenant, ids: [$id]) {
    id
    signedUrl
    originX
    originY
    width
    height
    mmScaleFactor
    bimOriginX
    bimOriginY
    bimOriginZ
    floorHeight
    angleToTrueNorth
    bimProjectId
    bimFloorIds
    space {
      id
      name
      ancestors {
        name
      }
    }
  }
}
    `;

/**
 * __useFloorplanByIdQuery__
 *
 * To run a query within a React component, call `useFloorplanByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFloorplanByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFloorplanByIdQuery({
 *   variables: {
 *      tenant: // value for 'tenant'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFloorplanByIdQuery(baseOptions: Apollo.QueryHookOptions<FloorplanByIdQuery, FloorplanByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FloorplanByIdQuery, FloorplanByIdQueryVariables>(FloorplanByIdDocument, options);
      }
export function useFloorplanByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FloorplanByIdQuery, FloorplanByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FloorplanByIdQuery, FloorplanByIdQueryVariables>(FloorplanByIdDocument, options);
        }
export type FloorplanByIdQueryHookResult = ReturnType<typeof useFloorplanByIdQuery>;
export type FloorplanByIdLazyQueryHookResult = ReturnType<typeof useFloorplanByIdLazyQuery>;
export type FloorplanByIdQueryResult = Apollo.QueryResult<FloorplanByIdQuery, FloorplanByIdQueryVariables>;
export const CreateFloorplanDocument = gql`
    mutation CreateFloorplan($tenant: TenantInputType!, $floorplan: CreateFloorplanInputType!) {
  createFloorplan(tenant: $tenant, floorplan: $floorplan) {
    id
    space {
      id
    }
  }
}
    `;
export type CreateFloorplanMutationFn = Apollo.MutationFunction<CreateFloorplanMutation, CreateFloorplanMutationVariables>;

/**
 * __useCreateFloorplanMutation__
 *
 * To run a mutation, you first call `useCreateFloorplanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFloorplanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFloorplanMutation, { data, loading, error }] = useCreateFloorplanMutation({
 *   variables: {
 *      tenant: // value for 'tenant'
 *      floorplan: // value for 'floorplan'
 *   },
 * });
 */
export function useCreateFloorplanMutation(baseOptions?: Apollo.MutationHookOptions<CreateFloorplanMutation, CreateFloorplanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFloorplanMutation, CreateFloorplanMutationVariables>(CreateFloorplanDocument, options);
      }
export type CreateFloorplanMutationHookResult = ReturnType<typeof useCreateFloorplanMutation>;
export type CreateFloorplanMutationResult = Apollo.MutationResult<CreateFloorplanMutation>;
export type CreateFloorplanMutationOptions = Apollo.BaseMutationOptions<CreateFloorplanMutation, CreateFloorplanMutationVariables>;
export const PatchFloorplanDocument = gql`
    mutation PatchFloorplan($tenant: TenantInputType!, $floorplan: PatchFloorplanInputType!) {
  patchFloorplan(tenant: $tenant, floorplan: $floorplan) {
    id
    space {
      id
    }
  }
}
    `;
export type PatchFloorplanMutationFn = Apollo.MutationFunction<PatchFloorplanMutation, PatchFloorplanMutationVariables>;

/**
 * __usePatchFloorplanMutation__
 *
 * To run a mutation, you first call `usePatchFloorplanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchFloorplanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchFloorplanMutation, { data, loading, error }] = usePatchFloorplanMutation({
 *   variables: {
 *      tenant: // value for 'tenant'
 *      floorplan: // value for 'floorplan'
 *   },
 * });
 */
export function usePatchFloorplanMutation(baseOptions?: Apollo.MutationHookOptions<PatchFloorplanMutation, PatchFloorplanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PatchFloorplanMutation, PatchFloorplanMutationVariables>(PatchFloorplanDocument, options);
      }
export type PatchFloorplanMutationHookResult = ReturnType<typeof usePatchFloorplanMutation>;
export type PatchFloorplanMutationResult = Apollo.MutationResult<PatchFloorplanMutation>;
export type PatchFloorplanMutationOptions = Apollo.BaseMutationOptions<PatchFloorplanMutation, PatchFloorplanMutationVariables>;
export const DeleteFoorplanDocument = gql`
    mutation DeleteFoorplan($tenant: TenantInputType!, $id: UUID!) {
  deleteFloorplan(tenant: $tenant, id: $id)
}
    `;
export type DeleteFoorplanMutationFn = Apollo.MutationFunction<DeleteFoorplanMutation, DeleteFoorplanMutationVariables>;

/**
 * __useDeleteFoorplanMutation__
 *
 * To run a mutation, you first call `useDeleteFoorplanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFoorplanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFoorplanMutation, { data, loading, error }] = useDeleteFoorplanMutation({
 *   variables: {
 *      tenant: // value for 'tenant'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFoorplanMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFoorplanMutation, DeleteFoorplanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFoorplanMutation, DeleteFoorplanMutationVariables>(DeleteFoorplanDocument, options);
      }
export type DeleteFoorplanMutationHookResult = ReturnType<typeof useDeleteFoorplanMutation>;
export type DeleteFoorplanMutationResult = Apollo.MutationResult<DeleteFoorplanMutation>;
export type DeleteFoorplanMutationOptions = Apollo.BaseMutationOptions<DeleteFoorplanMutation, DeleteFoorplanMutationVariables>;
export const GetHolidaysDocument = gql`
    query GetHolidays($customer: String!, $project: String!, $scope: Scope!) {
  holidays(tenant: {customer: $customer, project: $project, scope: $scope})
}
    `;

/**
 * __useGetHolidaysQuery__
 *
 * To run a query within a React component, call `useGetHolidaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHolidaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHolidaysQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useGetHolidaysQuery(baseOptions: Apollo.QueryHookOptions<GetHolidaysQuery, GetHolidaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHolidaysQuery, GetHolidaysQueryVariables>(GetHolidaysDocument, options);
      }
export function useGetHolidaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHolidaysQuery, GetHolidaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHolidaysQuery, GetHolidaysQueryVariables>(GetHolidaysDocument, options);
        }
export type GetHolidaysQueryHookResult = ReturnType<typeof useGetHolidaysQuery>;
export type GetHolidaysLazyQueryHookResult = ReturnType<typeof useGetHolidaysLazyQuery>;
export type GetHolidaysQueryResult = Apollo.QueryResult<GetHolidaysQuery, GetHolidaysQueryVariables>;
export const UploadHolidaysFileDocument = gql`
    mutation UploadHolidaysFile($customer: String!, $project: String!, $scope: Scope!, $file: Upload!) {
  uploadHolidaysFile(
    tenant: {customer: $customer, project: $project, scope: $scope}
    holidaysFile: $file
  )
}
    `;
export type UploadHolidaysFileMutationFn = Apollo.MutationFunction<UploadHolidaysFileMutation, UploadHolidaysFileMutationVariables>;

/**
 * __useUploadHolidaysFileMutation__
 *
 * To run a mutation, you first call `useUploadHolidaysFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadHolidaysFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadHolidaysFileMutation, { data, loading, error }] = useUploadHolidaysFileMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadHolidaysFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadHolidaysFileMutation, UploadHolidaysFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadHolidaysFileMutation, UploadHolidaysFileMutationVariables>(UploadHolidaysFileDocument, options);
      }
export type UploadHolidaysFileMutationHookResult = ReturnType<typeof useUploadHolidaysFileMutation>;
export type UploadHolidaysFileMutationResult = Apollo.MutationResult<UploadHolidaysFileMutation>;
export type UploadHolidaysFileMutationOptions = Apollo.BaseMutationOptions<UploadHolidaysFileMutation, UploadHolidaysFileMutationVariables>;
export const DeleteAllHolidaysDocument = gql`
    mutation DeleteAllHolidays($customer: String!, $project: String!, $scope: Scope!) {
  deleteAllHolidays(
    tenant: {customer: $customer, project: $project, scope: $scope}
  )
}
    `;
export type DeleteAllHolidaysMutationFn = Apollo.MutationFunction<DeleteAllHolidaysMutation, DeleteAllHolidaysMutationVariables>;

/**
 * __useDeleteAllHolidaysMutation__
 *
 * To run a mutation, you first call `useDeleteAllHolidaysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAllHolidaysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAllHolidaysMutation, { data, loading, error }] = useDeleteAllHolidaysMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useDeleteAllHolidaysMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAllHolidaysMutation, DeleteAllHolidaysMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAllHolidaysMutation, DeleteAllHolidaysMutationVariables>(DeleteAllHolidaysDocument, options);
      }
export type DeleteAllHolidaysMutationHookResult = ReturnType<typeof useDeleteAllHolidaysMutation>;
export type DeleteAllHolidaysMutationResult = Apollo.MutationResult<DeleteAllHolidaysMutation>;
export type DeleteAllHolidaysMutationOptions = Apollo.BaseMutationOptions<DeleteAllHolidaysMutation, DeleteAllHolidaysMutationVariables>;
export const GetProjectConfigDocument = gql`
    query GetProjectConfig($customer: String!, $project: String!) {
  projects(customer: $customer, project: $project) {
    customer
    project
    config {
      displayName
      defaultLanguage
      startDate
      isReleased
    }
  }
}
    `;

/**
 * __useGetProjectConfigQuery__
 *
 * To run a query within a React component, call `useGetProjectConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectConfigQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *   },
 * });
 */
export function useGetProjectConfigQuery(baseOptions: Apollo.QueryHookOptions<GetProjectConfigQuery, GetProjectConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectConfigQuery, GetProjectConfigQueryVariables>(GetProjectConfigDocument, options);
      }
export function useGetProjectConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectConfigQuery, GetProjectConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectConfigQuery, GetProjectConfigQueryVariables>(GetProjectConfigDocument, options);
        }
export type GetProjectConfigQueryHookResult = ReturnType<typeof useGetProjectConfigQuery>;
export type GetProjectConfigLazyQueryHookResult = ReturnType<typeof useGetProjectConfigLazyQuery>;
export type GetProjectConfigQueryResult = Apollo.QueryResult<GetProjectConfigQuery, GetProjectConfigQueryVariables>;
export const UpdateIsReleasedProjectConfigDocument = gql`
    mutation UpdateIsReleasedProjectConfig($customer: String!, $project: String!, $isReleased: Boolean!) {
  updateIsReleasedProjectConfig(
    input: {customer: $customer, project: $project, isReleased: $isReleased}
  ) {
    isReleased
  }
}
    `;
export type UpdateIsReleasedProjectConfigMutationFn = Apollo.MutationFunction<UpdateIsReleasedProjectConfigMutation, UpdateIsReleasedProjectConfigMutationVariables>;

/**
 * __useUpdateIsReleasedProjectConfigMutation__
 *
 * To run a mutation, you first call `useUpdateIsReleasedProjectConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIsReleasedProjectConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIsReleasedProjectConfigMutation, { data, loading, error }] = useUpdateIsReleasedProjectConfigMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      isReleased: // value for 'isReleased'
 *   },
 * });
 */
export function useUpdateIsReleasedProjectConfigMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIsReleasedProjectConfigMutation, UpdateIsReleasedProjectConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIsReleasedProjectConfigMutation, UpdateIsReleasedProjectConfigMutationVariables>(UpdateIsReleasedProjectConfigDocument, options);
      }
export type UpdateIsReleasedProjectConfigMutationHookResult = ReturnType<typeof useUpdateIsReleasedProjectConfigMutation>;
export type UpdateIsReleasedProjectConfigMutationResult = Apollo.MutationResult<UpdateIsReleasedProjectConfigMutation>;
export type UpdateIsReleasedProjectConfigMutationOptions = Apollo.BaseMutationOptions<UpdateIsReleasedProjectConfigMutation, UpdateIsReleasedProjectConfigMutationVariables>;
export const GetSequenceDetailsDocument = gql`
    query GetSequenceDetails($tenant: TenantInputType!, $floorPlanId: String!) {
  getSequenceDetails(tenant: $tenant, floorPlanId: $floorPlanId) {
    x_mm
    y_mm
    sequencenumber
  }
}
    `;

/**
 * __useGetSequenceDetailsQuery__
 *
 * To run a query within a React component, call `useGetSequenceDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSequenceDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSequenceDetailsQuery({
 *   variables: {
 *      tenant: // value for 'tenant'
 *      floorPlanId: // value for 'floorPlanId'
 *   },
 * });
 */
export function useGetSequenceDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetSequenceDetailsQuery, GetSequenceDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSequenceDetailsQuery, GetSequenceDetailsQueryVariables>(GetSequenceDetailsDocument, options);
      }
export function useGetSequenceDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSequenceDetailsQuery, GetSequenceDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSequenceDetailsQuery, GetSequenceDetailsQueryVariables>(GetSequenceDetailsDocument, options);
        }
export type GetSequenceDetailsQueryHookResult = ReturnType<typeof useGetSequenceDetailsQuery>;
export type GetSequenceDetailsLazyQueryHookResult = ReturnType<typeof useGetSequenceDetailsLazyQuery>;
export type GetSequenceDetailsQueryResult = Apollo.QueryResult<GetSequenceDetailsQuery, GetSequenceDetailsQueryVariables>;
export const GetTaskForTreeNodeDocument = gql`
    query GetTaskForTreeNode($customer: String!, $project: String!, $scope: Scope!, $taskId: UUID!) {
  task(
    tenant: {customer: $customer, project: $project, scope: $scope}
    id: $taskId
  ) {
    ...Task
  }
}
    ${TaskFragmentDoc}`;

/**
 * __useGetTaskForTreeNodeQuery__
 *
 * To run a query within a React component, call `useGetTaskForTreeNodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskForTreeNodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskForTreeNodeQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetTaskForTreeNodeQuery(baseOptions: Apollo.QueryHookOptions<GetTaskForTreeNodeQuery, GetTaskForTreeNodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTaskForTreeNodeQuery, GetTaskForTreeNodeQueryVariables>(GetTaskForTreeNodeDocument, options);
      }
export function useGetTaskForTreeNodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTaskForTreeNodeQuery, GetTaskForTreeNodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTaskForTreeNodeQuery, GetTaskForTreeNodeQueryVariables>(GetTaskForTreeNodeDocument, options);
        }
export type GetTaskForTreeNodeQueryHookResult = ReturnType<typeof useGetTaskForTreeNodeQuery>;
export type GetTaskForTreeNodeLazyQueryHookResult = ReturnType<typeof useGetTaskForTreeNodeLazyQuery>;
export type GetTaskForTreeNodeQueryResult = Apollo.QueryResult<GetTaskForTreeNodeQuery, GetTaskForTreeNodeQueryVariables>;
export const GetComponentForTreeNodeDocument = gql`
    query GetComponentForTreeNode($customer: String!, $project: String!, $scope: Scope!, $componentId: UUID!) {
  component(
    tenant: {customer: $customer, project: $project, scope: $scope}
    id: $componentId
  ) {
    ...ComponentInTaskTree
  }
}
    ${ComponentInTaskTreeFragmentDoc}`;

/**
 * __useGetComponentForTreeNodeQuery__
 *
 * To run a query within a React component, call `useGetComponentForTreeNodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComponentForTreeNodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComponentForTreeNodeQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      componentId: // value for 'componentId'
 *   },
 * });
 */
export function useGetComponentForTreeNodeQuery(baseOptions: Apollo.QueryHookOptions<GetComponentForTreeNodeQuery, GetComponentForTreeNodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetComponentForTreeNodeQuery, GetComponentForTreeNodeQueryVariables>(GetComponentForTreeNodeDocument, options);
      }
export function useGetComponentForTreeNodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetComponentForTreeNodeQuery, GetComponentForTreeNodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetComponentForTreeNodeQuery, GetComponentForTreeNodeQueryVariables>(GetComponentForTreeNodeDocument, options);
        }
export type GetComponentForTreeNodeQueryHookResult = ReturnType<typeof useGetComponentForTreeNodeQuery>;
export type GetComponentForTreeNodeLazyQueryHookResult = ReturnType<typeof useGetComponentForTreeNodeLazyQuery>;
export type GetComponentForTreeNodeQueryResult = Apollo.QueryResult<GetComponentForTreeNodeQuery, GetComponentForTreeNodeQueryVariables>;
export const GetTasksByParentPlanDocument = gql`
    query GetTasksByParentPlan($customer: String!, $project: String!, $scope: Scope!, $planId: UUID!) {
  tasksByParentPlan(
    tenant: {customer: $customer, project: $project, scope: $scope}
    planId: $planId
  ) {
    parentTask {
      id
    }
    ...Task
    childComponents {
      ...ComponentInTaskTree
    }
  }
}
    ${TaskFragmentDoc}
${ComponentInTaskTreeFragmentDoc}`;

/**
 * __useGetTasksByParentPlanQuery__
 *
 * To run a query within a React component, call `useGetTasksByParentPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTasksByParentPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTasksByParentPlanQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useGetTasksByParentPlanQuery(baseOptions: Apollo.QueryHookOptions<GetTasksByParentPlanQuery, GetTasksByParentPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTasksByParentPlanQuery, GetTasksByParentPlanQueryVariables>(GetTasksByParentPlanDocument, options);
      }
export function useGetTasksByParentPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTasksByParentPlanQuery, GetTasksByParentPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTasksByParentPlanQuery, GetTasksByParentPlanQueryVariables>(GetTasksByParentPlanDocument, options);
        }
export type GetTasksByParentPlanQueryHookResult = ReturnType<typeof useGetTasksByParentPlanQuery>;
export type GetTasksByParentPlanLazyQueryHookResult = ReturnType<typeof useGetTasksByParentPlanLazyQuery>;
export type GetTasksByParentPlanQueryResult = Apollo.QueryResult<GetTasksByParentPlanQuery, GetTasksByParentPlanQueryVariables>;
export const GetAllPlansDocument = gql`
    query GetAllPlans($customer: String!, $project: String!, $scope: Scope!) {
  plans(tenant: {customer: $customer, project: $project, scope: $scope}) {
    name
    id
  }
}
    `;

/**
 * __useGetAllPlansQuery__
 *
 * To run a query within a React component, call `useGetAllPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPlansQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useGetAllPlansQuery(baseOptions: Apollo.QueryHookOptions<GetAllPlansQuery, GetAllPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllPlansQuery, GetAllPlansQueryVariables>(GetAllPlansDocument, options);
      }
export function useGetAllPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllPlansQuery, GetAllPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllPlansQuery, GetAllPlansQueryVariables>(GetAllPlansDocument, options);
        }
export type GetAllPlansQueryHookResult = ReturnType<typeof useGetAllPlansQuery>;
export type GetAllPlansLazyQueryHookResult = ReturnType<typeof useGetAllPlansLazyQuery>;
export type GetAllPlansQueryResult = Apollo.QueryResult<GetAllPlansQuery, GetAllPlansQueryVariables>;
export const CreatePlanDocument = gql`
    mutation CreatePlan($customer: String!, $project: String!, $scope: Scope!, $name: String!) {
  createPlan(
    tenant: {customer: $customer, project: $project, scope: $scope}
    planName: $name
  ) {
    id
    name
    startDate
  }
}
    `;
export type CreatePlanMutationFn = Apollo.MutationFunction<CreatePlanMutation, CreatePlanMutationVariables>;

/**
 * __useCreatePlanMutation__
 *
 * To run a mutation, you first call `useCreatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlanMutation, { data, loading, error }] = useCreatePlanMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreatePlanMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlanMutation, CreatePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePlanMutation, CreatePlanMutationVariables>(CreatePlanDocument, options);
      }
export type CreatePlanMutationHookResult = ReturnType<typeof useCreatePlanMutation>;
export type CreatePlanMutationResult = Apollo.MutationResult<CreatePlanMutation>;
export type CreatePlanMutationOptions = Apollo.BaseMutationOptions<CreatePlanMutation, CreatePlanMutationVariables>;
export const UpdatePlanDocument = gql`
    mutation UpdatePlan($customer: String!, $project: String!, $scope: Scope!, $planId: UUID!, $name: String!, $startDate: Date) {
  updatePlan(
    tenant: {customer: $customer, project: $project, scope: $scope}
    id: $planId
    planName: $name
    startDate: $startDate
  ) {
    id
    name
    startDate
  }
}
    `;
export type UpdatePlanMutationFn = Apollo.MutationFunction<UpdatePlanMutation, UpdatePlanMutationVariables>;

/**
 * __useUpdatePlanMutation__
 *
 * To run a mutation, you first call `useUpdatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanMutation, { data, loading, error }] = useUpdatePlanMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      planId: // value for 'planId'
 *      name: // value for 'name'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useUpdatePlanMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlanMutation, UpdatePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePlanMutation, UpdatePlanMutationVariables>(UpdatePlanDocument, options);
      }
export type UpdatePlanMutationHookResult = ReturnType<typeof useUpdatePlanMutation>;
export type UpdatePlanMutationResult = Apollo.MutationResult<UpdatePlanMutation>;
export type UpdatePlanMutationOptions = Apollo.BaseMutationOptions<UpdatePlanMutation, UpdatePlanMutationVariables>;
export const DeletePlanDocument = gql`
    mutation DeletePlan($customer: String!, $project: String!, $scope: Scope!, $planId: UUID!) {
  deletePlan(
    tenant: {customer: $customer, project: $project, scope: $scope}
    id: $planId
  )
}
    `;
export type DeletePlanMutationFn = Apollo.MutationFunction<DeletePlanMutation, DeletePlanMutationVariables>;

/**
 * __useDeletePlanMutation__
 *
 * To run a mutation, you first call `useDeletePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlanMutation, { data, loading, error }] = useDeletePlanMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useDeletePlanMutation(baseOptions?: Apollo.MutationHookOptions<DeletePlanMutation, DeletePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePlanMutation, DeletePlanMutationVariables>(DeletePlanDocument, options);
      }
export type DeletePlanMutationHookResult = ReturnType<typeof useDeletePlanMutation>;
export type DeletePlanMutationResult = Apollo.MutationResult<DeletePlanMutation>;
export type DeletePlanMutationOptions = Apollo.BaseMutationOptions<DeletePlanMutation, DeletePlanMutationVariables>;
export const CreateTaskDocument = gql`
    mutation CreateTask($customer: String!, $project: String!, $scope: Scope!, $name: String!, $planId: UUID!, $parentTaskId: UUID) {
  createTask(
    tenant: {customer: $customer, project: $project, scope: $scope}
    task: {name: $name, planId: $planId, parentTaskId: $parentTaskId}
  ) {
    ...Task
  }
}
    ${TaskFragmentDoc}`;
export type CreateTaskMutationFn = Apollo.MutationFunction<CreateTaskMutation, CreateTaskMutationVariables>;

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      name: // value for 'name'
 *      planId: // value for 'planId'
 *      parentTaskId: // value for 'parentTaskId'
 *   },
 * });
 */
export function useCreateTaskMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaskMutation, CreateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(CreateTaskDocument, options);
      }
export type CreateTaskMutationHookResult = ReturnType<typeof useCreateTaskMutation>;
export type CreateTaskMutationResult = Apollo.MutationResult<CreateTaskMutation>;
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<CreateTaskMutation, CreateTaskMutationVariables>;
export const UpdateTasksDocument = gql`
    mutation UpdateTasks($customer: String!, $project: String!, $scope: Scope!, $tasks: [UpdateTaskInputType!]!) {
  updateTasks(
    tenant: {customer: $customer, project: $project, scope: $scope}
    tasks: $tasks
  ) {
    ...Task
  }
}
    ${TaskFragmentDoc}`;
export type UpdateTasksMutationFn = Apollo.MutationFunction<UpdateTasksMutation, UpdateTasksMutationVariables>;

/**
 * __useUpdateTasksMutation__
 *
 * To run a mutation, you first call `useUpdateTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTasksMutation, { data, loading, error }] = useUpdateTasksMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      tasks: // value for 'tasks'
 *   },
 * });
 */
export function useUpdateTasksMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTasksMutation, UpdateTasksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTasksMutation, UpdateTasksMutationVariables>(UpdateTasksDocument, options);
      }
export type UpdateTasksMutationHookResult = ReturnType<typeof useUpdateTasksMutation>;
export type UpdateTasksMutationResult = Apollo.MutationResult<UpdateTasksMutation>;
export type UpdateTasksMutationOptions = Apollo.BaseMutationOptions<UpdateTasksMutation, UpdateTasksMutationVariables>;
export const UploadPlansTreeDocument = gql`
    mutation UploadPlansTree($customer: String!, $project: String!, $scope: Scope!, $file: Upload!, $planId: UUID!) {
  uploadPlansTreeFile(
    tenant: {customer: $customer, project: $project, scope: $scope}
    plansTreeFile: $file
    planId: $planId
  )
}
    `;
export type UploadPlansTreeMutationFn = Apollo.MutationFunction<UploadPlansTreeMutation, UploadPlansTreeMutationVariables>;

/**
 * __useUploadPlansTreeMutation__
 *
 * To run a mutation, you first call `useUploadPlansTreeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadPlansTreeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadPlansTreeMutation, { data, loading, error }] = useUploadPlansTreeMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      file: // value for 'file'
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useUploadPlansTreeMutation(baseOptions?: Apollo.MutationHookOptions<UploadPlansTreeMutation, UploadPlansTreeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadPlansTreeMutation, UploadPlansTreeMutationVariables>(UploadPlansTreeDocument, options);
      }
export type UploadPlansTreeMutationHookResult = ReturnType<typeof useUploadPlansTreeMutation>;
export type UploadPlansTreeMutationResult = Apollo.MutationResult<UploadPlansTreeMutation>;
export type UploadPlansTreeMutationOptions = Apollo.BaseMutationOptions<UploadPlansTreeMutation, UploadPlansTreeMutationVariables>;
export const GetSpaceWithMappingsDocument = gql`
    query GetSpaceWithMappings($customer: String!, $project: String!, $scope: Scope!, $spaceId: UUID!, $planId: UUID!) {
  spacesByIds(
    tenant: {customer: $customer, project: $project, scope: $scope}
    ids: [$spaceId]
  ) {
    ...SpaceWithMappings
    subSpaces {
      ...SpaceWithMappings
    }
  }
}
    ${SpaceWithMappingsFragmentDoc}`;

/**
 * __useGetSpaceWithMappingsQuery__
 *
 * To run a query within a React component, call `useGetSpaceWithMappingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpaceWithMappingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpaceWithMappingsQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      spaceId: // value for 'spaceId'
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useGetSpaceWithMappingsQuery(baseOptions: Apollo.QueryHookOptions<GetSpaceWithMappingsQuery, GetSpaceWithMappingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSpaceWithMappingsQuery, GetSpaceWithMappingsQueryVariables>(GetSpaceWithMappingsDocument, options);
      }
export function useGetSpaceWithMappingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSpaceWithMappingsQuery, GetSpaceWithMappingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSpaceWithMappingsQuery, GetSpaceWithMappingsQueryVariables>(GetSpaceWithMappingsDocument, options);
        }
export type GetSpaceWithMappingsQueryHookResult = ReturnType<typeof useGetSpaceWithMappingsQuery>;
export type GetSpaceWithMappingsLazyQueryHookResult = ReturnType<typeof useGetSpaceWithMappingsLazyQuery>;
export type GetSpaceWithMappingsQueryResult = Apollo.QueryResult<GetSpaceWithMappingsQuery, GetSpaceWithMappingsQueryVariables>;
export const GetSpaceByIdDocument = gql`
    query GetSpaceById($customer: String!, $project: String!, $scope: Scope!, $spaceId: UUID!, $planId: UUID!, $includeSelf: Boolean!, $includeComponentsUnderSpace: Boolean = false) {
  spacesByIds(
    tenant: {customer: $customer, project: $project, scope: $scope}
    ids: [$spaceId]
  ) {
    id
    name
    subSpaces {
      id
      name
      guiIndex
    }
    componentsUnderSpace(planId: $planId) @include(if: $includeComponentsUnderSpace) {
      ...ComponentWithAncestors
    }
  }
}
    ${ComponentWithAncestorsFragmentDoc}`;

/**
 * __useGetSpaceByIdQuery__
 *
 * To run a query within a React component, call `useGetSpaceByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpaceByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpaceByIdQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      spaceId: // value for 'spaceId'
 *      planId: // value for 'planId'
 *      includeSelf: // value for 'includeSelf'
 *      includeComponentsUnderSpace: // value for 'includeComponentsUnderSpace'
 *   },
 * });
 */
export function useGetSpaceByIdQuery(baseOptions: Apollo.QueryHookOptions<GetSpaceByIdQuery, GetSpaceByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSpaceByIdQuery, GetSpaceByIdQueryVariables>(GetSpaceByIdDocument, options);
      }
export function useGetSpaceByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSpaceByIdQuery, GetSpaceByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSpaceByIdQuery, GetSpaceByIdQueryVariables>(GetSpaceByIdDocument, options);
        }
export type GetSpaceByIdQueryHookResult = ReturnType<typeof useGetSpaceByIdQuery>;
export type GetSpaceByIdLazyQueryHookResult = ReturnType<typeof useGetSpaceByIdLazyQuery>;
export type GetSpaceByIdQueryResult = Apollo.QueryResult<GetSpaceByIdQuery, GetSpaceByIdQueryVariables>;
export const GetComponentsForPlanDocument = gql`
    query GetComponentsForPlan($customer: String!, $project: String!, $scope: Scope!, $planId: UUID!, $includeSelf: Boolean!) {
  plan(
    tenant: {customer: $customer, project: $project, scope: $scope}
    id: $planId
  ) {
    components {
      ...ComponentWithAncestors
    }
  }
}
    ${ComponentWithAncestorsFragmentDoc}`;

/**
 * __useGetComponentsForPlanQuery__
 *
 * To run a query within a React component, call `useGetComponentsForPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComponentsForPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComponentsForPlanQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      planId: // value for 'planId'
 *      includeSelf: // value for 'includeSelf'
 *   },
 * });
 */
export function useGetComponentsForPlanQuery(baseOptions: Apollo.QueryHookOptions<GetComponentsForPlanQuery, GetComponentsForPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetComponentsForPlanQuery, GetComponentsForPlanQueryVariables>(GetComponentsForPlanDocument, options);
      }
export function useGetComponentsForPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetComponentsForPlanQuery, GetComponentsForPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetComponentsForPlanQuery, GetComponentsForPlanQueryVariables>(GetComponentsForPlanDocument, options);
        }
export type GetComponentsForPlanQueryHookResult = ReturnType<typeof useGetComponentsForPlanQuery>;
export type GetComponentsForPlanLazyQueryHookResult = ReturnType<typeof useGetComponentsForPlanLazyQuery>;
export type GetComponentsForPlanQueryResult = Apollo.QueryResult<GetComponentsForPlanQuery, GetComponentsForPlanQueryVariables>;
export const GetComponentProgressesDocument = gql`
    query GetComponentProgresses($customer: String!, $project: String!, $scope: Scope!, $planId: UUID!, $componentIds: [UUID!]!, $spaceIds: [UUID!]!, $batchIds: [UUID!]!) {
  componentsByIds(
    tenant: {customer: $customer, project: $project, scope: $scope}
    componentIds: $componentIds
  ) {
    id
    aggregatedProgresses(
      planId: $planId
      spaceIds: $spaceIds
      batchIds: $batchIds
      forceSlowAggs: true
    ) {
      progress
      status
      space {
        id
      }
      batch {
        id
      }
    }
  }
}
    `;

/**
 * __useGetComponentProgressesQuery__
 *
 * To run a query within a React component, call `useGetComponentProgressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComponentProgressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComponentProgressesQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      planId: // value for 'planId'
 *      componentIds: // value for 'componentIds'
 *      spaceIds: // value for 'spaceIds'
 *      batchIds: // value for 'batchIds'
 *   },
 * });
 */
export function useGetComponentProgressesQuery(baseOptions: Apollo.QueryHookOptions<GetComponentProgressesQuery, GetComponentProgressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetComponentProgressesQuery, GetComponentProgressesQueryVariables>(GetComponentProgressesDocument, options);
      }
export function useGetComponentProgressesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetComponentProgressesQuery, GetComponentProgressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetComponentProgressesQuery, GetComponentProgressesQueryVariables>(GetComponentProgressesDocument, options);
        }
export type GetComponentProgressesQueryHookResult = ReturnType<typeof useGetComponentProgressesQuery>;
export type GetComponentProgressesLazyQueryHookResult = ReturnType<typeof useGetComponentProgressesLazyQuery>;
export type GetComponentProgressesQueryResult = Apollo.QueryResult<GetComponentProgressesQuery, GetComponentProgressesQueryVariables>;
export const GetBatchesDocument = gql`
    query GetBatches($customer: String!, $project: String!, $scope: Scope!) {
  batches(tenant: {customer: $customer, project: $project, scope: $scope}) {
    id
    timestamp
    weekNumber
  }
}
    `;

/**
 * __useGetBatchesQuery__
 *
 * To run a query within a React component, call `useGetBatchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBatchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBatchesQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useGetBatchesQuery(baseOptions: Apollo.QueryHookOptions<GetBatchesQuery, GetBatchesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBatchesQuery, GetBatchesQueryVariables>(GetBatchesDocument, options);
      }
export function useGetBatchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBatchesQuery, GetBatchesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBatchesQuery, GetBatchesQueryVariables>(GetBatchesDocument, options);
        }
export type GetBatchesQueryHookResult = ReturnType<typeof useGetBatchesQuery>;
export type GetBatchesLazyQueryHookResult = ReturnType<typeof useGetBatchesLazyQuery>;
export type GetBatchesQueryResult = Apollo.QueryResult<GetBatchesQuery, GetBatchesQueryVariables>;
export const UpdateRawMappingProgressesDocument = gql`
    mutation UpdateRawMappingProgresses($customer: String!, $project: String!, $scope: Scope!, $batchId: UUID!, $componentId: UUID!, $spaceId: UUID!, $progress: Int!) {
  updateRawMappingProgresses(
    tenant: {customer: $customer, project: $project, scope: $scope}
    batchId: $batchId
    componentId: $componentId
    spaceId: $spaceId
    progress: $progress
  )
}
    `;
export type UpdateRawMappingProgressesMutationFn = Apollo.MutationFunction<UpdateRawMappingProgressesMutation, UpdateRawMappingProgressesMutationVariables>;

/**
 * __useUpdateRawMappingProgressesMutation__
 *
 * To run a mutation, you first call `useUpdateRawMappingProgressesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRawMappingProgressesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRawMappingProgressesMutation, { data, loading, error }] = useUpdateRawMappingProgressesMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      batchId: // value for 'batchId'
 *      componentId: // value for 'componentId'
 *      spaceId: // value for 'spaceId'
 *      progress: // value for 'progress'
 *   },
 * });
 */
export function useUpdateRawMappingProgressesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRawMappingProgressesMutation, UpdateRawMappingProgressesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRawMappingProgressesMutation, UpdateRawMappingProgressesMutationVariables>(UpdateRawMappingProgressesDocument, options);
      }
export type UpdateRawMappingProgressesMutationHookResult = ReturnType<typeof useUpdateRawMappingProgressesMutation>;
export type UpdateRawMappingProgressesMutationResult = Apollo.MutationResult<UpdateRawMappingProgressesMutation>;
export type UpdateRawMappingProgressesMutationOptions = Apollo.BaseMutationOptions<UpdateRawMappingProgressesMutation, UpdateRawMappingProgressesMutationVariables>;
export const UpdateEnabledDisabledMappingsDocument = gql`
    mutation UpdateEnabledDisabledMappings($customer: String!, $project: String!, $scope: Scope!, $mappings: [UpdateMappingInputType!]!) {
  updateMappings(
    tenant: {customer: $customer, project: $project, scope: $scope}
    mappings: $mappings
  ) {
    id
    disabled
    component {
      id
    }
    space {
      id
    }
  }
}
    `;
export type UpdateEnabledDisabledMappingsMutationFn = Apollo.MutationFunction<UpdateEnabledDisabledMappingsMutation, UpdateEnabledDisabledMappingsMutationVariables>;

/**
 * __useUpdateEnabledDisabledMappingsMutation__
 *
 * To run a mutation, you first call `useUpdateEnabledDisabledMappingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEnabledDisabledMappingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEnabledDisabledMappingsMutation, { data, loading, error }] = useUpdateEnabledDisabledMappingsMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      mappings: // value for 'mappings'
 *   },
 * });
 */
export function useUpdateEnabledDisabledMappingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEnabledDisabledMappingsMutation, UpdateEnabledDisabledMappingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEnabledDisabledMappingsMutation, UpdateEnabledDisabledMappingsMutationVariables>(UpdateEnabledDisabledMappingsDocument, options);
      }
export type UpdateEnabledDisabledMappingsMutationHookResult = ReturnType<typeof useUpdateEnabledDisabledMappingsMutation>;
export type UpdateEnabledDisabledMappingsMutationResult = Apollo.MutationResult<UpdateEnabledDisabledMappingsMutation>;
export type UpdateEnabledDisabledMappingsMutationOptions = Apollo.BaseMutationOptions<UpdateEnabledDisabledMappingsMutation, UpdateEnabledDisabledMappingsMutationVariables>;
export const CreateMappingDocument = gql`
    mutation CreateMapping($customer: String!, $project: String!, $scope: Scope!, $componentId: UUID!, $spaceId: UUID!) {
  createMappings(
    tenant: {customer: $customer, project: $project, scope: $scope}
    componentIds: [$componentId]
    spaceIds: [$spaceId]
  ) {
    id
    space {
      id
      name
    }
    component {
      id
      name
    }
    disabled
  }
}
    `;
export type CreateMappingMutationFn = Apollo.MutationFunction<CreateMappingMutation, CreateMappingMutationVariables>;

/**
 * __useCreateMappingMutation__
 *
 * To run a mutation, you first call `useCreateMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMappingMutation, { data, loading, error }] = useCreateMappingMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      componentId: // value for 'componentId'
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useCreateMappingMutation(baseOptions?: Apollo.MutationHookOptions<CreateMappingMutation, CreateMappingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMappingMutation, CreateMappingMutationVariables>(CreateMappingDocument, options);
      }
export type CreateMappingMutationHookResult = ReturnType<typeof useCreateMappingMutation>;
export type CreateMappingMutationResult = Apollo.MutationResult<CreateMappingMutation>;
export type CreateMappingMutationOptions = Apollo.BaseMutationOptions<CreateMappingMutation, CreateMappingMutationVariables>;
export const DeleteMappingDocument = gql`
    mutation DeleteMapping($customer: String!, $project: String!, $scope: Scope!, $mappingId: UUID!) {
  deleteMappings(
    tenant: {customer: $customer, project: $project, scope: $scope}
    mappingIds: [$mappingId]
  )
}
    `;
export type DeleteMappingMutationFn = Apollo.MutationFunction<DeleteMappingMutation, DeleteMappingMutationVariables>;

/**
 * __useDeleteMappingMutation__
 *
 * To run a mutation, you first call `useDeleteMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMappingMutation, { data, loading, error }] = useDeleteMappingMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      mappingId: // value for 'mappingId'
 *   },
 * });
 */
export function useDeleteMappingMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMappingMutation, DeleteMappingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMappingMutation, DeleteMappingMutationVariables>(DeleteMappingDocument, options);
      }
export type DeleteMappingMutationHookResult = ReturnType<typeof useDeleteMappingMutation>;
export type DeleteMappingMutationResult = Apollo.MutationResult<DeleteMappingMutation>;
export type DeleteMappingMutationOptions = Apollo.BaseMutationOptions<DeleteMappingMutation, DeleteMappingMutationVariables>;
export const ReleaseProjectDocument = gql`
    mutation ReleaseProject($customer: String!, $project: String!, $scope: Scope!) {
  releaseDataToExternal(
    tenant: {customer: $customer, project: $project, scope: $scope}
  )
}
    `;
export type ReleaseProjectMutationFn = Apollo.MutationFunction<ReleaseProjectMutation, ReleaseProjectMutationVariables>;

/**
 * __useReleaseProjectMutation__
 *
 * To run a mutation, you first call `useReleaseProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReleaseProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [releaseProjectMutation, { data, loading, error }] = useReleaseProjectMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useReleaseProjectMutation(baseOptions?: Apollo.MutationHookOptions<ReleaseProjectMutation, ReleaseProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReleaseProjectMutation, ReleaseProjectMutationVariables>(ReleaseProjectDocument, options);
      }
export type ReleaseProjectMutationHookResult = ReturnType<typeof useReleaseProjectMutation>;
export type ReleaseProjectMutationResult = Apollo.MutationResult<ReleaseProjectMutation>;
export type ReleaseProjectMutationOptions = Apollo.BaseMutationOptions<ReleaseProjectMutation, ReleaseProjectMutationVariables>;
export const GetAvailableProjectsDocument = gql`
    query GetAvailableProjects {
  projects {
    customer
    project
    scopes
  }
}
    `;

/**
 * __useGetAvailableProjectsQuery__
 *
 * To run a query within a React component, call `useGetAvailableProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAvailableProjectsQuery(baseOptions?: Apollo.QueryHookOptions<GetAvailableProjectsQuery, GetAvailableProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAvailableProjectsQuery, GetAvailableProjectsQueryVariables>(GetAvailableProjectsDocument, options);
      }
export function useGetAvailableProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAvailableProjectsQuery, GetAvailableProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAvailableProjectsQuery, GetAvailableProjectsQueryVariables>(GetAvailableProjectsDocument, options);
        }
export type GetAvailableProjectsQueryHookResult = ReturnType<typeof useGetAvailableProjectsQuery>;
export type GetAvailableProjectsLazyQueryHookResult = ReturnType<typeof useGetAvailableProjectsLazyQuery>;
export type GetAvailableProjectsQueryResult = Apollo.QueryResult<GetAvailableProjectsQuery, GetAvailableProjectsQueryVariables>;
export const GetEarliestTimestampOnWorkQueueDocument = gql`
    query GetEarliestTimestampOnWorkQueue($tenant: TenantInputType!) {
  earliestTimestampOnWorkQueue(tenant: $tenant)
}
    `;

/**
 * __useGetEarliestTimestampOnWorkQueueQuery__
 *
 * To run a query within a React component, call `useGetEarliestTimestampOnWorkQueueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEarliestTimestampOnWorkQueueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEarliestTimestampOnWorkQueueQuery({
 *   variables: {
 *      tenant: // value for 'tenant'
 *   },
 * });
 */
export function useGetEarliestTimestampOnWorkQueueQuery(baseOptions: Apollo.QueryHookOptions<GetEarliestTimestampOnWorkQueueQuery, GetEarliestTimestampOnWorkQueueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEarliestTimestampOnWorkQueueQuery, GetEarliestTimestampOnWorkQueueQueryVariables>(GetEarliestTimestampOnWorkQueueDocument, options);
      }
export function useGetEarliestTimestampOnWorkQueueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEarliestTimestampOnWorkQueueQuery, GetEarliestTimestampOnWorkQueueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEarliestTimestampOnWorkQueueQuery, GetEarliestTimestampOnWorkQueueQueryVariables>(GetEarliestTimestampOnWorkQueueDocument, options);
        }
export type GetEarliestTimestampOnWorkQueueQueryHookResult = ReturnType<typeof useGetEarliestTimestampOnWorkQueueQuery>;
export type GetEarliestTimestampOnWorkQueueLazyQueryHookResult = ReturnType<typeof useGetEarliestTimestampOnWorkQueueLazyQuery>;
export type GetEarliestTimestampOnWorkQueueQueryResult = Apollo.QueryResult<GetEarliestTimestampOnWorkQueueQuery, GetEarliestTimestampOnWorkQueueQueryVariables>;
export const GetAllProjectsDocument = gql`
    query GetAllProjects {
  projects {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;

/**
 * __useGetAllProjectsQuery__
 *
 * To run a query within a React component, call `useGetAllProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllProjectsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllProjectsQuery, GetAllProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllProjectsQuery, GetAllProjectsQueryVariables>(GetAllProjectsDocument, options);
      }
export function useGetAllProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllProjectsQuery, GetAllProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllProjectsQuery, GetAllProjectsQueryVariables>(GetAllProjectsDocument, options);
        }
export type GetAllProjectsQueryHookResult = ReturnType<typeof useGetAllProjectsQuery>;
export type GetAllProjectsLazyQueryHookResult = ReturnType<typeof useGetAllProjectsLazyQuery>;
export type GetAllProjectsQueryResult = Apollo.QueryResult<GetAllProjectsQuery, GetAllProjectsQueryVariables>;
export const UpdateProjectStartDateDocument = gql`
    mutation UpdateProjectStartDate($customer: String!, $project: String!, $startDate: Date!) {
  updateProjectStartDate(
    input: {customer: $customer, project: $project, startDate: $startDate}
  ) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;
export type UpdateProjectStartDateMutationFn = Apollo.MutationFunction<UpdateProjectStartDateMutation, UpdateProjectStartDateMutationVariables>;

/**
 * __useUpdateProjectStartDateMutation__
 *
 * To run a mutation, you first call `useUpdateProjectStartDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectStartDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectStartDateMutation, { data, loading, error }] = useUpdateProjectStartDateMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useUpdateProjectStartDateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectStartDateMutation, UpdateProjectStartDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectStartDateMutation, UpdateProjectStartDateMutationVariables>(UpdateProjectStartDateDocument, options);
      }
export type UpdateProjectStartDateMutationHookResult = ReturnType<typeof useUpdateProjectStartDateMutation>;
export type UpdateProjectStartDateMutationResult = Apollo.MutationResult<UpdateProjectStartDateMutation>;
export type UpdateProjectStartDateMutationOptions = Apollo.BaseMutationOptions<UpdateProjectStartDateMutation, UpdateProjectStartDateMutationVariables>;
export const UpdateBatchDocument = gql`
    mutation UpdateBatch($customer: String!, $project: String!, $batchId: UUID!, $timestamp: Date!, $weekNumber: Int!) {
  updateBatches(
    tenant: {customer: $customer, project: $project, scope: internal}
    batches: [{id: $batchId, weekNumber: $weekNumber, timestamp: $timestamp}]
  ) {
    id
    weekNumber
    timestamp
  }
}
    `;
export type UpdateBatchMutationFn = Apollo.MutationFunction<UpdateBatchMutation, UpdateBatchMutationVariables>;

/**
 * __useUpdateBatchMutation__
 *
 * To run a mutation, you first call `useUpdateBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBatchMutation, { data, loading, error }] = useUpdateBatchMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      batchId: // value for 'batchId'
 *      timestamp: // value for 'timestamp'
 *      weekNumber: // value for 'weekNumber'
 *   },
 * });
 */
export function useUpdateBatchMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBatchMutation, UpdateBatchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBatchMutation, UpdateBatchMutationVariables>(UpdateBatchDocument, options);
      }
export type UpdateBatchMutationHookResult = ReturnType<typeof useUpdateBatchMutation>;
export type UpdateBatchMutationResult = Apollo.MutationResult<UpdateBatchMutation>;
export type UpdateBatchMutationOptions = Apollo.BaseMutationOptions<UpdateBatchMutation, UpdateBatchMutationVariables>;
export const CreateNewProjectDocument = gql`
    mutation CreateNewProject($customer: String!, $project: String!, $defaultLanguage: String!, $displayName: String!) {
  createProject(
    input: {customer: $customer, project: $project, defaultLanguage: $defaultLanguage, displayName: $displayName}
  )
}
    `;
export type CreateNewProjectMutationFn = Apollo.MutationFunction<CreateNewProjectMutation, CreateNewProjectMutationVariables>;

/**
 * __useCreateNewProjectMutation__
 *
 * To run a mutation, you first call `useCreateNewProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewProjectMutation, { data, loading, error }] = useCreateNewProjectMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      defaultLanguage: // value for 'defaultLanguage'
 *      displayName: // value for 'displayName'
 *   },
 * });
 */
export function useCreateNewProjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewProjectMutation, CreateNewProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNewProjectMutation, CreateNewProjectMutationVariables>(CreateNewProjectDocument, options);
      }
export type CreateNewProjectMutationHookResult = ReturnType<typeof useCreateNewProjectMutation>;
export type CreateNewProjectMutationResult = Apollo.MutationResult<CreateNewProjectMutation>;
export type CreateNewProjectMutationOptions = Apollo.BaseMutationOptions<CreateNewProjectMutation, CreateNewProjectMutationVariables>;
export const GetAncestorsForSceneDocument = gql`
    query GetAncestorsForScene($customer: String!, $project: String!, $scope: Scope!, $sceneId: UUID!, $includeSelfSpace: Boolean = false) {
  scene(
    tenant: {customer: $customer, project: $project, scope: $scope}
    id: $sceneId
  ) {
    id
    name
    space {
      id
      name
      ancestors(includeSelf: $includeSelfSpace) {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetAncestorsForSceneQuery__
 *
 * To run a query within a React component, call `useGetAncestorsForSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAncestorsForSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAncestorsForSceneQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      sceneId: // value for 'sceneId'
 *      includeSelfSpace: // value for 'includeSelfSpace'
 *   },
 * });
 */
export function useGetAncestorsForSceneQuery(baseOptions: Apollo.QueryHookOptions<GetAncestorsForSceneQuery, GetAncestorsForSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAncestorsForSceneQuery, GetAncestorsForSceneQueryVariables>(GetAncestorsForSceneDocument, options);
      }
export function useGetAncestorsForSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAncestorsForSceneQuery, GetAncestorsForSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAncestorsForSceneQuery, GetAncestorsForSceneQueryVariables>(GetAncestorsForSceneDocument, options);
        }
export type GetAncestorsForSceneQueryHookResult = ReturnType<typeof useGetAncestorsForSceneQuery>;
export type GetAncestorsForSceneLazyQueryHookResult = ReturnType<typeof useGetAncestorsForSceneLazyQuery>;
export type GetAncestorsForSceneQueryResult = Apollo.QueryResult<GetAncestorsForSceneQuery, GetAncestorsForSceneQueryVariables>;
export const GetSiblingSpacesDocument = gql`
    query GetSiblingSpaces($customer: String!, $project: String!, $scope: Scope!, $spaceId: UUID!) {
  space(
    tenant: {customer: $customer, project: $project, scope: $scope}
    id: $spaceId
  ) {
    id
    parentSpace {
      id
      subSpaces {
        id
        name
        guiIndex
      }
    }
  }
}
    `;

/**
 * __useGetSiblingSpacesQuery__
 *
 * To run a query within a React component, call `useGetSiblingSpacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiblingSpacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiblingSpacesQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useGetSiblingSpacesQuery(baseOptions: Apollo.QueryHookOptions<GetSiblingSpacesQuery, GetSiblingSpacesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSiblingSpacesQuery, GetSiblingSpacesQueryVariables>(GetSiblingSpacesDocument, options);
      }
export function useGetSiblingSpacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSiblingSpacesQuery, GetSiblingSpacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSiblingSpacesQuery, GetSiblingSpacesQueryVariables>(GetSiblingSpacesDocument, options);
        }
export type GetSiblingSpacesQueryHookResult = ReturnType<typeof useGetSiblingSpacesQuery>;
export type GetSiblingSpacesLazyQueryHookResult = ReturnType<typeof useGetSiblingSpacesLazyQuery>;
export type GetSiblingSpacesQueryResult = Apollo.QueryResult<GetSiblingSpacesQuery, GetSiblingSpacesQueryVariables>;
export const GetScenesDocument = gql`
    query GetScenes($customer: String!, $project: String!, $scope: Scope!, $spaceId: UUID!) {
  space(
    tenant: {customer: $customer, project: $project, scope: $scope}
    id: $spaceId
  ) {
    id
    scenes {
      id
      name
      guiIndex
    }
  }
}
    `;

/**
 * __useGetScenesQuery__
 *
 * To run a query within a React component, call `useGetScenesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScenesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScenesQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useGetScenesQuery(baseOptions: Apollo.QueryHookOptions<GetScenesQuery, GetScenesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScenesQuery, GetScenesQueryVariables>(GetScenesDocument, options);
      }
export function useGetScenesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScenesQuery, GetScenesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScenesQuery, GetScenesQueryVariables>(GetScenesDocument, options);
        }
export type GetScenesQueryHookResult = ReturnType<typeof useGetScenesQuery>;
export type GetScenesLazyQueryHookResult = ReturnType<typeof useGetScenesLazyQuery>;
export type GetScenesQueryResult = Apollo.QueryResult<GetScenesQuery, GetScenesQueryVariables>;
export const GetSubSpacesDocument = gql`
    query GetSubSpaces($customer: String!, $project: String!, $scope: Scope!, $spaceId: UUID!) {
  space(
    tenant: {customer: $customer, project: $project, scope: $scope}
    id: $spaceId
  ) {
    id
    subSpaces {
      id
      name
    }
  }
}
    `;

/**
 * __useGetSubSpacesQuery__
 *
 * To run a query within a React component, call `useGetSubSpacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubSpacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubSpacesQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useGetSubSpacesQuery(baseOptions: Apollo.QueryHookOptions<GetSubSpacesQuery, GetSubSpacesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubSpacesQuery, GetSubSpacesQueryVariables>(GetSubSpacesDocument, options);
      }
export function useGetSubSpacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubSpacesQuery, GetSubSpacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubSpacesQuery, GetSubSpacesQueryVariables>(GetSubSpacesDocument, options);
        }
export type GetSubSpacesQueryHookResult = ReturnType<typeof useGetSubSpacesQuery>;
export type GetSubSpacesLazyQueryHookResult = ReturnType<typeof useGetSubSpacesLazyQuery>;
export type GetSubSpacesQueryResult = Apollo.QueryResult<GetSubSpacesQuery, GetSubSpacesQueryVariables>;
export const GenerateSceneSpacesMappingsDocument = gql`
    query GenerateSceneSpacesMappings($customer: String!, $project: String!, $scope: Scope!) {
  generateDumpSheetData(
    tenant: {customer: $customer, project: $project, scope: $scope}
  ) {
    sceneShell360Id
    sceneName
    ancestorIds
    ancestorNames
    sceneLocation
    floorName
    scanLevel
    sequenceNumber
    sceneAliasShell360Id
  }
}
    `;

/**
 * __useGenerateSceneSpacesMappingsQuery__
 *
 * To run a query within a React component, call `useGenerateSceneSpacesMappingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateSceneSpacesMappingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateSceneSpacesMappingsQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useGenerateSceneSpacesMappingsQuery(baseOptions: Apollo.QueryHookOptions<GenerateSceneSpacesMappingsQuery, GenerateSceneSpacesMappingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenerateSceneSpacesMappingsQuery, GenerateSceneSpacesMappingsQueryVariables>(GenerateSceneSpacesMappingsDocument, options);
      }
export function useGenerateSceneSpacesMappingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenerateSceneSpacesMappingsQuery, GenerateSceneSpacesMappingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenerateSceneSpacesMappingsQuery, GenerateSceneSpacesMappingsQueryVariables>(GenerateSceneSpacesMappingsDocument, options);
        }
export type GenerateSceneSpacesMappingsQueryHookResult = ReturnType<typeof useGenerateSceneSpacesMappingsQuery>;
export type GenerateSceneSpacesMappingsLazyQueryHookResult = ReturnType<typeof useGenerateSceneSpacesMappingsLazyQuery>;
export type GenerateSceneSpacesMappingsQueryResult = Apollo.QueryResult<GenerateSceneSpacesMappingsQuery, GenerateSceneSpacesMappingsQueryVariables>;
export const BatchesDocument = gql`
    query Batches($customer: String!, $project: String!, $scope: Scope!) {
  batches(tenant: {customer: $customer, project: $project, scope: $scope}) {
    id
    weekNumber
    timestamp
  }
}
    `;

/**
 * __useBatchesQuery__
 *
 * To run a query within a React component, call `useBatchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBatchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBatchesQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useBatchesQuery(baseOptions: Apollo.QueryHookOptions<BatchesQuery, BatchesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BatchesQuery, BatchesQueryVariables>(BatchesDocument, options);
      }
export function useBatchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BatchesQuery, BatchesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BatchesQuery, BatchesQueryVariables>(BatchesDocument, options);
        }
export type BatchesQueryHookResult = ReturnType<typeof useBatchesQuery>;
export type BatchesLazyQueryHookResult = ReturnType<typeof useBatchesLazyQuery>;
export type BatchesQueryResult = Apollo.QueryResult<BatchesQuery, BatchesQueryVariables>;
export const GetComponentTypesDocument = gql`
    query GetComponentTypes($customer: String!, $project: String!, $scope: Scope!) {
  componentTypes(tenant: {customer: $customer, project: $project, scope: $scope}) {
    id
    name
  }
}
    `;

/**
 * __useGetComponentTypesQuery__
 *
 * To run a query within a React component, call `useGetComponentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComponentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComponentTypesQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useGetComponentTypesQuery(baseOptions: Apollo.QueryHookOptions<GetComponentTypesQuery, GetComponentTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetComponentTypesQuery, GetComponentTypesQueryVariables>(GetComponentTypesDocument, options);
      }
export function useGetComponentTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetComponentTypesQuery, GetComponentTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetComponentTypesQuery, GetComponentTypesQueryVariables>(GetComponentTypesDocument, options);
        }
export type GetComponentTypesQueryHookResult = ReturnType<typeof useGetComponentTypesQuery>;
export type GetComponentTypesLazyQueryHookResult = ReturnType<typeof useGetComponentTypesLazyQuery>;
export type GetComponentTypesQueryResult = Apollo.QueryResult<GetComponentTypesQuery, GetComponentTypesQueryVariables>;
export const CreateComponentTypeDocument = gql`
    mutation CreateComponentType($customer: String!, $project: String!, $scope: Scope!, $name: String!, $description: String!) {
  createComponentType(
    tenant: {customer: $customer, project: $project, scope: $scope}
    name: $name
    description: $description
  ) {
    ...ComponentType
  }
}
    ${ComponentTypeFragmentDoc}`;
export type CreateComponentTypeMutationFn = Apollo.MutationFunction<CreateComponentTypeMutation, CreateComponentTypeMutationVariables>;

/**
 * __useCreateComponentTypeMutation__
 *
 * To run a mutation, you first call `useCreateComponentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateComponentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createComponentTypeMutation, { data, loading, error }] = useCreateComponentTypeMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateComponentTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreateComponentTypeMutation, CreateComponentTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateComponentTypeMutation, CreateComponentTypeMutationVariables>(CreateComponentTypeDocument, options);
      }
export type CreateComponentTypeMutationHookResult = ReturnType<typeof useCreateComponentTypeMutation>;
export type CreateComponentTypeMutationResult = Apollo.MutationResult<CreateComponentTypeMutation>;
export type CreateComponentTypeMutationOptions = Apollo.BaseMutationOptions<CreateComponentTypeMutation, CreateComponentTypeMutationVariables>;
export const PlansDocument = gql`
    query Plans($customer: String!, $project: String!, $scope: Scope!) {
  plans(tenant: {customer: $customer, project: $project, scope: $scope}) {
    id
    name
    startDate
  }
}
    `;

/**
 * __usePlansQuery__
 *
 * To run a query within a React component, call `usePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlansQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function usePlansQuery(baseOptions: Apollo.QueryHookOptions<PlansQuery, PlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlansQuery, PlansQueryVariables>(PlansDocument, options);
      }
export function usePlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlansQuery, PlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlansQuery, PlansQueryVariables>(PlansDocument, options);
        }
export type PlansQueryHookResult = ReturnType<typeof usePlansQuery>;
export type PlansLazyQueryHookResult = ReturnType<typeof usePlansLazyQuery>;
export type PlansQueryResult = Apollo.QueryResult<PlansQuery, PlansQueryVariables>;
export const GetAllSpaceCategoriesDocument = gql`
    query getAllSpaceCategories($customer: String!, $project: String!, $scope: Scope!) {
  spaceCategories(tenant: {customer: $customer, project: $project, scope: $scope})
}
    `;

/**
 * __useGetAllSpaceCategoriesQuery__
 *
 * To run a query within a React component, call `useGetAllSpaceCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSpaceCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSpaceCategoriesQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useGetAllSpaceCategoriesQuery(baseOptions: Apollo.QueryHookOptions<GetAllSpaceCategoriesQuery, GetAllSpaceCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllSpaceCategoriesQuery, GetAllSpaceCategoriesQueryVariables>(GetAllSpaceCategoriesDocument, options);
      }
export function useGetAllSpaceCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllSpaceCategoriesQuery, GetAllSpaceCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllSpaceCategoriesQuery, GetAllSpaceCategoriesQueryVariables>(GetAllSpaceCategoriesDocument, options);
        }
export type GetAllSpaceCategoriesQueryHookResult = ReturnType<typeof useGetAllSpaceCategoriesQuery>;
export type GetAllSpaceCategoriesLazyQueryHookResult = ReturnType<typeof useGetAllSpaceCategoriesLazyQuery>;
export type GetAllSpaceCategoriesQueryResult = Apollo.QueryResult<GetAllSpaceCategoriesQuery, GetAllSpaceCategoriesQueryVariables>;
export const GetFullNameForSpaceDocument = gql`
    query GetFullNameForSpace($customer: String!, $project: String!, $scope: Scope!, $spaceId: UUID!) {
  spacesByIds(
    tenant: {customer: $customer, project: $project, scope: $scope}
    ids: [$spaceId]
  ) {
    ancestors(includeSelf: true) {
      id
      name
    }
  }
}
    `;

/**
 * __useGetFullNameForSpaceQuery__
 *
 * To run a query within a React component, call `useGetFullNameForSpaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFullNameForSpaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFullNameForSpaceQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useGetFullNameForSpaceQuery(baseOptions: Apollo.QueryHookOptions<GetFullNameForSpaceQuery, GetFullNameForSpaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFullNameForSpaceQuery, GetFullNameForSpaceQueryVariables>(GetFullNameForSpaceDocument, options);
      }
export function useGetFullNameForSpaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFullNameForSpaceQuery, GetFullNameForSpaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFullNameForSpaceQuery, GetFullNameForSpaceQueryVariables>(GetFullNameForSpaceDocument, options);
        }
export type GetFullNameForSpaceQueryHookResult = ReturnType<typeof useGetFullNameForSpaceQuery>;
export type GetFullNameForSpaceLazyQueryHookResult = ReturnType<typeof useGetFullNameForSpaceLazyQuery>;
export type GetFullNameForSpaceQueryResult = Apollo.QueryResult<GetFullNameForSpaceQuery, GetFullNameForSpaceQueryVariables>;
export const GetAllSpaceTagsDocument = gql`
    query getAllSpaceTags($customer: String!, $project: String!, $scope: Scope!) {
  spaceTags(tenant: {customer: $customer, project: $project, scope: $scope})
}
    `;

/**
 * __useGetAllSpaceTagsQuery__
 *
 * To run a query within a React component, call `useGetAllSpaceTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSpaceTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSpaceTagsQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useGetAllSpaceTagsQuery(baseOptions: Apollo.QueryHookOptions<GetAllSpaceTagsQuery, GetAllSpaceTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllSpaceTagsQuery, GetAllSpaceTagsQueryVariables>(GetAllSpaceTagsDocument, options);
      }
export function useGetAllSpaceTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllSpaceTagsQuery, GetAllSpaceTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllSpaceTagsQuery, GetAllSpaceTagsQueryVariables>(GetAllSpaceTagsDocument, options);
        }
export type GetAllSpaceTagsQueryHookResult = ReturnType<typeof useGetAllSpaceTagsQuery>;
export type GetAllSpaceTagsLazyQueryHookResult = ReturnType<typeof useGetAllSpaceTagsLazyQuery>;
export type GetAllSpaceTagsQueryResult = Apollo.QueryResult<GetAllSpaceTagsQuery, GetAllSpaceTagsQueryVariables>;
export const GetAllSpaceTypesDocument = gql`
    query getAllSpaceTypes($customer: String!, $project: String!, $scope: Scope!) {
  spaceTypes(tenant: {customer: $customer, project: $project, scope: $scope})
}
    `;

/**
 * __useGetAllSpaceTypesQuery__
 *
 * To run a query within a React component, call `useGetAllSpaceTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSpaceTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSpaceTypesQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useGetAllSpaceTypesQuery(baseOptions: Apollo.QueryHookOptions<GetAllSpaceTypesQuery, GetAllSpaceTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllSpaceTypesQuery, GetAllSpaceTypesQueryVariables>(GetAllSpaceTypesDocument, options);
      }
export function useGetAllSpaceTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllSpaceTypesQuery, GetAllSpaceTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllSpaceTypesQuery, GetAllSpaceTypesQueryVariables>(GetAllSpaceTypesDocument, options);
        }
export type GetAllSpaceTypesQueryHookResult = ReturnType<typeof useGetAllSpaceTypesQuery>;
export type GetAllSpaceTypesLazyQueryHookResult = ReturnType<typeof useGetAllSpaceTypesLazyQuery>;
export type GetAllSpaceTypesQueryResult = Apollo.QueryResult<GetAllSpaceTypesQuery, GetAllSpaceTypesQueryVariables>;
export const GetSubcontractorsDocument = gql`
    query GetSubcontractors($customer: String!, $project: String!, $scope: Scope!) {
  subcontractors(tenant: {customer: $customer, project: $project, scope: $scope}) {
    id
    name
  }
}
    `;

/**
 * __useGetSubcontractorsQuery__
 *
 * To run a query within a React component, call `useGetSubcontractorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractorsQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useGetSubcontractorsQuery(baseOptions: Apollo.QueryHookOptions<GetSubcontractorsQuery, GetSubcontractorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubcontractorsQuery, GetSubcontractorsQueryVariables>(GetSubcontractorsDocument, options);
      }
export function useGetSubcontractorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubcontractorsQuery, GetSubcontractorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubcontractorsQuery, GetSubcontractorsQueryVariables>(GetSubcontractorsDocument, options);
        }
export type GetSubcontractorsQueryHookResult = ReturnType<typeof useGetSubcontractorsQuery>;
export type GetSubcontractorsLazyQueryHookResult = ReturnType<typeof useGetSubcontractorsLazyQuery>;
export type GetSubcontractorsQueryResult = Apollo.QueryResult<GetSubcontractorsQuery, GetSubcontractorsQueryVariables>;
export const CreateSubcontractorsDocument = gql`
    mutation CreateSubcontractors($customer: String!, $project: String!, $scope: Scope!, $subcontractors: [String!]!) {
  createSubcontractors(
    tenant: {customer: $customer, project: $project, scope: $scope}
    subcontractors: $subcontractors
  ) {
    ...Subcontractor
  }
}
    ${SubcontractorFragmentDoc}`;
export type CreateSubcontractorsMutationFn = Apollo.MutationFunction<CreateSubcontractorsMutation, CreateSubcontractorsMutationVariables>;

/**
 * __useCreateSubcontractorsMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractorsMutation, { data, loading, error }] = useCreateSubcontractorsMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      subcontractors: // value for 'subcontractors'
 *   },
 * });
 */
export function useCreateSubcontractorsMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubcontractorsMutation, CreateSubcontractorsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubcontractorsMutation, CreateSubcontractorsMutationVariables>(CreateSubcontractorsDocument, options);
      }
export type CreateSubcontractorsMutationHookResult = ReturnType<typeof useCreateSubcontractorsMutation>;
export type CreateSubcontractorsMutationResult = Apollo.MutationResult<CreateSubcontractorsMutation>;
export type CreateSubcontractorsMutationOptions = Apollo.BaseMutationOptions<CreateSubcontractorsMutation, CreateSubcontractorsMutationVariables>;
export const GetAllFloorplansScanSequencesAndScenesDocument = gql`
    query GetAllFloorplansScanSequencesAndScenes($tenant: TenantInputType!) {
  scanSequencesByFilter(tenant: $tenant, scanSequenceFilters: {floorplanId: null}) {
    floorplan {
      ...SequenceMapsFloorplanFragment
      space {
        name
        category
        scenes {
          ...SequenceMapsSceneFragment
        }
        ancestors {
          name
        }
        descendantSpaces {
          category
          scenes {
            ...SequenceMapsSceneFragment
          }
        }
      }
    }
    sequencePrefix
    scanLevel
    scenes {
      id
    }
  }
}
    ${SequenceMapsFloorplanFragmentFragmentDoc}
${SequenceMapsSceneFragmentFragmentDoc}`;

/**
 * __useGetAllFloorplansScanSequencesAndScenesQuery__
 *
 * To run a query within a React component, call `useGetAllFloorplansScanSequencesAndScenesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllFloorplansScanSequencesAndScenesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllFloorplansScanSequencesAndScenesQuery({
 *   variables: {
 *      tenant: // value for 'tenant'
 *   },
 * });
 */
export function useGetAllFloorplansScanSequencesAndScenesQuery(baseOptions: Apollo.QueryHookOptions<GetAllFloorplansScanSequencesAndScenesQuery, GetAllFloorplansScanSequencesAndScenesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllFloorplansScanSequencesAndScenesQuery, GetAllFloorplansScanSequencesAndScenesQueryVariables>(GetAllFloorplansScanSequencesAndScenesDocument, options);
      }
export function useGetAllFloorplansScanSequencesAndScenesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllFloorplansScanSequencesAndScenesQuery, GetAllFloorplansScanSequencesAndScenesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllFloorplansScanSequencesAndScenesQuery, GetAllFloorplansScanSequencesAndScenesQueryVariables>(GetAllFloorplansScanSequencesAndScenesDocument, options);
        }
export type GetAllFloorplansScanSequencesAndScenesQueryHookResult = ReturnType<typeof useGetAllFloorplansScanSequencesAndScenesQuery>;
export type GetAllFloorplansScanSequencesAndScenesLazyQueryHookResult = ReturnType<typeof useGetAllFloorplansScanSequencesAndScenesLazyQuery>;
export type GetAllFloorplansScanSequencesAndScenesQueryResult = Apollo.QueryResult<GetAllFloorplansScanSequencesAndScenesQuery, GetAllFloorplansScanSequencesAndScenesQueryVariables>;
export const ScanSequencesByFilterDocument = gql`
    query ScanSequencesByFilter($tenant: TenantInputType!, $floorplanId: UUID!) {
  scanSequencesByFilter(
    tenant: $tenant
    scanSequenceFilters: {floorplanId: $floorplanId}
  ) {
    ...Sequence
  }
}
    ${SequenceFragmentDoc}`;

/**
 * __useScanSequencesByFilterQuery__
 *
 * To run a query within a React component, call `useScanSequencesByFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useScanSequencesByFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScanSequencesByFilterQuery({
 *   variables: {
 *      tenant: // value for 'tenant'
 *      floorplanId: // value for 'floorplanId'
 *   },
 * });
 */
export function useScanSequencesByFilterQuery(baseOptions: Apollo.QueryHookOptions<ScanSequencesByFilterQuery, ScanSequencesByFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScanSequencesByFilterQuery, ScanSequencesByFilterQueryVariables>(ScanSequencesByFilterDocument, options);
      }
export function useScanSequencesByFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScanSequencesByFilterQuery, ScanSequencesByFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScanSequencesByFilterQuery, ScanSequencesByFilterQueryVariables>(ScanSequencesByFilterDocument, options);
        }
export type ScanSequencesByFilterQueryHookResult = ReturnType<typeof useScanSequencesByFilterQuery>;
export type ScanSequencesByFilterLazyQueryHookResult = ReturnType<typeof useScanSequencesByFilterLazyQuery>;
export type ScanSequencesByFilterQueryResult = Apollo.QueryResult<ScanSequencesByFilterQuery, ScanSequencesByFilterQueryVariables>;
export const CreateScanSequenceDocument = gql`
    mutation createScanSequence($tenant: TenantInputType!, $floorplanId: UUID!, $sequencePrefix: ScanSequencePrefixType!, $scanLevel: ScanSequenceLevelType!, $sceneIds: [UUID!]!) {
  createScanSequence(
    tenant: $tenant
    scanSequence: {floorplanId: $floorplanId, sequencePrefix: $sequencePrefix, scanLevel: $scanLevel, sceneIds: $sceneIds}
  ) {
    ...Sequence
  }
}
    ${SequenceFragmentDoc}`;
export type CreateScanSequenceMutationFn = Apollo.MutationFunction<CreateScanSequenceMutation, CreateScanSequenceMutationVariables>;

/**
 * __useCreateScanSequenceMutation__
 *
 * To run a mutation, you first call `useCreateScanSequenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScanSequenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScanSequenceMutation, { data, loading, error }] = useCreateScanSequenceMutation({
 *   variables: {
 *      tenant: // value for 'tenant'
 *      floorplanId: // value for 'floorplanId'
 *      sequencePrefix: // value for 'sequencePrefix'
 *      scanLevel: // value for 'scanLevel'
 *      sceneIds: // value for 'sceneIds'
 *   },
 * });
 */
export function useCreateScanSequenceMutation(baseOptions?: Apollo.MutationHookOptions<CreateScanSequenceMutation, CreateScanSequenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateScanSequenceMutation, CreateScanSequenceMutationVariables>(CreateScanSequenceDocument, options);
      }
export type CreateScanSequenceMutationHookResult = ReturnType<typeof useCreateScanSequenceMutation>;
export type CreateScanSequenceMutationResult = Apollo.MutationResult<CreateScanSequenceMutation>;
export type CreateScanSequenceMutationOptions = Apollo.BaseMutationOptions<CreateScanSequenceMutation, CreateScanSequenceMutationVariables>;
export const UpdateScanSequenceDocument = gql`
    mutation updateScanSequence($tenant: TenantInputType!, $id: UUID!, $floorplanId: UUID!, $sequencePrefix: ScanSequencePrefixType!, $scanLevel: ScanSequenceLevelType!, $sceneIds: [UUID!]!) {
  updateScanSequence(
    tenant: $tenant
    scanSequence: {id: $id, floorplanId: $floorplanId, sequencePrefix: $sequencePrefix, scanLevel: $scanLevel, sceneIds: $sceneIds}
  ) {
    ...Sequence
  }
}
    ${SequenceFragmentDoc}`;
export type UpdateScanSequenceMutationFn = Apollo.MutationFunction<UpdateScanSequenceMutation, UpdateScanSequenceMutationVariables>;

/**
 * __useUpdateScanSequenceMutation__
 *
 * To run a mutation, you first call `useUpdateScanSequenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScanSequenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScanSequenceMutation, { data, loading, error }] = useUpdateScanSequenceMutation({
 *   variables: {
 *      tenant: // value for 'tenant'
 *      id: // value for 'id'
 *      floorplanId: // value for 'floorplanId'
 *      sequencePrefix: // value for 'sequencePrefix'
 *      scanLevel: // value for 'scanLevel'
 *      sceneIds: // value for 'sceneIds'
 *   },
 * });
 */
export function useUpdateScanSequenceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateScanSequenceMutation, UpdateScanSequenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateScanSequenceMutation, UpdateScanSequenceMutationVariables>(UpdateScanSequenceDocument, options);
      }
export type UpdateScanSequenceMutationHookResult = ReturnType<typeof useUpdateScanSequenceMutation>;
export type UpdateScanSequenceMutationResult = Apollo.MutationResult<UpdateScanSequenceMutation>;
export type UpdateScanSequenceMutationOptions = Apollo.BaseMutationOptions<UpdateScanSequenceMutation, UpdateScanSequenceMutationVariables>;
export const CreateJiraIssueDocument = gql`
    mutation createJiraIssue($tenant: TenantInputType!, $issue: CreateJiraIssueInputType!) {
  createJiraIssue(tenant: $tenant, issue: $issue) {
    id
    uniqueShotId
    type
    message
  }
}
    `;
export type CreateJiraIssueMutationFn = Apollo.MutationFunction<CreateJiraIssueMutation, CreateJiraIssueMutationVariables>;

/**
 * __useCreateJiraIssueMutation__
 *
 * To run a mutation, you first call `useCreateJiraIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJiraIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJiraIssueMutation, { data, loading, error }] = useCreateJiraIssueMutation({
 *   variables: {
 *      tenant: // value for 'tenant'
 *      issue: // value for 'issue'
 *   },
 * });
 */
export function useCreateJiraIssueMutation(baseOptions?: Apollo.MutationHookOptions<CreateJiraIssueMutation, CreateJiraIssueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateJiraIssueMutation, CreateJiraIssueMutationVariables>(CreateJiraIssueDocument, options);
      }
export type CreateJiraIssueMutationHookResult = ReturnType<typeof useCreateJiraIssueMutation>;
export type CreateJiraIssueMutationResult = Apollo.MutationResult<CreateJiraIssueMutation>;
export type CreateJiraIssueMutationOptions = Apollo.BaseMutationOptions<CreateJiraIssueMutation, CreateJiraIssueMutationVariables>;
export const DeleteJiraIssueDocument = gql`
    mutation deleteJiraIssue($tenant: TenantInputType!, $issueId: String!) {
  deleteJiraIssue(tenant: $tenant, issueId: $issueId)
}
    `;
export type DeleteJiraIssueMutationFn = Apollo.MutationFunction<DeleteJiraIssueMutation, DeleteJiraIssueMutationVariables>;

/**
 * __useDeleteJiraIssueMutation__
 *
 * To run a mutation, you first call `useDeleteJiraIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJiraIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJiraIssueMutation, { data, loading, error }] = useDeleteJiraIssueMutation({
 *   variables: {
 *      tenant: // value for 'tenant'
 *      issueId: // value for 'issueId'
 *   },
 * });
 */
export function useDeleteJiraIssueMutation(baseOptions?: Apollo.MutationHookOptions<DeleteJiraIssueMutation, DeleteJiraIssueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteJiraIssueMutation, DeleteJiraIssueMutationVariables>(DeleteJiraIssueDocument, options);
      }
export type DeleteJiraIssueMutationHookResult = ReturnType<typeof useDeleteJiraIssueMutation>;
export type DeleteJiraIssueMutationResult = Apollo.MutationResult<DeleteJiraIssueMutation>;
export type DeleteJiraIssueMutationOptions = Apollo.BaseMutationOptions<DeleteJiraIssueMutation, DeleteJiraIssueMutationVariables>;
export const GetJiraIssuesForShotDocument = gql`
    query getJiraIssuesForShot($tenant: TenantInputType!, $uniqueShotId: String!) {
  jiraIssuesForShot(tenant: $tenant, uniqueShotId: $uniqueShotId) {
    id
    type
    uniqueShotId
    message
  }
}
    `;

/**
 * __useGetJiraIssuesForShotQuery__
 *
 * To run a query within a React component, call `useGetJiraIssuesForShotQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJiraIssuesForShotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJiraIssuesForShotQuery({
 *   variables: {
 *      tenant: // value for 'tenant'
 *      uniqueShotId: // value for 'uniqueShotId'
 *   },
 * });
 */
export function useGetJiraIssuesForShotQuery(baseOptions: Apollo.QueryHookOptions<GetJiraIssuesForShotQuery, GetJiraIssuesForShotQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJiraIssuesForShotQuery, GetJiraIssuesForShotQueryVariables>(GetJiraIssuesForShotDocument, options);
      }
export function useGetJiraIssuesForShotLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJiraIssuesForShotQuery, GetJiraIssuesForShotQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJiraIssuesForShotQuery, GetJiraIssuesForShotQueryVariables>(GetJiraIssuesForShotDocument, options);
        }
export type GetJiraIssuesForShotQueryHookResult = ReturnType<typeof useGetJiraIssuesForShotQuery>;
export type GetJiraIssuesForShotLazyQueryHookResult = ReturnType<typeof useGetJiraIssuesForShotLazyQuery>;
export type GetJiraIssuesForShotQueryResult = Apollo.QueryResult<GetJiraIssuesForShotQuery, GetJiraIssuesForShotQueryVariables>;
export const GetSpacesTreeWithDescendantSpacesDocument = gql`
    query GetSpacesTreeWithDescendantSpaces($customer: String!, $project: String!, $scope: Scope!, $spaceFilters: SpaceFilters!, $includeMetadata: Boolean = false) {
  spacesByFilter(
    tenant: {customer: $customer, project: $project, scope: $scope}
    spaceFilters: $spaceFilters
  ) {
    ...SpaceWithScenesAndParent
    descendantSpaces {
      ...SpaceWithScenesAndParent
    }
  }
}
    ${SpaceWithScenesAndParentFragmentDoc}`;

/**
 * __useGetSpacesTreeWithDescendantSpacesQuery__
 *
 * To run a query within a React component, call `useGetSpacesTreeWithDescendantSpacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpacesTreeWithDescendantSpacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpacesTreeWithDescendantSpacesQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      spaceFilters: // value for 'spaceFilters'
 *      includeMetadata: // value for 'includeMetadata'
 *   },
 * });
 */
export function useGetSpacesTreeWithDescendantSpacesQuery(baseOptions: Apollo.QueryHookOptions<GetSpacesTreeWithDescendantSpacesQuery, GetSpacesTreeWithDescendantSpacesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSpacesTreeWithDescendantSpacesQuery, GetSpacesTreeWithDescendantSpacesQueryVariables>(GetSpacesTreeWithDescendantSpacesDocument, options);
      }
export function useGetSpacesTreeWithDescendantSpacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSpacesTreeWithDescendantSpacesQuery, GetSpacesTreeWithDescendantSpacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSpacesTreeWithDescendantSpacesQuery, GetSpacesTreeWithDescendantSpacesQueryVariables>(GetSpacesTreeWithDescendantSpacesDocument, options);
        }
export type GetSpacesTreeWithDescendantSpacesQueryHookResult = ReturnType<typeof useGetSpacesTreeWithDescendantSpacesQuery>;
export type GetSpacesTreeWithDescendantSpacesLazyQueryHookResult = ReturnType<typeof useGetSpacesTreeWithDescendantSpacesLazyQuery>;
export type GetSpacesTreeWithDescendantSpacesQueryResult = Apollo.QueryResult<GetSpacesTreeWithDescendantSpacesQuery, GetSpacesTreeWithDescendantSpacesQueryVariables>;
export const SearchSpacesDocument = gql`
    query SearchSpaces($customer: String!, $project: String!, $scope: Scope!, $searchTerms: [String!]!, $categories: [String!]!, $types: [String!]!, $tags: [String!]!, $includeMetadata: Boolean = false) {
  searchSpaces(
    tenant: {customer: $customer, project: $project, scope: $scope}
    searchTerms: $searchTerms
    categories: $categories
    types: $types
    tags: $tags
    includeAncestors: true
  ) {
    ...Space
    ancestors {
      id
      name
    }
  }
}
    ${SpaceFragmentDoc}`;

/**
 * __useSearchSpacesQuery__
 *
 * To run a query within a React component, call `useSearchSpacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSpacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSpacesQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      searchTerms: // value for 'searchTerms'
 *      categories: // value for 'categories'
 *      types: // value for 'types'
 *      tags: // value for 'tags'
 *      includeMetadata: // value for 'includeMetadata'
 *   },
 * });
 */
export function useSearchSpacesQuery(baseOptions: Apollo.QueryHookOptions<SearchSpacesQuery, SearchSpacesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchSpacesQuery, SearchSpacesQueryVariables>(SearchSpacesDocument, options);
      }
export function useSearchSpacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchSpacesQuery, SearchSpacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchSpacesQuery, SearchSpacesQueryVariables>(SearchSpacesDocument, options);
        }
export type SearchSpacesQueryHookResult = ReturnType<typeof useSearchSpacesQuery>;
export type SearchSpacesLazyQueryHookResult = ReturnType<typeof useSearchSpacesLazyQuery>;
export type SearchSpacesQueryResult = Apollo.QueryResult<SearchSpacesQuery, SearchSpacesQueryVariables>;
export const GetSpacesTreeDocument = gql`
    query GetSpacesTree($customer: String!, $project: String!, $scope: Scope!, $includeMetadata: Boolean = false, $spaceFilters: SpaceFilters = {parentSpaceId: null}) {
  spacesByFilter(
    tenant: {customer: $customer, project: $project, scope: $scope}
    spaceFilters: $spaceFilters
  ) {
    ...Space
  }
}
    ${SpaceFragmentDoc}`;

/**
 * __useGetSpacesTreeQuery__
 *
 * To run a query within a React component, call `useGetSpacesTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpacesTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpacesTreeQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      includeMetadata: // value for 'includeMetadata'
 *      spaceFilters: // value for 'spaceFilters'
 *   },
 * });
 */
export function useGetSpacesTreeQuery(baseOptions: Apollo.QueryHookOptions<GetSpacesTreeQuery, GetSpacesTreeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSpacesTreeQuery, GetSpacesTreeQueryVariables>(GetSpacesTreeDocument, options);
      }
export function useGetSpacesTreeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSpacesTreeQuery, GetSpacesTreeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSpacesTreeQuery, GetSpacesTreeQueryVariables>(GetSpacesTreeDocument, options);
        }
export type GetSpacesTreeQueryHookResult = ReturnType<typeof useGetSpacesTreeQuery>;
export type GetSpacesTreeLazyQueryHookResult = ReturnType<typeof useGetSpacesTreeLazyQuery>;
export type GetSpacesTreeQueryResult = Apollo.QueryResult<GetSpacesTreeQuery, GetSpacesTreeQueryVariables>;
export const GetChildSpacesDocument = gql`
    query GetChildSpaces($parentId: UUID!, $customer: String!, $project: String!, $scope: Scope!, $includeMetadata: Boolean = false, $includeScenes: Boolean = false, $includeMappings: Boolean = false, $includeShots: Boolean = false) {
  spacesByFilter(
    tenant: {customer: $customer, project: $project, scope: $scope}
    spaceFilters: {parentSpaceId: $parentId}
  ) {
    ...Space
  }
  space(
    tenant: {customer: $customer, project: $project, scope: $scope}
    id: $parentId
  ) {
    id
    name
    guiIndex
    scenes @include(if: $includeScenes) {
      id
      name
      guiIndex
      shots @include(if: $includeShots) {
        id
        batch {
          id
          weekNumber
          timestamp
        }
      }
    }
    mappings @include(if: $includeMappings) {
      ...MappingInSpacesTree
    }
  }
}
    ${SpaceFragmentDoc}
${MappingInSpacesTreeFragmentDoc}`;

/**
 * __useGetChildSpacesQuery__
 *
 * To run a query within a React component, call `useGetChildSpacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChildSpacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChildSpacesQuery({
 *   variables: {
 *      parentId: // value for 'parentId'
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      includeMetadata: // value for 'includeMetadata'
 *      includeScenes: // value for 'includeScenes'
 *      includeMappings: // value for 'includeMappings'
 *      includeShots: // value for 'includeShots'
 *   },
 * });
 */
export function useGetChildSpacesQuery(baseOptions: Apollo.QueryHookOptions<GetChildSpacesQuery, GetChildSpacesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChildSpacesQuery, GetChildSpacesQueryVariables>(GetChildSpacesDocument, options);
      }
export function useGetChildSpacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChildSpacesQuery, GetChildSpacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChildSpacesQuery, GetChildSpacesQueryVariables>(GetChildSpacesDocument, options);
        }
export type GetChildSpacesQueryHookResult = ReturnType<typeof useGetChildSpacesQuery>;
export type GetChildSpacesLazyQueryHookResult = ReturnType<typeof useGetChildSpacesLazyQuery>;
export type GetChildSpacesQueryResult = Apollo.QueryResult<GetChildSpacesQuery, GetChildSpacesQueryVariables>;
export const GetShotsOnSceneDocument = gql`
    query GetShotsOnScene($customer: String!, $project: String!, $scope: Scope!, $sceneId: UUID!) {
  scene(
    tenant: {customer: $customer, project: $project, scope: $scope}
    id: $sceneId
  ) {
    id
    name
    shots {
      id
      batch {
        id
        weekNumber
        timestamp
      }
    }
  }
}
    `;

/**
 * __useGetShotsOnSceneQuery__
 *
 * To run a query within a React component, call `useGetShotsOnSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShotsOnSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShotsOnSceneQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      sceneId: // value for 'sceneId'
 *   },
 * });
 */
export function useGetShotsOnSceneQuery(baseOptions: Apollo.QueryHookOptions<GetShotsOnSceneQuery, GetShotsOnSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShotsOnSceneQuery, GetShotsOnSceneQueryVariables>(GetShotsOnSceneDocument, options);
      }
export function useGetShotsOnSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShotsOnSceneQuery, GetShotsOnSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShotsOnSceneQuery, GetShotsOnSceneQueryVariables>(GetShotsOnSceneDocument, options);
        }
export type GetShotsOnSceneQueryHookResult = ReturnType<typeof useGetShotsOnSceneQuery>;
export type GetShotsOnSceneLazyQueryHookResult = ReturnType<typeof useGetShotsOnSceneLazyQuery>;
export type GetShotsOnSceneQueryResult = Apollo.QueryResult<GetShotsOnSceneQuery, GetShotsOnSceneQueryVariables>;
export const GetMappingsOnSpaceDocument = gql`
    query GetMappingsOnSpace($customer: String!, $project: String!, $scope: Scope!, $spaceId: UUID!) {
  space(
    tenant: {customer: $customer, project: $project, scope: $scope}
    id: $spaceId
  ) {
    id
    mappings {
      id
      disabled
      component {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetMappingsOnSpaceQuery__
 *
 * To run a query within a React component, call `useGetMappingsOnSpaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMappingsOnSpaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMappingsOnSpaceQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useGetMappingsOnSpaceQuery(baseOptions: Apollo.QueryHookOptions<GetMappingsOnSpaceQuery, GetMappingsOnSpaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMappingsOnSpaceQuery, GetMappingsOnSpaceQueryVariables>(GetMappingsOnSpaceDocument, options);
      }
export function useGetMappingsOnSpaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMappingsOnSpaceQuery, GetMappingsOnSpaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMappingsOnSpaceQuery, GetMappingsOnSpaceQueryVariables>(GetMappingsOnSpaceDocument, options);
        }
export type GetMappingsOnSpaceQueryHookResult = ReturnType<typeof useGetMappingsOnSpaceQuery>;
export type GetMappingsOnSpaceLazyQueryHookResult = ReturnType<typeof useGetMappingsOnSpaceLazyQuery>;
export type GetMappingsOnSpaceQueryResult = Apollo.QueryResult<GetMappingsOnSpaceQuery, GetMappingsOnSpaceQueryVariables>;
export const UpdateSpacesDocument = gql`
    mutation UpdateSpaces($customer: String!, $project: String!, $scope: Scope!, $spaces: [UpdateSpaceInputType!]!) {
  updateSpaces(
    tenant: {customer: $customer, project: $project, scope: $scope}
    spaces: $spaces
  ) {
    id
    name
    type
    category
    tags
    parentSpace {
      id
    }
  }
}
    `;
export type UpdateSpacesMutationFn = Apollo.MutationFunction<UpdateSpacesMutation, UpdateSpacesMutationVariables>;

/**
 * __useUpdateSpacesMutation__
 *
 * To run a mutation, you first call `useUpdateSpacesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSpacesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSpacesMutation, { data, loading, error }] = useUpdateSpacesMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      spaces: // value for 'spaces'
 *   },
 * });
 */
export function useUpdateSpacesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSpacesMutation, UpdateSpacesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSpacesMutation, UpdateSpacesMutationVariables>(UpdateSpacesDocument, options);
      }
export type UpdateSpacesMutationHookResult = ReturnType<typeof useUpdateSpacesMutation>;
export type UpdateSpacesMutationResult = Apollo.MutationResult<UpdateSpacesMutation>;
export type UpdateSpacesMutationOptions = Apollo.BaseMutationOptions<UpdateSpacesMutation, UpdateSpacesMutationVariables>;
export const SetSpaceParentDocument = gql`
    mutation setSpaceParent($customer: String!, $project: String!, $scope: Scope!, $spaceId: UUID!, $newParentId: UUID) {
  patchSpaces(
    tenant: {customer: $customer, project: $project, scope: $scope}
    spaceIds: [$spaceId]
    update: {setParentId: {newValue: $newParentId}}
  ) {
    id
    name
    type
    category
    tags
    parentSpace {
      id
    }
  }
}
    `;
export type SetSpaceParentMutationFn = Apollo.MutationFunction<SetSpaceParentMutation, SetSpaceParentMutationVariables>;

/**
 * __useSetSpaceParentMutation__
 *
 * To run a mutation, you first call `useSetSpaceParentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSpaceParentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSpaceParentMutation, { data, loading, error }] = useSetSpaceParentMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      spaceId: // value for 'spaceId'
 *      newParentId: // value for 'newParentId'
 *   },
 * });
 */
export function useSetSpaceParentMutation(baseOptions?: Apollo.MutationHookOptions<SetSpaceParentMutation, SetSpaceParentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetSpaceParentMutation, SetSpaceParentMutationVariables>(SetSpaceParentDocument, options);
      }
export type SetSpaceParentMutationHookResult = ReturnType<typeof useSetSpaceParentMutation>;
export type SetSpaceParentMutationResult = Apollo.MutationResult<SetSpaceParentMutation>;
export type SetSpaceParentMutationOptions = Apollo.BaseMutationOptions<SetSpaceParentMutation, SetSpaceParentMutationVariables>;
export const SetSceneParentDocument = gql`
    mutation setSceneParent($customer: String!, $project: String!, $scope: Scope!, $sceneId: UUID!, $newSpaceId: UUID!) {
  patchScenes(
    tenant: {customer: $customer, project: $project, scope: $scope}
    sceneIds: [$sceneId]
    update: {setSpaceId: {newValue: $newSpaceId}}
  ) {
    id
    name
    space {
      id
    }
  }
}
    `;
export type SetSceneParentMutationFn = Apollo.MutationFunction<SetSceneParentMutation, SetSceneParentMutationVariables>;

/**
 * __useSetSceneParentMutation__
 *
 * To run a mutation, you first call `useSetSceneParentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSceneParentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSceneParentMutation, { data, loading, error }] = useSetSceneParentMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      sceneId: // value for 'sceneId'
 *      newSpaceId: // value for 'newSpaceId'
 *   },
 * });
 */
export function useSetSceneParentMutation(baseOptions?: Apollo.MutationHookOptions<SetSceneParentMutation, SetSceneParentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetSceneParentMutation, SetSceneParentMutationVariables>(SetSceneParentDocument, options);
      }
export type SetSceneParentMutationHookResult = ReturnType<typeof useSetSceneParentMutation>;
export type SetSceneParentMutationResult = Apollo.MutationResult<SetSceneParentMutation>;
export type SetSceneParentMutationOptions = Apollo.BaseMutationOptions<SetSceneParentMutation, SetSceneParentMutationVariables>;
export const GetSceneSpatialConfigDocument = gql`
    query GetSceneSpatialConfig($tenant: TenantInputType!, $sceneId: UUID!) {
  scenesByIds(tenant: $tenant, ids: [$sceneId]) {
    id
    name
    path
  }
}
    `;

/**
 * __useGetSceneSpatialConfigQuery__
 *
 * To run a query within a React component, call `useGetSceneSpatialConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSceneSpatialConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSceneSpatialConfigQuery({
 *   variables: {
 *      tenant: // value for 'tenant'
 *      sceneId: // value for 'sceneId'
 *   },
 * });
 */
export function useGetSceneSpatialConfigQuery(baseOptions: Apollo.QueryHookOptions<GetSceneSpatialConfigQuery, GetSceneSpatialConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSceneSpatialConfigQuery, GetSceneSpatialConfigQueryVariables>(GetSceneSpatialConfigDocument, options);
      }
export function useGetSceneSpatialConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSceneSpatialConfigQuery, GetSceneSpatialConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSceneSpatialConfigQuery, GetSceneSpatialConfigQueryVariables>(GetSceneSpatialConfigDocument, options);
        }
export type GetSceneSpatialConfigQueryHookResult = ReturnType<typeof useGetSceneSpatialConfigQuery>;
export type GetSceneSpatialConfigLazyQueryHookResult = ReturnType<typeof useGetSceneSpatialConfigLazyQuery>;
export type GetSceneSpatialConfigQueryResult = Apollo.QueryResult<GetSceneSpatialConfigQuery, GetSceneSpatialConfigQueryVariables>;
export const PatchScenesSpatialConfigDocument = gql`
    mutation PatchScenesSpatialConfig($tenant: TenantInputType!, $sceneIds: [UUID!]!, $update: PatchSceneInputType!) {
  patchScenes(tenant: $tenant, sceneIds: $sceneIds, update: $update) {
    ...SceneWithSpacesAliasesAndSubjectsFragment
  }
}
    ${SceneWithSpacesAliasesAndSubjectsFragmentFragmentDoc}`;
export type PatchScenesSpatialConfigMutationFn = Apollo.MutationFunction<PatchScenesSpatialConfigMutation, PatchScenesSpatialConfigMutationVariables>;

/**
 * __usePatchScenesSpatialConfigMutation__
 *
 * To run a mutation, you first call `usePatchScenesSpatialConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchScenesSpatialConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchScenesSpatialConfigMutation, { data, loading, error }] = usePatchScenesSpatialConfigMutation({
 *   variables: {
 *      tenant: // value for 'tenant'
 *      sceneIds: // value for 'sceneIds'
 *      update: // value for 'update'
 *   },
 * });
 */
export function usePatchScenesSpatialConfigMutation(baseOptions?: Apollo.MutationHookOptions<PatchScenesSpatialConfigMutation, PatchScenesSpatialConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PatchScenesSpatialConfigMutation, PatchScenesSpatialConfigMutationVariables>(PatchScenesSpatialConfigDocument, options);
      }
export type PatchScenesSpatialConfigMutationHookResult = ReturnType<typeof usePatchScenesSpatialConfigMutation>;
export type PatchScenesSpatialConfigMutationResult = Apollo.MutationResult<PatchScenesSpatialConfigMutation>;
export type PatchScenesSpatialConfigMutationOptions = Apollo.BaseMutationOptions<PatchScenesSpatialConfigMutation, PatchScenesSpatialConfigMutationVariables>;
export const GetScenesUnderSpaceDocument = gql`
    query GetScenesUnderSpace($tenant: TenantInputType!, $spaceId: UUID!) {
  spacesByFilter(tenant: $tenant, spaceFilters: {id: [$spaceId]}) {
    id
    name
    category
    scenes {
      ...SceneWithAliasesAndSubjectsFragment
    }
    descendantSpaces {
      id
      name
      category
      parentSpace {
        id
      }
      scenes {
        ...SceneWithAliasesAndSubjectsFragment
      }
    }
  }
}
    ${SceneWithAliasesAndSubjectsFragmentFragmentDoc}`;

/**
 * __useGetScenesUnderSpaceQuery__
 *
 * To run a query within a React component, call `useGetScenesUnderSpaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScenesUnderSpaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScenesUnderSpaceQuery({
 *   variables: {
 *      tenant: // value for 'tenant'
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useGetScenesUnderSpaceQuery(baseOptions: Apollo.QueryHookOptions<GetScenesUnderSpaceQuery, GetScenesUnderSpaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScenesUnderSpaceQuery, GetScenesUnderSpaceQueryVariables>(GetScenesUnderSpaceDocument, options);
      }
export function useGetScenesUnderSpaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScenesUnderSpaceQuery, GetScenesUnderSpaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScenesUnderSpaceQuery, GetScenesUnderSpaceQueryVariables>(GetScenesUnderSpaceDocument, options);
        }
export type GetScenesUnderSpaceQueryHookResult = ReturnType<typeof useGetScenesUnderSpaceQuery>;
export type GetScenesUnderSpaceLazyQueryHookResult = ReturnType<typeof useGetScenesUnderSpaceLazyQuery>;
export type GetScenesUnderSpaceQueryResult = Apollo.QueryResult<GetScenesUnderSpaceQuery, GetScenesUnderSpaceQueryVariables>;
export const DeleteScenesSpatialConfigDocument = gql`
    mutation DeleteScenesSpatialConfig($tenant: TenantInputType!, $sceneIds: [UUID!]!) {
  deleteScenes(tenant: $tenant, sceneIds: $sceneIds)
}
    `;
export type DeleteScenesSpatialConfigMutationFn = Apollo.MutationFunction<DeleteScenesSpatialConfigMutation, DeleteScenesSpatialConfigMutationVariables>;

/**
 * __useDeleteScenesSpatialConfigMutation__
 *
 * To run a mutation, you first call `useDeleteScenesSpatialConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScenesSpatialConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScenesSpatialConfigMutation, { data, loading, error }] = useDeleteScenesSpatialConfigMutation({
 *   variables: {
 *      tenant: // value for 'tenant'
 *      sceneIds: // value for 'sceneIds'
 *   },
 * });
 */
export function useDeleteScenesSpatialConfigMutation(baseOptions?: Apollo.MutationHookOptions<DeleteScenesSpatialConfigMutation, DeleteScenesSpatialConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteScenesSpatialConfigMutation, DeleteScenesSpatialConfigMutationVariables>(DeleteScenesSpatialConfigDocument, options);
      }
export type DeleteScenesSpatialConfigMutationHookResult = ReturnType<typeof useDeleteScenesSpatialConfigMutation>;
export type DeleteScenesSpatialConfigMutationResult = Apollo.MutationResult<DeleteScenesSpatialConfigMutation>;
export type DeleteScenesSpatialConfigMutationOptions = Apollo.BaseMutationOptions<DeleteScenesSpatialConfigMutation, DeleteScenesSpatialConfigMutationVariables>;
export const CreateSceneUnderSpaceDocument = gql`
    mutation CreateSceneUnderSpace($tenant: TenantInputType!, $scene: CreateSceneInputType!) {
  createScenes(tenant: $tenant, scenes: [$scene]) {
    ...SceneWithSpacesAliasesAndSubjectsFragment
  }
}
    ${SceneWithSpacesAliasesAndSubjectsFragmentFragmentDoc}`;
export type CreateSceneUnderSpaceMutationFn = Apollo.MutationFunction<CreateSceneUnderSpaceMutation, CreateSceneUnderSpaceMutationVariables>;

/**
 * __useCreateSceneUnderSpaceMutation__
 *
 * To run a mutation, you first call `useCreateSceneUnderSpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSceneUnderSpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSceneUnderSpaceMutation, { data, loading, error }] = useCreateSceneUnderSpaceMutation({
 *   variables: {
 *      tenant: // value for 'tenant'
 *      scene: // value for 'scene'
 *   },
 * });
 */
export function useCreateSceneUnderSpaceMutation(baseOptions?: Apollo.MutationHookOptions<CreateSceneUnderSpaceMutation, CreateSceneUnderSpaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSceneUnderSpaceMutation, CreateSceneUnderSpaceMutationVariables>(CreateSceneUnderSpaceDocument, options);
      }
export type CreateSceneUnderSpaceMutationHookResult = ReturnType<typeof useCreateSceneUnderSpaceMutation>;
export type CreateSceneUnderSpaceMutationResult = Apollo.MutationResult<CreateSceneUnderSpaceMutation>;
export type CreateSceneUnderSpaceMutationOptions = Apollo.BaseMutationOptions<CreateSceneUnderSpaceMutation, CreateSceneUnderSpaceMutationVariables>;
export const UpdateScenesSpatialConfigDocument = gql`
    mutation UpdateScenesSpatialConfig($tenant: TenantInputType!, $scenes: [UpdateSceneInputType!]!) {
  updateScenes(tenant: $tenant, scenes: $scenes) {
    ...SceneWithSpacesFragment
  }
}
    ${SceneWithSpacesFragmentFragmentDoc}`;
export type UpdateScenesSpatialConfigMutationFn = Apollo.MutationFunction<UpdateScenesSpatialConfigMutation, UpdateScenesSpatialConfigMutationVariables>;

/**
 * __useUpdateScenesSpatialConfigMutation__
 *
 * To run a mutation, you first call `useUpdateScenesSpatialConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScenesSpatialConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScenesSpatialConfigMutation, { data, loading, error }] = useUpdateScenesSpatialConfigMutation({
 *   variables: {
 *      tenant: // value for 'tenant'
 *      scenes: // value for 'scenes'
 *   },
 * });
 */
export function useUpdateScenesSpatialConfigMutation(baseOptions?: Apollo.MutationHookOptions<UpdateScenesSpatialConfigMutation, UpdateScenesSpatialConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateScenesSpatialConfigMutation, UpdateScenesSpatialConfigMutationVariables>(UpdateScenesSpatialConfigDocument, options);
      }
export type UpdateScenesSpatialConfigMutationHookResult = ReturnType<typeof useUpdateScenesSpatialConfigMutation>;
export type UpdateScenesSpatialConfigMutationResult = Apollo.MutationResult<UpdateScenesSpatialConfigMutation>;
export type UpdateScenesSpatialConfigMutationOptions = Apollo.BaseMutationOptions<UpdateScenesSpatialConfigMutation, UpdateScenesSpatialConfigMutationVariables>;
export const GetSpaceOptionsByIdDocument = gql`
    query GetSpaceOptionsById($tenant: TenantInputType!, $spaceId: UUID!) {
  spacesByFilter(tenant: $tenant, spaceFilters: {id: [$spaceId]}) {
    id
    name
    type
    category
    tags
  }
}
    `;

/**
 * __useGetSpaceOptionsByIdQuery__
 *
 * To run a query within a React component, call `useGetSpaceOptionsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpaceOptionsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpaceOptionsByIdQuery({
 *   variables: {
 *      tenant: // value for 'tenant'
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useGetSpaceOptionsByIdQuery(baseOptions: Apollo.QueryHookOptions<GetSpaceOptionsByIdQuery, GetSpaceOptionsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSpaceOptionsByIdQuery, GetSpaceOptionsByIdQueryVariables>(GetSpaceOptionsByIdDocument, options);
      }
export function useGetSpaceOptionsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSpaceOptionsByIdQuery, GetSpaceOptionsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSpaceOptionsByIdQuery, GetSpaceOptionsByIdQueryVariables>(GetSpaceOptionsByIdDocument, options);
        }
export type GetSpaceOptionsByIdQueryHookResult = ReturnType<typeof useGetSpaceOptionsByIdQuery>;
export type GetSpaceOptionsByIdLazyQueryHookResult = ReturnType<typeof useGetSpaceOptionsByIdLazyQuery>;
export type GetSpaceOptionsByIdQueryResult = Apollo.QueryResult<GetSpaceOptionsByIdQuery, GetSpaceOptionsByIdQueryVariables>;
export const CreateSpacesSpatialConfigDocument = gql`
    mutation CreateSpacesSpatialConfig($tenant: TenantInputType!, $spaces: [CreateSpaceInputType!]!) {
  createSpaces(tenant: $tenant, spaces: $spaces) {
    ...SpaceWithParentAndAncestorsFragment
  }
}
    ${SpaceWithParentAndAncestorsFragmentFragmentDoc}`;
export type CreateSpacesSpatialConfigMutationFn = Apollo.MutationFunction<CreateSpacesSpatialConfigMutation, CreateSpacesSpatialConfigMutationVariables>;

/**
 * __useCreateSpacesSpatialConfigMutation__
 *
 * To run a mutation, you first call `useCreateSpacesSpatialConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSpacesSpatialConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSpacesSpatialConfigMutation, { data, loading, error }] = useCreateSpacesSpatialConfigMutation({
 *   variables: {
 *      tenant: // value for 'tenant'
 *      spaces: // value for 'spaces'
 *   },
 * });
 */
export function useCreateSpacesSpatialConfigMutation(baseOptions?: Apollo.MutationHookOptions<CreateSpacesSpatialConfigMutation, CreateSpacesSpatialConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSpacesSpatialConfigMutation, CreateSpacesSpatialConfigMutationVariables>(CreateSpacesSpatialConfigDocument, options);
      }
export type CreateSpacesSpatialConfigMutationHookResult = ReturnType<typeof useCreateSpacesSpatialConfigMutation>;
export type CreateSpacesSpatialConfigMutationResult = Apollo.MutationResult<CreateSpacesSpatialConfigMutation>;
export type CreateSpacesSpatialConfigMutationOptions = Apollo.BaseMutationOptions<CreateSpacesSpatialConfigMutation, CreateSpacesSpatialConfigMutationVariables>;
export const PatchSpacesSpatialConfigDocument = gql`
    mutation PatchSpacesSpatialConfig($tenant: TenantInputType!, $ids: [UUID!]!, $update: PatchSpaceInputType!) {
  patchSpaces(tenant: $tenant, spaceIds: $ids, update: $update) {
    ...SpaceWithParentFragment
  }
}
    ${SpaceWithParentFragmentFragmentDoc}`;
export type PatchSpacesSpatialConfigMutationFn = Apollo.MutationFunction<PatchSpacesSpatialConfigMutation, PatchSpacesSpatialConfigMutationVariables>;

/**
 * __usePatchSpacesSpatialConfigMutation__
 *
 * To run a mutation, you first call `usePatchSpacesSpatialConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchSpacesSpatialConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchSpacesSpatialConfigMutation, { data, loading, error }] = usePatchSpacesSpatialConfigMutation({
 *   variables: {
 *      tenant: // value for 'tenant'
 *      ids: // value for 'ids'
 *      update: // value for 'update'
 *   },
 * });
 */
export function usePatchSpacesSpatialConfigMutation(baseOptions?: Apollo.MutationHookOptions<PatchSpacesSpatialConfigMutation, PatchSpacesSpatialConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PatchSpacesSpatialConfigMutation, PatchSpacesSpatialConfigMutationVariables>(PatchSpacesSpatialConfigDocument, options);
      }
export type PatchSpacesSpatialConfigMutationHookResult = ReturnType<typeof usePatchSpacesSpatialConfigMutation>;
export type PatchSpacesSpatialConfigMutationResult = Apollo.MutationResult<PatchSpacesSpatialConfigMutation>;
export type PatchSpacesSpatialConfigMutationOptions = Apollo.BaseMutationOptions<PatchSpacesSpatialConfigMutation, PatchSpacesSpatialConfigMutationVariables>;
export const DeleteSpacesSpatialConfigDocument = gql`
    mutation DeleteSpacesSpatialConfig($tenant: TenantInputType!, $spaceIds: [UUID!]!) {
  deleteSpaces(tenant: $tenant, spaceIds: $spaceIds)
}
    `;
export type DeleteSpacesSpatialConfigMutationFn = Apollo.MutationFunction<DeleteSpacesSpatialConfigMutation, DeleteSpacesSpatialConfigMutationVariables>;

/**
 * __useDeleteSpacesSpatialConfigMutation__
 *
 * To run a mutation, you first call `useDeleteSpacesSpatialConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSpacesSpatialConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSpacesSpatialConfigMutation, { data, loading, error }] = useDeleteSpacesSpatialConfigMutation({
 *   variables: {
 *      tenant: // value for 'tenant'
 *      spaceIds: // value for 'spaceIds'
 *   },
 * });
 */
export function useDeleteSpacesSpatialConfigMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSpacesSpatialConfigMutation, DeleteSpacesSpatialConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSpacesSpatialConfigMutation, DeleteSpacesSpatialConfigMutationVariables>(DeleteSpacesSpatialConfigDocument, options);
      }
export type DeleteSpacesSpatialConfigMutationHookResult = ReturnType<typeof useDeleteSpacesSpatialConfigMutation>;
export type DeleteSpacesSpatialConfigMutationResult = Apollo.MutationResult<DeleteSpacesSpatialConfigMutation>;
export type DeleteSpacesSpatialConfigMutationOptions = Apollo.BaseMutationOptions<DeleteSpacesSpatialConfigMutation, DeleteSpacesSpatialConfigMutationVariables>;
export const CopySpatialDataDocument = gql`
    mutation CopySpatialData($tenant: TenantInputType!, $spaceId: UUID!, $newParentSpaceId: UUID!, $newSpaceName: String, $newFloorHeight: Float) {
  copySpatialData(
    tenant: $tenant
    existingRootSpaceId: $spaceId
    newRootParentSpaceId: $newParentSpaceId
    newSpaceName: $newSpaceName
    newFloorHeight: $newFloorHeight
  ) {
    id
  }
}
    `;
export type CopySpatialDataMutationFn = Apollo.MutationFunction<CopySpatialDataMutation, CopySpatialDataMutationVariables>;

/**
 * __useCopySpatialDataMutation__
 *
 * To run a mutation, you first call `useCopySpatialDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopySpatialDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copySpatialDataMutation, { data, loading, error }] = useCopySpatialDataMutation({
 *   variables: {
 *      tenant: // value for 'tenant'
 *      spaceId: // value for 'spaceId'
 *      newParentSpaceId: // value for 'newParentSpaceId'
 *      newSpaceName: // value for 'newSpaceName'
 *      newFloorHeight: // value for 'newFloorHeight'
 *   },
 * });
 */
export function useCopySpatialDataMutation(baseOptions?: Apollo.MutationHookOptions<CopySpatialDataMutation, CopySpatialDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopySpatialDataMutation, CopySpatialDataMutationVariables>(CopySpatialDataDocument, options);
      }
export type CopySpatialDataMutationHookResult = ReturnType<typeof useCopySpatialDataMutation>;
export type CopySpatialDataMutationResult = Apollo.MutationResult<CopySpatialDataMutation>;
export type CopySpatialDataMutationOptions = Apollo.BaseMutationOptions<CopySpatialDataMutation, CopySpatialDataMutationVariables>;
export const GetSpotlightsForRootSpaceDocument = gql`
    query GetSpotlightsForRootSpace($customer: String!, $project: String!, $scope: Scope!, $batchId: UUID!, $spaceId: UUID!) {
  space(
    tenant: {customer: $customer, project: $project, scope: $scope}
    id: $spaceId
  ) {
    ...QASpace
    descendantSpaces {
      ...QASpace
    }
  }
}
    ${QaSpaceFragmentDoc}`;

/**
 * __useGetSpotlightsForRootSpaceQuery__
 *
 * To run a query within a React component, call `useGetSpotlightsForRootSpaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpotlightsForRootSpaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpotlightsForRootSpaceQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      batchId: // value for 'batchId'
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useGetSpotlightsForRootSpaceQuery(baseOptions: Apollo.QueryHookOptions<GetSpotlightsForRootSpaceQuery, GetSpotlightsForRootSpaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSpotlightsForRootSpaceQuery, GetSpotlightsForRootSpaceQueryVariables>(GetSpotlightsForRootSpaceDocument, options);
      }
export function useGetSpotlightsForRootSpaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSpotlightsForRootSpaceQuery, GetSpotlightsForRootSpaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSpotlightsForRootSpaceQuery, GetSpotlightsForRootSpaceQueryVariables>(GetSpotlightsForRootSpaceDocument, options);
        }
export type GetSpotlightsForRootSpaceQueryHookResult = ReturnType<typeof useGetSpotlightsForRootSpaceQuery>;
export type GetSpotlightsForRootSpaceLazyQueryHookResult = ReturnType<typeof useGetSpotlightsForRootSpaceLazyQuery>;
export type GetSpotlightsForRootSpaceQueryResult = Apollo.QueryResult<GetSpotlightsForRootSpaceQuery, GetSpotlightsForRootSpaceQueryVariables>;
export const GetTaskDocument = gql`
    query getTask($customer: String!, $project: String!, $scope: Scope!, $taskId: UUID!) {
  task(
    tenant: {customer: $customer, project: $project, scope: $scope}
    id: $taskId
  ) {
    id
    name
    guiIndex
    plan {
      id
      name
    }
    parentTask {
      id
    }
  }
}
    `;

/**
 * __useGetTaskQuery__
 *
 * To run a query within a React component, call `useGetTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetTaskQuery(baseOptions: Apollo.QueryHookOptions<GetTaskQuery, GetTaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTaskQuery, GetTaskQueryVariables>(GetTaskDocument, options);
      }
export function useGetTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTaskQuery, GetTaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTaskQuery, GetTaskQueryVariables>(GetTaskDocument, options);
        }
export type GetTaskQueryHookResult = ReturnType<typeof useGetTaskQuery>;
export type GetTaskLazyQueryHookResult = ReturnType<typeof useGetTaskLazyQuery>;
export type GetTaskQueryResult = Apollo.QueryResult<GetTaskQuery, GetTaskQueryVariables>;
export const DeleteTaskDocument = gql`
    mutation DeleteTask($customer: String!, $project: String!, $scope: Scope!, $taskId: UUID!) {
  deleteTasks(
    tenant: {customer: $customer, project: $project, scope: $scope}
    ids: [$taskId]
  )
}
    `;
export type DeleteTaskMutationFn = Apollo.MutationFunction<DeleteTaskMutation, DeleteTaskMutationVariables>;

/**
 * __useDeleteTaskMutation__
 *
 * To run a mutation, you first call `useDeleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskMutation, { data, loading, error }] = useDeleteTaskMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useDeleteTaskMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTaskMutation, DeleteTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTaskMutation, DeleteTaskMutationVariables>(DeleteTaskDocument, options);
      }
export type DeleteTaskMutationHookResult = ReturnType<typeof useDeleteTaskMutation>;
export type DeleteTaskMutationResult = Apollo.MutationResult<DeleteTaskMutation>;
export type DeleteTaskMutationOptions = Apollo.BaseMutationOptions<DeleteTaskMutation, DeleteTaskMutationVariables>;
export const UpdateTaskDocument = gql`
    mutation UpdateTask($customer: String!, $project: String!, $scope: Scope!, $task: UpdateTaskInputType!) {
  updateTasks(
    tenant: {customer: $customer, project: $project, scope: $scope}
    tasks: [$task]
  ) {
    id
    name
    guiIndex
  }
}
    `;
export type UpdateTaskMutationFn = Apollo.MutationFunction<UpdateTaskMutation, UpdateTaskMutationVariables>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      task: // value for 'task'
 *   },
 * });
 */
export function useUpdateTaskMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaskMutation, UpdateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(UpdateTaskDocument, options);
      }
export type UpdateTaskMutationHookResult = ReturnType<typeof useUpdateTaskMutation>;
export type UpdateTaskMutationResult = Apollo.MutationResult<UpdateTaskMutation>;
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<UpdateTaskMutation, UpdateTaskMutationVariables>;
export const CanEditJiraIssuesDocument = gql`
    query canEditJiraIssues($tenant: TenantInputType!) {
  canEditJiraIssues(tenant: $tenant)
}
    `;

/**
 * __useCanEditJiraIssuesQuery__
 *
 * To run a query within a React component, call `useCanEditJiraIssuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanEditJiraIssuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanEditJiraIssuesQuery({
 *   variables: {
 *      tenant: // value for 'tenant'
 *   },
 * });
 */
export function useCanEditJiraIssuesQuery(baseOptions: Apollo.QueryHookOptions<CanEditJiraIssuesQuery, CanEditJiraIssuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CanEditJiraIssuesQuery, CanEditJiraIssuesQueryVariables>(CanEditJiraIssuesDocument, options);
      }
export function useCanEditJiraIssuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CanEditJiraIssuesQuery, CanEditJiraIssuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CanEditJiraIssuesQuery, CanEditJiraIssuesQueryVariables>(CanEditJiraIssuesDocument, options);
        }
export type CanEditJiraIssuesQueryHookResult = ReturnType<typeof useCanEditJiraIssuesQuery>;
export type CanEditJiraIssuesLazyQueryHookResult = ReturnType<typeof useCanEditJiraIssuesLazyQuery>;
export type CanEditJiraIssuesQueryResult = Apollo.QueryResult<CanEditJiraIssuesQuery, CanEditJiraIssuesQueryVariables>;
export const GetDefaultSceneDocument = gql`
    query GetDefaultScene($customer: String!, $project: String!, $scope: Scope!, $spaceId: UUID!, $batchId: UUID) {
  space(
    tenant: {customer: $customer, project: $project, scope: $scope}
    id: $spaceId
  ) {
    id
    defaultScene(batchId: $batchId) {
      id
      space {
        id
      }
    }
  }
}
    `;

/**
 * __useGetDefaultSceneQuery__
 *
 * To run a query within a React component, call `useGetDefaultSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultSceneQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      spaceId: // value for 'spaceId'
 *      batchId: // value for 'batchId'
 *   },
 * });
 */
export function useGetDefaultSceneQuery(baseOptions: Apollo.QueryHookOptions<GetDefaultSceneQuery, GetDefaultSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDefaultSceneQuery, GetDefaultSceneQueryVariables>(GetDefaultSceneDocument, options);
      }
export function useGetDefaultSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDefaultSceneQuery, GetDefaultSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDefaultSceneQuery, GetDefaultSceneQueryVariables>(GetDefaultSceneDocument, options);
        }
export type GetDefaultSceneQueryHookResult = ReturnType<typeof useGetDefaultSceneQuery>;
export type GetDefaultSceneLazyQueryHookResult = ReturnType<typeof useGetDefaultSceneLazyQuery>;
export type GetDefaultSceneQueryResult = Apollo.QueryResult<GetDefaultSceneQuery, GetDefaultSceneQueryVariables>;
export const GetNextSceneDocument = gql`
    query GetNextScene($customer: String!, $project: String!, $scope: Scope!, $sceneId: UUID!, $rootSpaceId: UUID!, $batchId: UUID) {
  scene(
    tenant: {customer: $customer, project: $project, scope: $scope}
    id: $sceneId
  ) {
    id
    nextScene(subTreeRootId: $rootSpaceId, batchId: $batchId) {
      id
      space {
        id
      }
    }
  }
}
    `;

/**
 * __useGetNextSceneQuery__
 *
 * To run a query within a React component, call `useGetNextSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextSceneQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      sceneId: // value for 'sceneId'
 *      rootSpaceId: // value for 'rootSpaceId'
 *      batchId: // value for 'batchId'
 *   },
 * });
 */
export function useGetNextSceneQuery(baseOptions: Apollo.QueryHookOptions<GetNextSceneQuery, GetNextSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNextSceneQuery, GetNextSceneQueryVariables>(GetNextSceneDocument, options);
      }
export function useGetNextSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNextSceneQuery, GetNextSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNextSceneQuery, GetNextSceneQueryVariables>(GetNextSceneDocument, options);
        }
export type GetNextSceneQueryHookResult = ReturnType<typeof useGetNextSceneQuery>;
export type GetNextSceneLazyQueryHookResult = ReturnType<typeof useGetNextSceneLazyQuery>;
export type GetNextSceneQueryResult = Apollo.QueryResult<GetNextSceneQuery, GetNextSceneQueryVariables>;
export const GetPreviousSceneDocument = gql`
    query GetPreviousScene($customer: String!, $project: String!, $scope: Scope!, $sceneId: UUID!, $rootSpaceId: UUID!, $batchId: UUID) {
  scene(
    tenant: {customer: $customer, project: $project, scope: $scope}
    id: $sceneId
  ) {
    id
    previousScene(subTreeRootId: $rootSpaceId, batchId: $batchId) {
      id
      space {
        id
      }
    }
  }
}
    `;

/**
 * __useGetPreviousSceneQuery__
 *
 * To run a query within a React component, call `useGetPreviousSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPreviousSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPreviousSceneQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      sceneId: // value for 'sceneId'
 *      rootSpaceId: // value for 'rootSpaceId'
 *      batchId: // value for 'batchId'
 *   },
 * });
 */
export function useGetPreviousSceneQuery(baseOptions: Apollo.QueryHookOptions<GetPreviousSceneQuery, GetPreviousSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPreviousSceneQuery, GetPreviousSceneQueryVariables>(GetPreviousSceneDocument, options);
      }
export function useGetPreviousSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPreviousSceneQuery, GetPreviousSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPreviousSceneQuery, GetPreviousSceneQueryVariables>(GetPreviousSceneDocument, options);
        }
export type GetPreviousSceneQueryHookResult = ReturnType<typeof useGetPreviousSceneQuery>;
export type GetPreviousSceneLazyQueryHookResult = ReturnType<typeof useGetPreviousSceneLazyQuery>;
export type GetPreviousSceneQueryResult = Apollo.QueryResult<GetPreviousSceneQuery, GetPreviousSceneQueryVariables>;
export const GetNextIdenticalSceneDocument = gql`
    query GetNextIdenticalScene($customer: String!, $project: String!, $scope: Scope!, $sceneId: UUID!, $rootSpaceId: UUID!, $batchId: UUID) {
  scene(
    tenant: {customer: $customer, project: $project, scope: $scope}
    id: $sceneId
  ) {
    id
    nextIdenticalScene(subTree: $rootSpaceId, batchId: $batchId) {
      id
      space {
        id
      }
    }
  }
}
    `;

/**
 * __useGetNextIdenticalSceneQuery__
 *
 * To run a query within a React component, call `useGetNextIdenticalSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextIdenticalSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextIdenticalSceneQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      sceneId: // value for 'sceneId'
 *      rootSpaceId: // value for 'rootSpaceId'
 *      batchId: // value for 'batchId'
 *   },
 * });
 */
export function useGetNextIdenticalSceneQuery(baseOptions: Apollo.QueryHookOptions<GetNextIdenticalSceneQuery, GetNextIdenticalSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNextIdenticalSceneQuery, GetNextIdenticalSceneQueryVariables>(GetNextIdenticalSceneDocument, options);
      }
export function useGetNextIdenticalSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNextIdenticalSceneQuery, GetNextIdenticalSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNextIdenticalSceneQuery, GetNextIdenticalSceneQueryVariables>(GetNextIdenticalSceneDocument, options);
        }
export type GetNextIdenticalSceneQueryHookResult = ReturnType<typeof useGetNextIdenticalSceneQuery>;
export type GetNextIdenticalSceneLazyQueryHookResult = ReturnType<typeof useGetNextIdenticalSceneLazyQuery>;
export type GetNextIdenticalSceneQueryResult = Apollo.QueryResult<GetNextIdenticalSceneQuery, GetNextIdenticalSceneQueryVariables>;
export const GetPreviousIdenticalSceneDocument = gql`
    query GetPreviousIdenticalScene($customer: String!, $project: String!, $scope: Scope!, $sceneId: UUID!, $rootSpaceId: UUID!, $batchId: UUID) {
  scene(
    tenant: {customer: $customer, project: $project, scope: $scope}
    id: $sceneId
  ) {
    id
    previousIdenticalScene(subTree: $rootSpaceId, batchId: $batchId) {
      id
      space {
        id
      }
    }
  }
}
    `;

/**
 * __useGetPreviousIdenticalSceneQuery__
 *
 * To run a query within a React component, call `useGetPreviousIdenticalSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPreviousIdenticalSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPreviousIdenticalSceneQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      sceneId: // value for 'sceneId'
 *      rootSpaceId: // value for 'rootSpaceId'
 *      batchId: // value for 'batchId'
 *   },
 * });
 */
export function useGetPreviousIdenticalSceneQuery(baseOptions: Apollo.QueryHookOptions<GetPreviousIdenticalSceneQuery, GetPreviousIdenticalSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPreviousIdenticalSceneQuery, GetPreviousIdenticalSceneQueryVariables>(GetPreviousIdenticalSceneDocument, options);
      }
export function useGetPreviousIdenticalSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPreviousIdenticalSceneQuery, GetPreviousIdenticalSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPreviousIdenticalSceneQuery, GetPreviousIdenticalSceneQueryVariables>(GetPreviousIdenticalSceneDocument, options);
        }
export type GetPreviousIdenticalSceneQueryHookResult = ReturnType<typeof useGetPreviousIdenticalSceneQuery>;
export type GetPreviousIdenticalSceneLazyQueryHookResult = ReturnType<typeof useGetPreviousIdenticalSceneLazyQuery>;
export type GetPreviousIdenticalSceneQueryResult = Apollo.QueryResult<GetPreviousIdenticalSceneQuery, GetPreviousIdenticalSceneQueryVariables>;
export const FloorplansWithDescendantsDocument = gql`
    query FloorplansWithDescendants($tenant: TenantInputType!) {
  floorplans(tenant: $tenant) {
    id
    bimOriginX
    bimOriginY
    bimOriginZ
    angleToTrueNorth
    floorHeight
    space {
      id
      descendantSpaces {
        id
      }
    }
  }
}
    `;

/**
 * __useFloorplansWithDescendantsQuery__
 *
 * To run a query within a React component, call `useFloorplansWithDescendantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFloorplansWithDescendantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFloorplansWithDescendantsQuery({
 *   variables: {
 *      tenant: // value for 'tenant'
 *   },
 * });
 */
export function useFloorplansWithDescendantsQuery(baseOptions: Apollo.QueryHookOptions<FloorplansWithDescendantsQuery, FloorplansWithDescendantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FloorplansWithDescendantsQuery, FloorplansWithDescendantsQueryVariables>(FloorplansWithDescendantsDocument, options);
      }
export function useFloorplansWithDescendantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FloorplansWithDescendantsQuery, FloorplansWithDescendantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FloorplansWithDescendantsQuery, FloorplansWithDescendantsQueryVariables>(FloorplansWithDescendantsDocument, options);
        }
export type FloorplansWithDescendantsQueryHookResult = ReturnType<typeof useFloorplansWithDescendantsQuery>;
export type FloorplansWithDescendantsLazyQueryHookResult = ReturnType<typeof useFloorplansWithDescendantsLazyQuery>;
export type FloorplansWithDescendantsQueryResult = Apollo.QueryResult<FloorplansWithDescendantsQuery, FloorplansWithDescendantsQueryVariables>;
export const ClearDatesDocument = gql`
    mutation ClearDates($customer: String!, $project: String!, $scope: Scope!, $planId: UUID!) {
  clearDates(
    tenant: {customer: $customer, project: $project, scope: $scope}
    planId: $planId
  )
}
    `;
export type ClearDatesMutationFn = Apollo.MutationFunction<ClearDatesMutation, ClearDatesMutationVariables>;

/**
 * __useClearDatesMutation__
 *
 * To run a mutation, you first call `useClearDatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearDatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearDatesMutation, { data, loading, error }] = useClearDatesMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useClearDatesMutation(baseOptions?: Apollo.MutationHookOptions<ClearDatesMutation, ClearDatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearDatesMutation, ClearDatesMutationVariables>(ClearDatesDocument, options);
      }
export type ClearDatesMutationHookResult = ReturnType<typeof useClearDatesMutation>;
export type ClearDatesMutationResult = Apollo.MutationResult<ClearDatesMutation>;
export type ClearDatesMutationOptions = Apollo.BaseMutationOptions<ClearDatesMutation, ClearDatesMutationVariables>;
export const GetPlanDocument = gql`
    query GetPlan($customer: String!, $project: String!, $scope: Scope!, $planId: UUID!) {
  plan(
    tenant: {customer: $customer, project: $project, scope: $scope}
    id: $planId
  ) {
    id
    name
    startDate
  }
}
    `;

/**
 * __useGetPlanQuery__
 *
 * To run a query within a React component, call `useGetPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useGetPlanQuery(baseOptions: Apollo.QueryHookOptions<GetPlanQuery, GetPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlanQuery, GetPlanQueryVariables>(GetPlanDocument, options);
      }
export function useGetPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlanQuery, GetPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlanQuery, GetPlanQueryVariables>(GetPlanDocument, options);
        }
export type GetPlanQueryHookResult = ReturnType<typeof useGetPlanQuery>;
export type GetPlanLazyQueryHookResult = ReturnType<typeof useGetPlanLazyQuery>;
export type GetPlanQueryResult = Apollo.QueryResult<GetPlanQuery, GetPlanQueryVariables>;
export const UpdatePlanStartDateDocument = gql`
    mutation UpdatePlanStartDate($customer: String!, $project: String!, $scope: Scope!, $planId: UUID!, $name: String!, $startDate: Date) {
  updatePlan(
    tenant: {customer: $customer, project: $project, scope: $scope}
    id: $planId
    planName: $name
    startDate: $startDate
  ) {
    id
    name
    startDate
  }
}
    `;
export type UpdatePlanStartDateMutationFn = Apollo.MutationFunction<UpdatePlanStartDateMutation, UpdatePlanStartDateMutationVariables>;

/**
 * __useUpdatePlanStartDateMutation__
 *
 * To run a mutation, you first call `useUpdatePlanStartDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanStartDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanStartDateMutation, { data, loading, error }] = useUpdatePlanStartDateMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      planId: // value for 'planId'
 *      name: // value for 'name'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useUpdatePlanStartDateMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlanStartDateMutation, UpdatePlanStartDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePlanStartDateMutation, UpdatePlanStartDateMutationVariables>(UpdatePlanStartDateDocument, options);
      }
export type UpdatePlanStartDateMutationHookResult = ReturnType<typeof useUpdatePlanStartDateMutation>;
export type UpdatePlanStartDateMutationResult = Apollo.MutationResult<UpdatePlanStartDateMutation>;
export type UpdatePlanStartDateMutationOptions = Apollo.BaseMutationOptions<UpdatePlanStartDateMutation, UpdatePlanStartDateMutationVariables>;
export const GetSpaceAncestorsDocument = gql`
    query GetSpaceAncestors($customer: String!, $project: String!, $scope: Scope!, $spaceId: UUID!) {
  space(
    tenant: {customer: $customer, project: $project, scope: $scope}
    id: $spaceId
  ) {
    id
    name
    ancestors {
      id
      name
    }
  }
}
    `;

/**
 * __useGetSpaceAncestorsQuery__
 *
 * To run a query within a React component, call `useGetSpaceAncestorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpaceAncestorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpaceAncestorsQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useGetSpaceAncestorsQuery(baseOptions: Apollo.QueryHookOptions<GetSpaceAncestorsQuery, GetSpaceAncestorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSpaceAncestorsQuery, GetSpaceAncestorsQueryVariables>(GetSpaceAncestorsDocument, options);
      }
export function useGetSpaceAncestorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSpaceAncestorsQuery, GetSpaceAncestorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSpaceAncestorsQuery, GetSpaceAncestorsQueryVariables>(GetSpaceAncestorsDocument, options);
        }
export type GetSpaceAncestorsQueryHookResult = ReturnType<typeof useGetSpaceAncestorsQuery>;
export type GetSpaceAncestorsLazyQueryHookResult = ReturnType<typeof useGetSpaceAncestorsLazyQuery>;
export type GetSpaceAncestorsQueryResult = Apollo.QueryResult<GetSpaceAncestorsQuery, GetSpaceAncestorsQueryVariables>;
export const ReweightDocument = gql`
    mutation Reweight($customer: String!, $project: String!, $scope: Scope!, $planId: UUID!) {
  calculateWeights(
    tenant: {customer: $customer, project: $project, scope: $scope}
    planIds: [$planId]
  )
}
    `;
export type ReweightMutationFn = Apollo.MutationFunction<ReweightMutation, ReweightMutationVariables>;

/**
 * __useReweightMutation__
 *
 * To run a mutation, you first call `useReweightMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReweightMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reweightMutation, { data, loading, error }] = useReweightMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      project: // value for 'project'
 *      scope: // value for 'scope'
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useReweightMutation(baseOptions?: Apollo.MutationHookOptions<ReweightMutation, ReweightMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReweightMutation, ReweightMutationVariables>(ReweightDocument, options);
      }
export type ReweightMutationHookResult = ReturnType<typeof useReweightMutation>;
export type ReweightMutationResult = Apollo.MutationResult<ReweightMutation>;
export type ReweightMutationOptions = Apollo.BaseMutationOptions<ReweightMutation, ReweightMutationVariables>;
export const GetSpaceAncestorNamesByIdDocument = gql`
    query GetSpaceAncestorNamesById($tenant: TenantInputType!, $spaceIds: [UUID!]) {
  spacesByFilter(tenant: $tenant, spaceFilters: {id: $spaceIds}) {
    id
    name
    ancestors {
      name
    }
  }
}
    `;

/**
 * __useGetSpaceAncestorNamesByIdQuery__
 *
 * To run a query within a React component, call `useGetSpaceAncestorNamesByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpaceAncestorNamesByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpaceAncestorNamesByIdQuery({
 *   variables: {
 *      tenant: // value for 'tenant'
 *      spaceIds: // value for 'spaceIds'
 *   },
 * });
 */
export function useGetSpaceAncestorNamesByIdQuery(baseOptions: Apollo.QueryHookOptions<GetSpaceAncestorNamesByIdQuery, GetSpaceAncestorNamesByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSpaceAncestorNamesByIdQuery, GetSpaceAncestorNamesByIdQueryVariables>(GetSpaceAncestorNamesByIdDocument, options);
      }
export function useGetSpaceAncestorNamesByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSpaceAncestorNamesByIdQuery, GetSpaceAncestorNamesByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSpaceAncestorNamesByIdQuery, GetSpaceAncestorNamesByIdQueryVariables>(GetSpaceAncestorNamesByIdDocument, options);
        }
export type GetSpaceAncestorNamesByIdQueryHookResult = ReturnType<typeof useGetSpaceAncestorNamesByIdQuery>;
export type GetSpaceAncestorNamesByIdLazyQueryHookResult = ReturnType<typeof useGetSpaceAncestorNamesByIdLazyQuery>;
export type GetSpaceAncestorNamesByIdQueryResult = Apollo.QueryResult<GetSpaceAncestorNamesByIdQuery, GetSpaceAncestorNamesByIdQueryVariables>;